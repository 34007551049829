const IMG_SIZE_DEFAULT1 = {width: '', height: ''}
const IMG_SIZE_DEFAULT2 = {width: '', height: ''}
export default {
  section: {
    idx: '',
    path: [],
    pathName: ''
  },
  link_url_app: '',
  link_target_app: '_self',
  dp_type: 'ORIGINAL',
  dp_title: '',
  dp_text1: '',
  dp_text2: '',
  dp_html: '',
  dp_status: 'N',
  dp_interactive: 'N',
  dp_start_time: '',
  dp_end_time: '',
  model: '',
  model_idx: '',
  model_data: {},
  is_advertising: false,
  ad_channel: 'A',
  ad_title: '',
  ad_code_app: '',
  app_img: {},
  app_img_size: IMG_SIZE_DEFAULT1,
  app_img_interactive: {},
  app_img_interactive_size: IMG_SIZE_DEFAULT2,
}