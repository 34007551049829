export const
  SET_HOTEL_FORM = 'SET_HOTEL_FORM',
  EMPTY_HOTEL_FORM = 'EMPTY_HOTEL_FORM',
  UPDATE_HOTEL_FORM = 'UPDATE_HOTEL_FORM',
  SET_ALL_HOTEL_DATA_FAST = 'SET_ALL_HOTEL_DATA_FAST',
  SET_ALL_HASH_TAG_DATA = 'SET_ALL_HASH_TAG_DATA',
  SET_ALL_HOTEL_BRAND_DATA = 'SET_ALL_HOTEL_BRAND_DATA',
  SET_ALL_HOTEL_REGION_DATA = 'SET_ALL_HOTEL_REGION_DATA',
  SET_ALL_AIRLINE_CORP_DATA = 'SET_ALL_AIRLINE_CORP_DATA',
  SET_ALL_POST_DATA_FAST = 'SET_ALL_POST_DATA_FAST',
  SET_ALL_VIDEO_DATA_FAST = 'SET_ALL_VIDEO_DATA_FAST',
  SET_ALL_HOTEL_REGION_DEPTH_DATA = 'SET_ALL_HOTEL_REGION_DEPTH_DATA',
  SET_ALL_POST_CATE_DATA = 'SET_ALL_POST_CATE_DATA',
  SET_ALL_DISPLAY_CATE_DATA = 'SET_ALL_DISPLAY_CATE_DATA',
  SET_ALL_MEMBER_DATA = 'SET_ALL_MEMBER_DATA',
  SET_ALL_MEMBER_CLASS_DATA = 'SET_ALL_MEMBER_CLASS_DATA',
  SET_ALL_MEMBER_AUTH_DATA = 'SET_ALL_MEMBER_AUTH_DATA',
  SET_SEO_META_DATA_DEFAULT = 'SET_SEO_META_DATA_DEFAULT',
  SET_CALENDAR_DATA = 'SET_CALENDAR_DATA',
  SET_MY_MEMBER_AUTH_DATA = 'SET_MY_MEMBER_AUTH_DATA',
  SET_OTA_SINGLE_HOTEL_DATA = 'SET_OTA_SINGLE_HOTEL_DATA',
  SET_OTA_SINGLE_HOTEL_DATA_CANCEL_TOKEN = 'SET_OTA_SINGLE_HOTEL_DATA_CANCEL_TOKEN',
  SET_OTA_SINGLE_HOTEL_DATAFEEDS = 'SET_OTA_SINGLE_HOTEL_DATAFEEDS',
  SET_OTA_SINGLE_HOTEL_DATAFEEDS_CANCEL_TOKEN = 'SET_OTA_SINGLE_HOTEL_DATAFEEDS_CANCEL_TOKEN',
  SET_ALL_OTA_CODE_DATA = 'SET_ALL_OTA_CODE_DATA',
  SET_ALL_USER_DATA = 'SET_ALL_USER_DATA'
