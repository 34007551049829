<template>
  <div class="user">
    <div class="photo">
      <img v-if="getLoginMember.prof_img.url" :src="getLoginMember.prof_img.url" alt="user avatar"/>
      <img v-else src="static/img/favicon.png" />
    </div>
    <div class="info">
      <a data-toggle="collapse" :aria-expanded="!isClosed" @click="toggleMenu" href="#">
         <span>
           {{getLoginMember.name}} ({{getLoginMember.position}})
           <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <collapse-transition>
          <ul class="nav nav-menu" v-show="!isClosed">
            <li>
              <a @click="logout">
                <span class="sidebar-mini-icon">LO</span>
                <span class="sidebar-normal">Logout</span>
              </a>
            </li>
            <!--<li>-->
              <!--<a href="#">-->
                <!--<span class="sidebar-mini-icon">Ep</span>-->
                <!--<span class="sidebar-normal">Edit Profile</span>-->
              <!--</a>-->
            <!--</li>-->
            <!--<li>-->
              <!--<a href="#">-->
                <!--<span class="sidebar-mini-icon">S</span>-->
                <!--<span class="sidebar-normal">Settings</span>-->
              <!--</a>-->
            <!--</li>-->
          </ul>
        </collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions'

  export default {
    components: {
      CollapseTransition
    },
    data() {
      return {
        isClosed: true
      }
    },
    computed: {
      getLoginMember () {
        let member = JSON.parse(this.$localStorage.get('accessMember'))
        return member && member.idx ? member : {
          name: '',
        }
      }
    },
    methods: {
      toggleMenu() {
        this.isClosed = !this.isClosed
      },
      logout() {
        this.$router.push('/login')
        let formData = new FormData()
        formData.append('api_token', this.$localStorage.get('accessToken'))
        this.axios.post('member/logout', formData)
        this.axios.defaults.headers.common['Authorization'] = undefined
        this.$localStorage.remove('accessToken')
        this.$localStorage.remove('accessMember')
      }
    }
  }
</script>
<style scoped>
  .nav.nav-menu {
    margin-top: 0;
  }
</style>
