<template>
  <div class="card" style="background-color: #ddd">
    <div class="card-header position-relative" :key="hotel.main_img.toString()">
      <div class="bg-white" style="position: absolute; left: 0; bottom: 0; width: 100%; height: 110px" v-if="hotel.main_img.url"></div>
      <div class="mb-3 text-center position-relative" :style="hotel.main_img.url ? 'height: 200px' : ''">
        <img v-if="hotel.main_img.url" :src="hotel.main_img.url" class="shadow pointer" style="border-radius: 12px" width="auto" height="100%" @click="showImage(hotel.main_img.url)" />
        <div v-else class="text-white">
          No Main Image
        </div>
      </div>
      <label class="row text-info text-center" v-if="getIsModify">
        <div class="col-md-12">수정화면에서는 이미지 업로드 및 삭제가 바로 반영됩니다</div>
      </label>
    </div>

    <div class="card-body bg-white pb-3" style="border-radius: 0 0 12px 12px">
      <div class="mb-3">
        <label class="row">
          <div class="col-md-4">프고 이미지</div>
          <div class="col-md-8">
            <span class="text-danger" v-if="getUploadFilesCnt > 0"> : {{getUploadFilesCnt}}개</span>
          </div>
        </label>
        <el-upload
          id="imgs"
          ref="imgs"
          class="avatar-uploader"
          :action="getDirectUploadUrl"
          :data="getUploadRequestData('imgs')"
          :file-list="hotel.imgs"
          :on-preview="handlePreview"
          :on-change="handleChange"
          :on-success="handleSuccess"
          :on-remove="handleRemove"
          :before-remove="handleBeforeRemove"
          :show-file-list="true"
          :auto-upload="getIsModify"
          :multiple="true"
          list-type="picture-card">
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>

      <div class="text-left">
        <label class="row">
          <div class="col-md-4">기본 이미지</div>
          <div class="col-md-8">
            <span class="text-danger" v-if="getBasicImagesCnt > 0"> : {{getBasicImagesCnt}}개</span>
          </div>
        </label>
        <el-upload
          id="bs_imgs"
          ref="bs_imgs"
          class="avatar-uploader"
          :action="getDirectUploadUrl"
          :data="getUploadRequestData('bs_imgs')"
          :file-list="hotel.bs_imgs"
          :on-preview="handlePreview"
          :on-change="handleChange"
          :on-success="handleSuccess"
          :on-remove="handleRemove"
          :before-remove="handleBeforeRemove"
          :show-file-list="true"
          :auto-upload="getIsModify"
          :multiple="true"
          list-type="picture-card">
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>

    </div>

    <el-dialog :visible.sync="dialogVisible"
               :modal-append-to-body="false" >
      <div class="row">
        <div class="col-md-12">
          <img :src="dialogImage.url" width="auto" height="auto" alt="">
        </div>
        <div class="position-relative">
          <p-button type="primary" :disabled="getIsMainImage" :class="{'bg-danger' : getIsMainImage}" size="md" class="m-3 text-nowrap" style="position: absolute; top: 0; right: 15px" @click="setMainImage">
            <i class="fa fa-star" /> 대표사진{{!getIsMainImage ? '으로' : ''}}
          </p-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import Vue from 'vue'
  import forms from '../forms'
  import {Modal} from 'src/components/UIComponents'
  import {Upload, Dialog, Progress, Image} from 'element-ui'
  import commonMethods from 'src/mixins/common_methods'
  export default {
    name: 'HotelPreview',
    components: {
      Modal,
      [Upload.name]: Upload,
      [Dialog.name]: Dialog,
      [Progress.name]: Progress,
      [Image.name]: Image
    },
    data () {
      return {
        hotel: forms,
        uploadData: [],
        dialogImage: {
          url: '',
          uid: 0
        },
        dialogVisible: false,
        removable: true
      }
    },
    created () {
      window.vm = this
    },
    computed: {
      getIsModify () {
        return this.hotel.idx > 0
      },
      getIsMainImage () {
        return this.dialogImage.url === this.hotel.main_img.url
      },
      getDirectUploadUrl () {
        return this.getIsModify ? `${Vue.prototype.$apiURL}/hotels/upload/multi` : 'about:blank'
      },
      getUploadFilesCnt () {
        const rtn = this.$refs.imgs ? this.$refs.imgs.uploadFiles : []
        return rtn
      },
      getBasicImages () {
        return this.hotel.bs_imgs
      },
      getBasicImagesCnt () {
        const rtn = this.$refs.bs_imgs ? this.$refs.bs_imgs.uploadFiles : []
        return rtn.length
      },
    },
    methods: {
      ...commonMethods,
      showImage (img) {
        this.dialogImage.url = img
        this.dialogVisible = true
      },
      setMainImage () {
        // if (this.dialogImage.uid) {
        //   alert('등록화면에서 대표사진은 첫번째 업로드 이미지로 자동설정 됩니다.\n변경은 수정화면에서 설정 가능합니다.')
        //   return
        // }
        this.hotel.main_img.url = this.dialogImage.url
        this.dialogVisible = false
      },
      removeUploadFile (file, target_id) {
        const target = this.$refs[target_id]
        if (target.autoUpload) { // multiple upload
          const formData = new FormData()
          formData.append('idx', this.hotel.idx)
          formData.append('column', target_id)
          formData.append('id', file.id)
          this.removable = false
          this.$axios.post('/hotels/remove/multi', formData).then(rs => {
            setTimeout(() => this.removable = true, 300)
          }).catch(e => {
            alert('삭제 중 오류가 발생 했습니다.\n이미 삭제된 이미지 이거나 업로드 실패일 수 있습니다.\n창을 새로 띄우고 다시 시도해 주세요.')
            setTimeout(() => this.removable = true, 500)
          })
        }
      },
      getUploadRequestData (id) {
        return this.getIsModify ? {
          idx: this.hotel.idx,
          column: id
        } : null
      },
      handleBeforeRemove(file) {
        // 삭제하는 파일이 메인이미지일때 예외처리
        if (file.url === this.hotel.main_img.url && this.getIsModify) {
          alert('메인이미지는 삭제할 수 없습니다.\n다른 이미지로 교체 후 삭제할 수 있습니다.')
          return false
        }
        return this.removable
      },
      handleSuccess (res, file) {
        console.log('success')
        file.id = res.id
        file.url = res.url
        if (!this.hotel.main_img.url) this.hotel.main_img.url = file.url
      }
    }
  }
</script>
<style scoped>
  .card-body {
    min-height: 150px !important;
  }
  .nowrap {
    white-space: pre-wrap;
  }
  .avatar-uploader i:hover {
    border-color: #409EFF;
  }

  /deep/ .avatar-uploader .el-upload--picture-card {
    background-color: #eee;
    font-size: 28px;
    color: #8c939d;
    min-width: 86px;
    min-height: 65px;
    max-width: 4.27vw;
    max-height: 3.27vw;
    text-align: center;
    position: relative;
    margin: 0 2px;
    z-index: 1;
  }
  /deep/ .avatar-uploader .el-upload--picture-card .el-icon-plus {
    position: absolute;
    top: calc(50% - (28px/2));
    left: calc(50% - (28px/2))
  }
  /deep/ .avatar-uploader .el-upload-list {
    position: relative;
    z-index: 2;
  }
  /deep/ .avatar-uploader .el-upload-list__item {
    min-width: 86px;
    min-height: 65px;
    max-width: 4.27vw;
    max-height: 3.27vw;
    position: relative;
    margin: 0 2px;
    z-index: 0;
  }
  /deep/ .avatar-uploader.landscape .el-upload--picture-card {
    max-width: 4.27vw;
    max-height: 3.27vw;
    margin: 0 0 0 2px;
    line-height: 78px;
  }
  /deep/ .avatar-uploader.landscape .el-upload-list__item {
    max-width: 104px;
    max-height: 78px;
  }
  /deep/ .avatar-uploader .el-upload-list__item.is-success{
    z-index: 2;
  }
  /deep/ .avatar-uploader .el-progress {
    max-width: 100px;
  }
  /deep/ .avatar-uploader .el-progress-circle {
    max-width: 80px;
    margin: 50px auto 0;
  }
  /deep/ .el-image.on img {
    border: 4px solid #FF5675;
  }

  .upload_tip {
    position: relative;
    left: 10px;
  }


  .thumbnail_loading {
    margin-left: calc((4vw/2) - (30px/2));
    margin-top: calc((3vw/2) - (30px/2));
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 3px solid rgba(0,0,0,.1);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
</style>
