export default {
  type: 'HOTEL', // HOTEL: 호텔, FLIGHT: 항공
  post_cate: {},
  title: '',
  writer: {},
  hash_tags: [],
  seo_meta: [],
  post_img: {},
  post_top_img: {},
  related_hotels: [],
  related_airlines: [],
  contents: '',
  is_visible: false,
}