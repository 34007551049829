<template>
  <div class="row HOTEL_REGION">

    <!-- 헤더 및 컨트롤 -->
    <div class="col-md-12">
      <h5 class="title">지역 관리</h5>
      <p class="category">
        <!--We combine <a href="http://element.eleme.io/#/en-US/component/quickstart" target="_blank" rel="noopener">Element-UI</a>-->
        <!--table functionalities together with a custom pagination component-->
        <!--which should provide a very good starting point to integrate tables in your application.-->
        <!--Check out more functionalities at <a href="http://element.eleme.io/#/en-US/component/table" target="_blank" rel="noopener">Element-UI table documentation</a>.-->
        지역 관리입니다.
      </p>
    </div>

    <!-- 국가 리스트 -->
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">국가</h5>
        </div>
        <div class="card-body row table-full-width">
          <div class="col-sm-12">

            <el-table-draggable handle=".handle">
              <el-table :data="tableDataMain"
                        v-loading="loadingMain"
                        highlight-current-row
                        ref="mainTable"
                        @current-change="handleCurrentChange"
                        header-row-class-name="text-default">

                <el-table-column :min-width="30">
                  <template slot-scope="props">
                    <div class="handle" :class="isReSortedMain ? 'text-warning' : 'text-gray' " v-if="getCheckThisPageModify">
                      <i class="nc-icon nc-tile-56 mt-2"></i>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column type="index"
                                 width="60">
                  </el-table-column>
                <el-table-column prop="name"
                                 :min-width="120"
                                 label="국가명">
                </el-table-column>
                <el-table-column label="로고">
                  <template slot-scope="props">
                    <img v-if="isFileExist(props.row.logo_img)" :src="props.row.logo_img.url" width="auto" height="30px" />
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="getCheckThisPageModify"
                  :min-width="90"
                  fixed="right"
                  class-name="td-actions text-center"
                  label="검색노출">
                  <template slot-scope="props">
                    <el-switch v-model="props.row.is_visible" @change="changeIsVisibled(props.row)" active-color="#EB5E28" :disabled="!getCheckThisPageModify"></el-switch>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="getCheckThisPageModify || getCheckThisPageDelete"
                  class-name="action-buttons td-actions"
                  :min-width="100"
                  align="right"
                  fixed="right"
                  label="수정/삭제">
                  <template slot-scope="props">
                    <p-button type="success" size="sm" icon @click.native="handleEdit(props.$index, props.row); isMain=true" :disabled="!getCheckThisPageModify">
                      <i class="fa fa-edit"></i>
                    </p-button>
                    <p-button type="danger" size="sm" icon @click.native="handleDelete(props.$index, props.row); isMain=true" :disabled="!getCheckThisPageDelete">
                      <i class="fa fa-times"></i>
                    </p-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-table-draggable>

          </div>
        </div>
        <div>
          <p class="pull-left ml-3" v-if="getCheckThisPageModify">
            <p-button :type="isReSortedMain ? 'warning' : 'default'" :disabled="!isReSortedMain" round @click.native="isMain=true; saveSorting()">순서 저장</p-button>
          </p>
          <p class="pull-right mr-3">
            <p-button type="info" round @click.native="toggleModal(); loadingMain=false; isMain=true" v-if="getCheckThisPageCreate">국가 등록</p-button>
          </p>
        </div>
      </div>
    </div>

    <!-- 도시 리스트 -->
    <div class="col-md-6">
      <div class="card" :class="{fixed: isSubFixed}">
        <div class="card-header">
          <h5 class="card-title">도시 {{selectedRow ? '- '+selectedRow.name : null}}</h5>
        </div>
        <div class="card-body row table-full-width">
          <div class="col-sm-12">

            <el-table-draggable handle=".handle">
              <el-table :data="tableDataSub"
                        v-loading="loadingSub"
                        header-row-class-name="text-default">

                <el-table-column :min-width="30">
                  <template slot-scope="props">
                    <div class="handle" :class="isReSortedSub ? 'text-warning' : 'text-gray' ">
                      <i class="nc-icon nc-tile-56 mt-2"></i>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column type="index"
                                 width="60">
                  </el-table-column>
                <el-table-column prop="name"
                                 :min-width="120"
                                 label="도시명">
                </el-table-column>
                <el-table-column label="로고">
                  <template slot-scope="props">
                    <img v-if="isFileExist(props.row.logo_img)" :src="props.row.logo_img.url" width="auto" height="30px" />
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="getCheckThisPageModify"
                  :min-width="90"
                  fixed="right"
                  class-name="td-actions text-center"
                  label="검색노출">
                  <template slot-scope="props">
                    <el-switch v-model="props.row.is_visible" @change="changeIsVisibled(props.row)" active-color="#EB5E28" :disabled="!getCheckThisPageModify"></el-switch>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="getCheckThisPageModify || getCheckThisPageDelete"
                  class-name="action-buttons td-actions"
                  :min-width="100"
                  align="right"
                  fixed="right"
                  label="수정/삭제">
                  <template slot-scope="props">
                    <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row); isMain=false" :disabled="!getCheckThisPageModify">
                      <i class="fa fa-edit"></i>
                    </p-button>
                    <p-button type="danger" size="sm" icon @click="handleDelete(props.$index, props.row); isMain=false" :disabled="!getCheckThisPageDelete">
                      <i class="fa fa-times"></i>
                    </p-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-table-draggable>
          </div>
        </div>
        <div>
          <p class="pull-left ml-3" v-if="getCheckThisPageModify">
            <p-button :type="isReSortedSub ? 'warning' : 'default'" :disabled="!isReSortedSub" round @click.native=" isMain=false; saveSorting();">순서 저장</p-button>
          </p>
          <p class="pull-right mr-3">
            <p-button type="info" :disabled="!selectedRow" round @click.native="toggleModal(); loadingSub=false; isMain=false" v-if="getCheckThisPageCreate">도시 등록</p-button>
          </p>
        </div>
      </div>
    </div>

    <!-- 폼 모달 -->
    <modal :show.sync="isModalOpen"
           footer-classes="justify-content-center">

      <h5 slot="header" class="modal-title text-left">
        {{isMain ? '국가' : '도시'}}
        {{!isMain && selectedRow ? '- '+selectedRow.name : null}}
        {{formData.idx ? '수정' : '등록'}}
      </h5>
      <template class="bg-light">
        <div class="row ">
          <div class="col-lg-8 col-md-7">

            <div class="card">
              <div class="card-body">

                <form>
                  <div class="row">
                    <div class="col-md-12">
                      <fg-input type="text"
                                :label="`${isMain ? '국가' : '도시'}명`"
                                :placeholder="`${isMain ? '국가' : '도시'} 입력`"
                                class="required"
                                v-model="formData.name">
                      </fg-input>
                    </div>
                  </div>
                </form>

              </div>
            </div>

          </div>
          <div class="col-lg-4 col-md-5">

            <div class="card card-user">
              <div class="image">
                <img src="static/img/blank.gif" alt="...">
              </div>
              <div class="card-body">
                <div class="author">
                  <el-upload
                    id="logo_img"
                    ref="logo_img"
                    class="avatar-uploader"
                    action="about:blank"
                    accept="image/png, image/jpeg, image/gif"
                    :file-list="getFileList(formData.logo_img)"
                    :on-preview="handlePreview"
                    :on-change="handleChange"
                    :on-remove="handleRemove"
                    :show-file-list="true"
                    :auto-upload="false"
                    :limit="1"
                    list-type="picture-card">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="pull-right">
                      <label class="pr-2">노출</label>
                      <p-switch v-model="formData.is_visible" type="danger" on-text="ON" off-text="OFF"></p-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </template>
      <div slot="footer" class="justify-content-center">
        <p-button type="info" size="lg" wide round @click.native="saveCardBenefitData">{{btnText}}</p-button>
      </div>
    </modal>

    <!-- 이미지뷰어 다이얼로그 -->
    <el-dialog :visible.sync="dialogVisible"
               :modal-append-to-body="false">
      <img :src="dialogImageUrl" alt="">
    </el-dialog>

  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Loading, Upload, Dialog, Switch} from 'element-ui'
  import ElTableDraggable from 'element-ui-el-table-draggable'
  import PSwitch from 'src/components/UIComponents/Switch'
  import Modal from 'src/components/UIComponents/Modal'
  import swal from 'sweetalert2'
  import { mapGetters } from 'vuex'
  import commonMethods from 'src/mixins/common_methods'
  const baseAction = '/hotel_regions/'
  export default{
    components: {
      PSwitch, Modal, ElTableDraggable,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Upload.name]: Upload,
      [Dialog.name]: Dialog,
      [Switch.name]: Switch,
    },
    data () {
      return {
        baseAction: baseAction,
        formData: {
          idx_p: 0,
          name: '',
          logo_img: {},
          is_visible: false,
        },
        uploadData: [],
        tableDataMain: [],
        tableDataMainOrigin: [],
        selectedRow: null,
        tableDataSub: [],
        tableDataSubOrigin: [],
        loadingMain: true,
        loadingSub: true,
        dialogImageUrl: '',
        dialogVisible: false,
        isModalOpen: false,
        isSubFixed: false,
        isMain: true,
        btnText: '등록',
      }
    },
    watch: {
      isModalOpen (val) {
        if (!val) {
          // forms 초기화
          for( let key in this.formData) {
            if (typeof this.formData[key] === 'string') this.formData[key] = ''
            else if (typeof this.formData[key] === 'number') this.formData[key] = 0
            else if (typeof this.formData[key] === 'boolean') this.formData[key] = false
            else if (typeof this.formData[key] === 'object') this.formData[key] = []
          }
        }
      }
    },
    created () {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    mounted () {
      this.getMainList();
      this.getSubList();
    },
    computed: {
      ...mapGetters([
        'getCheckThisPageCreate',
        'getCheckThisPageRead',
        'getCheckThisPageModify',
        'getCheckThisPageDelete'
      ]),
      isReSortedMain () {
        const cur = this.tableDataMain.map(row => row.idx).toString()
        const ori = this.tableDataMainOrigin.map(row => row.idx).toString()
        return cur !== ori
      },
      isReSortedSub () {
        const cur = this.tableDataSub.map(row => row.idx).toString()
        const ori = this.tableDataSubOrigin.map(row => row.idx).toString()
        return cur !== ori
      }
    },
    methods: {
      ...commonMethods,
      handleScroll () {
        this.isSubFixed = window.pageYOffset > 150 ? true : false
      },
      getMainList () {
        this.loadingMain = true
        this.axios.get(baseAction + 'main/all').then((rs) => {
          this.tableDataMain = rs.data
          this.tableDataMainOrigin = this.cloneData(rs.data)
          this.loadingMain = false
        })
      },
      getSubList (idx) {
        if(idx) {
          this.loadingSub = true
          this.axios.get(baseAction + 'sub/all/' + idx).then((rs) => {
            this.tableDataSub = rs.data
            this.tableDataSubOrigin = this.cloneData(rs.data)
            this.loadingSub = false
          })
        } else {
          this.loadingSub = false
        }
      },
      changeIsVisibled (row) {
        const params = {idx: row.idx, is_visible: row.is_visible};
        const action = baseAction + 'visible/' + params.idx
        this.axios.put(action, params).then((rs) => {
          if (rs.data.status === 'success') {
            this.setCardBenefitList('put', rs.data.data[0])
          }
        }).catch( error => {
          console.log('failed', error)
        })
      },
      setCardBenefitList (method, row) {
        let tableData = this.isMain ? this.tableDataMain : this.tableDataSub
        if(method === 'put') {
          for (let key in tableData) {
            if (tableData[key].idx === row.idx) {
              tableData.splice(key, 1, row)
              break
            }
          }
        } else {
          tableData.unshift(row)
        }
      },
      toggleModal (row) {
        const loading = Loading.service()
        if(row) {
          this.btnText = '수정'
          this.axios.get(baseAction + row.idx).then((rs) => {
            for( var key in rs.data) {
              let val = rs.data[key]
              if(val!==null) {
                this.formData[key] = val
              }
            }
            loading.close()
          })
        } else {
          this.btnText = '등록'
          loading.close()
        }
        this.isModalOpen = !this.isModalOpen
      },
      saveCardBenefitData () {
        const loading = Loading.service()
        let params = this.cloneData(this.formData)
        let method = 'post'
        let action = baseAction

        if (params.idx) {
          action += params.idx
          method = 'put'
        } else {
          if (this.selectedRow) {
            params.idx_p = this.selectedRow.idx
          }
        }

        let formData = new FormData()
        for (let key in this.uploadData) {
          delete params[key]
          if (Object.keys(this.uploadData[key]).length > 0) {
            formData.append(key, this.uploadData[key].raw)
          } else {
            formData.append(key, null)
          }
        }
        for (let key in params) {
          if (typeof params[key] === 'object') {
            formData.append(key, JSON.stringify(params[key]))
          } else {
            formData.append(key, params[key])
          }
        }

        // for (var key of formData.entries()) {
        //   console.log(key[0] + ', ' + key[1]);
        // }

        this.axios.post(action, formData).then((rs) => {
          if (rs.data.status === 'success') {
            let data = rs.data.data[0]
            let tableData = this.isMain ? this.tableDataMain : this.tableDataSub
            let tableDataOrigin = this.isMain ? this.tableDataMainOrigin : this.tableDataSubOrigin
            if (params.idx) {
              for (let key in tableData) {
                if (tableData[key].idx === data.idx) {
                  Vue.set(tableData, key, data)
                  break
                }
              }
            } else {
              tableData.push(data)
              tableDataOrigin.push(data)
            }
            this.$store.dispatch('loadAllCardBenefitDepthData')
            this.$store.dispatch('loadAllCardBenefitFlatData')
            this.uploadData = []
          } else {
            swal({
              title: 'ERROR',
              text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
              type: 'error',
              confirmButtonClass: 'btn btn-info btn-fill',
              buttonsStyling: false
            })
          }
          loading.close()
          this.isModalOpen = false

        }).catch( error => {
          loading.close()
          console.log('failed', error)
        })
      },
      saveSorting () {
        let tableData = this.isMain ? this.tableDataMain : this.tableDataSub
        let gb = this.isMain ? 'Main' : 'Sub'
        this['loading'+gb] = true
        const action = baseAction + 'sort'
        const params = {idxs: tableData.map(row => row.idx)}
        this.axios.put(action, params).then(rs => {
          if (rs.data.status === 'success') {
            this['tableData'+gb+'Origin'] = this.cloneData(tableData)
          } else {
            alert('error')
          }
          this['loading'+gb] = false
        })
      },

      handleEdit (index, row) {
        this.toggleModal(row)
      },
      handleDelete (index, row) {
        let $this = this
        swal({
          title: '삭제하시겠습니까?',
          // text: '삭제하면 관련',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          buttonsStyling: false
        }).then(function () {
          let gb = $this.isMain ? 'Main' : 'Sub'
          vm['loading'+gb] = true
          let tableData = $this.isMain ? $this.tableDataMain : $this.tableDataSub
          let tableDataOrigin = $this.isMain ? $this.tableDataMainOrigin : $this.tableDataSubOrigin
          $this.axios.delete(baseAction + row.idx).then((rs) => {
            $this.isMain ? $this.loadingMain = false : $this.loadingSub = false
            if (rs.data.status === 'success') {
              tableData.splice(index, 1)
              tableDataOrigin.splice(index, 1)
              if ($this.isMain) {
                $this.tableDataSub = []
                $this.selectedRow = null
              }
            } else {
              swal({
                title: 'ERROR',
                text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
                type: 'error',
                confirmButtonClass: 'btn btn-info btn-fill',
                buttonsStyling: false
              })
            }
          }).catch( error => {
            vm['loading'+gb] = false
          })
        }, function (dismiss) {

        })
      },

      handleCurrentChange (row) {
        if(row) {
          this.selectedRow = row
          this.getSubList(row.idx)
        }
      },
      handleCurrentClear () {
        this.isMain = true
        // this.selectedRow = null
        // this.$refs.mainTable.setCurrentRow()
      },
    }
  }
</script>
<style scoped>
  .el-table th .cell {
    padding-right: 15px;
  }
  label {
    font-weight: bold !important;
  }

  .card-user .image { height: 114px; }
  .card-user .card-body { min-height: 140px; }

  .fixed {
    position: fixed;
    top: 10px;
    max-height: calc(90vh);
    overflow-y: auto;
  }

  /deep/ .avatar-uploader .el-upload-list {
    position: relative;
    z-index: 2;
  }
  /deep/ .avatar-uploader .el-upload-list__item {
    position: absolute;
    z-index: 0;
  }
  /deep/ .avatar-uploader .el-upload-list__item.is-success{
    z-index: 2;
  }

  /deep/ .modal-dialog {
    padding-top: 0 !important;
    width: 75% !important;
    max-width: 1919px !important;
    margin: 2.5rem auto !important;
  }
  /deep/ .modal-body {
    background-color: #f4f3ef;
    padding: 20px 30px !important;
  }
</style>

