<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <ul>
          <li>
            <a href="http://www.piaaano.com" target="_blank">
              Piaaanosoft
            </a>
          </li>
          <li>
            <a href="http://www.creative-tim.com/license" target="_blank">
              Licenses
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright pull-right">
        <!--&copy; Coded with-->
        <!--<i class="fa fa-heart heart"></i> by-->
        <!--<a href="https://binarcode.com" target="_blank" rel="noopener">BinarCode</a>.-->
        <!--<span>Designed by <a href="https://www.piaaano.com" rel="noopener" target="_blank">Piaaano</a>.</span>-->
        <span>Developed by <a href="http://www.piaaano.com" rel="noopener" target="_blank">Piaaanosoft</a>.</span>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {}

</script>
<style>

</style>
