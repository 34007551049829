<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <notifications transition-name="notification-list" transition-mode="out-in"></notifications>

    <router-view name="header"></router-view>
    <transition name="fade"
                mode="out-in">
      <router-view></router-view>
    </transition>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
  // Loading some plugin css asynchronously
  import 'sweetalert2/dist/sweetalert2.css'
  import 'vue-notifyjs/themes/default.css'
  export default {
    name: 'app',
    created () {
      window.vm = this
    }
  }
</script>
<style lang="scss">

  /* custom style */
  .text-info-darken {
    color: #31abae !important;
  }
  .required:before {
    content: '●';
    color: red;
    display: inline-block;
    margin: 0 5px 0 0;
  }
  .provided:before {
    content: '●';
    color: #00aef0;
    display: inline-block;
    margin: 0 5px 0 0;
  }
  .saved:before {
    content: '●';
    color: #ffb527;
    display: inline-block;
    margin: 0 5px 0 0;
  }
  .pointer {
    cursor: pointer;
  }
  .title-comment {
    font-size: 12px;
    color: #ef8157;
  }

  /* element-ui style reset */
  .input-group-append, .input-group-prepend {
    background-color: #fafafa;
    overflow: hidden;
  }
  .upload-demo .el-upload {
    display: block !important;
  }
  .upload-demo .el-upload-list {
    margin-bottom: 3px !important;
  }
  .upload-demo .el-upload-list__item:first-child {
    margin-top: 0 !important;
  }
  .modal-dialog {
    padding-top: 0 !important;
    width: 95% !important;
    max-width: 1919px !important;
    margin: 2.5rem auto !important;
  }
  .modal-body {
    background-color: #f4f3ef;
    padding: 20px 30px !important;
  }
  .el-scrollbar__wrap--hidden-default {
    min-height: 30px;
  }
  .el-cascader__dropdown {
    max-height: 70vh;
    overflow: hidden;
  }
  .el-cascader__dropdown .el-cascader-menu__list {
    max-height: 70vh;
  }
  .el-cascader-panel .el-radio__input {
    margin-top: 8px;
  }





  /* froala editor style reset */
  .fr-view {
    padding: 10px !important;
  }
  .fr-view ul,
  .fr-view li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .fr-toolbar {
    border-top: 0 !important;
  }
  .nav-item a {
    padding-left: 2vw !important;
    padding-right: 2vw !important;
  }
  .fr-wrapper {
    background-color: #fafafa !important;
    box-shadow: initial !important;
    border: 1px solid #ddd !important;
    border-radius: 4px !important;
  }
  .fr-toolbar {
    border-top: 1px solid #eee !important;
  }
  .el-textarea__inner {
    min-height: calc(2.25rem + 2px) !important;
    background-color: #fafafa !important;
    padding: 10px !important;
  }
  .el-textarea__inner:focus {
    border: 1px solid #999 !important;
    background-color: #fff !important;
  }
</style>
