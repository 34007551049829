<template>
  <div class="row STAT_CHART_DATA">

    <!-- 헤더 및 컨트롤 -->
    <div class="col-md-12">
      <div class="row">
        <div class="col-sm-4">
          <h5 class="title">{{pageName}}</h5>
          <p class="category">
            <!--We combine <a href="http://element.eleme.io/#/en-US/component/quickstart" target="_blank" rel="noopener">Element-UI</a>-->
            <!--table functionalities together with a custom pagination component-->
            <!--which should provide a very good starting point to integrate tables in your application.-->
            <!--Check out more functionalities at <a href="http://element.eleme.io/#/en-US/component/table" target="_blank" rel="noopener">Element-UI table documentation</a>.-->
            차트 데이터 관리입니다.
          </p>
        </div>
        <div class="col-sm-7">
          <el-select
            v-model="logMode"
            @change="changeLogMode"
            class="select-default square pull-right"
            placeholder="로그 선택">
            <el-option label="일간로그" value="D"></el-option>
            <el-option label="주간로그" value="W"></el-option>
          </el-select>
        </div>
        <div class="col-sm-1">
          <p-button class="pull-right" type="info" round icon :disabled="!selectedNode.idx || newdata" @click="downloadExcelFile(getFileName, getTableResult, getTableColumns, 'array_data')">
            <i class="fa fa-download"></i>
          </p-button>
        </div>
      </div>
    </div>

    <!-- 카테고리 -->
    <div class="col-md-2">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            {{ logMode === 'W' ? '차트기간 선택' : '차트일 선택' }}
          </h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">

              <div v-if="logMode === 'W'">
                <el-tree
                  :props="props"
                  :data="displayCate.value"
                  node-key="id"
                  highlight-current
                  @node-click="handleNodeClick"
                  :expand-on-click-node="true">
                </el-tree>
              </div>
              <div v-else-if="logMode === 'D'">
                <el-date-picker
                  style="width: 100%"
                  v-model="searchDate"
                  @change="handleDateClick"
                  type="date"
                  unlink-panels
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions2">
                </el-date-picker>
              </div>

            </div>
          </div>
        </div>
        <div>
          <p class="pull-right mr-3">

          </p>
        </div>
      </div>
    </div>

    <!-- 상세설정 -->
    <div class="col-md-10 px-md-0">
      <div class="card" :class="{fixed: isSubFixed === 10}">
        <div class="card-header">
          <h5 class="title">{{getSelectedNodeLabel}} {{selectedNode.name}} 차트 데이터 {{newdata ? '(신규)' : ''}}</h5>
          <div class="mt-3 BUTTON">
            <p-radio class="float-left mr-3" label="DOMESTIC" v-model="searchDB.cate">{{cateLabel['DOMESTIC'].name}}</p-radio>
            <p-radio class="float-left" label="OVERSEAS" v-model="searchDB.cate">{{cateLabel['OVERSEAS'].name}}</p-radio><br>
          </div>
        </div>
        <div class="card-body">

          <div v-if="selectedNode.idx">

            <div class="row">
              <div class="col-md-6 pr-md-1">
                <fg-input type="text"
                          placeholder=""
                          readonly
                          v-model="selectedNode.name">
                </fg-input>
              </div>
              <div class="col-md-6 pl-md-1">
                <fg-input type="text"
                          placeholder=""
                          :readonly="selectedNode.idx"
                          v-model="selectedNode.idx">
                </fg-input>
              </div>
            </div>

            <div class="row py-2 mb-3" style="border-bottom: 1px solid #eee; border-top: 1px solid #eee">
              <div class="col-md-6">
                (HID) 호텔명
              </div>
              <div class="col-md-6 text-center">
                APP
              </div>
            </div>
            <div class="row" v-for="(hotel, index) in getVisibledData" :key="index">
              <div class="col-md-6 pt-2 m-0">
                <span class="text-primary">({{hotel.idx}})</span> {{hotel.name}}
              </div>
              <div class="col-md-6 row" v-if="logMode === 'W'">
                <div class="col-md-6 m-0 pr-md-1 form-group">
                  <input class="el-input__inner" type="number" placeholder="PV" v-model.number="selectedNode[getDataName][index].view_app" @keydown.enter="saveValue(selectedNode[getDataName][index],'view_app')" @blue="saveValue(selectedNode[getDataName][index],'view_app')" :readonly="!getCheckThisPageModify" />
                </div>
                <div class="col-md-6 m-0 pl-md-1 form-group">
                  <input class="el-input__inner" type="number" placeholder="Click" v-model.number="selectedNode[getDataName][index].click_app" @keydown.enter="saveValue(selectedNode[getDataName][index],'click_app')" @blur="saveValue(selectedNode[getDataName][index],'click_app')" :readonly="!getCheckThisPageModify" />
                </div>
              </div>
              <div class="col-md-6 row" v-if="logMode === 'D'">
                <div class="col-md-6 m-0 pl-md-0 pr-md-1 form-group">
                  <input class="el-input__inner" type="number" placeholder="PV" v-model.number="selectedNode[getDataName][index].view_app" @keydown.enter="saveValue(selectedNode[getDataName][index],'view_app')" @blue="saveValue(selectedNode[getDataName][index],'view_app')" :readonly="!getCheckThisPageModify" />
                </div>
                <div class="col-md-6 m-0 pl-md-0 pr-md-1 form-group">
                  <input class="el-input__inner" type="number" placeholder="Click" v-model.number="selectedNode[getDataName][index].click_app" @keydown.enter="saveValue(selectedNode[getDataName][index],'click_app')" @blur="saveValue(selectedNode[getDataName][index],'click_app')" :readonly="!getCheckThisPageModify" />
                </div>
              </div>

              <!--<div class="col-md-12 px-0" v-if="index % 10 === 0 && index > 0">-->
                <!--<p class="pull-right mr-3">-->
                  <!--<p-button type="info" round @click="saveDisplayCateData()">주간 차트 저장</p-button>-->
                <!--</p>-->
              <!--</div>-->

            </div>
          </div>
          <div v-else>
            <span v-if="logMode === 'W'">
              설정할 차트기간 노드를 선택하세요.<br/>
              마지막 노드만 설정을 할 수 있습니다.
            </span>
            <span v-else>
              설정할 차트일을 선태하세요.
            </span>
          </div>

        </div>
        <!--<div>-->
          <!--<p class="pull-right mr-3">-->
            <!--<p-button type="info" v-if="selectedNode.idx" round @click="saveDisplayCateData()">주간 차트 저장</p-button>-->
          <!--</p>-->
        <!--</div>-->
      </div>
    </div>

  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import {Table, TableColumn, Tree, Button, ButtonGroup, Select, Option, Loading, Dialog, Switch, Divider, Input, Image, DatePicker} from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch'
  import swal from 'sweetalert2'
  import commonMethods from 'src/mixins/common_methods'
  import chartdata from './chartdata'
  const baseAction = '/charts/'
  const DATA_OBJECT_DEFAULT = {hotel_idx: '', view_app: '', click_app: '', view_mobile: '', click_mobile: ''}
  let id = 174
  export default {
    name: 'DisplayCate',
    components: {
      PSwitch,
      [Tree.name]: Tree,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Button.name]: Button,
      [ButtonGroup.name]: ButtonGroup,
      [Select.name]: Select,
      [Option.name]: Option,
      [Dialog.name]: Dialog,
      [Switch.name]: Switch,
      [Divider.name]: Divider,
      [Input.name]: Input,
      [Image.name]: Image,
      [DatePicker.name]: DatePicker,
    },
    beforeRouteLeave (to, from, next) {
      // if (this.tab) {
      //   next(false)
      // } else {
      next()
      // }
    },
    data () {
      return {
        baseAction: baseAction,
        pageName: '차트 데이터 관리',
        displayCate: {
          idx: '',
          column: '',
          value: []
        },
        hotelData: {
          DOMESTIC: [],
          OVERSEAS: [],
        },
        displayCateRs: {},
        displayCateOrigin: [],  //this.cloneData(chartdata),
        selectedNode: {},
        dataObjectDefault: this.cloneData(DATA_OBJECT_DEFAULT),
        cateOpen: false,
        accordion: true,
        props: {
          id: 'idx',
          label: 'name',
          children: 'sections'
        },
        cateLabel: {
          DOMESTIC: {
            name: '국내',
            column: 'domestic'
          },
          OVERSEAS: {
            name: '해외',
            column: 'overseas'
          }
        },
        searchDB: {
          'cate': 'DOMESTIC',
          'label': '국내',
        },
        tableColumns: [
          {
            prop: 'no',
            label: '#',
            minWidth: 70
          },
          {
            prop: 'idx',
            label: 'HID',
            minWidth: 70
          },
          // {
          //   prop: 'hotel_img',
          //   label: '썸네일',
          //   minWidth: 90
          // },
          {
            prop: 'name',
            label: '카드명',
            minWidth: 280
          },
        ],
        loading: true,
        isSubFixed: false,
        btnText: '저장',
        newdata: false,
        logMode: 'D',
        searchDate: this.getCurrentDate(),
        pickerOptions2: {
          disabledDate(time) {
            return false//time.getTime() > Date.now();
          },
          shortcuts: [{
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      }
    },
    created () {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    mounted () {
      this.getChartPeriod()
      // this.getDisplayCateList()
      // this.getHotelList()
    },
    computed: {
      ...mapGetters([
        'getCheckThisPageCreate',
        'getCheckThisPageRead',
        'getCheckThisPageModify',
        'getCheckThisPageDelete'
      ]),
      getSelectedNodeLabel () {
        return this.selectedNode.idx ? this.selectedNode.year + '년 ' + this.selectedNode.month + '월' : ''
      },
      getDataName () {
        return this.cateLabel[this.searchDB.cate].column + '_data'
      },
      getVisibledData () {
        return this.cloneData(this.hotelData[this.searchDB.cate])
      },
      getFileName () {
        const rtn = `${this.pageName.replace(/\ /g, '')}_${this.logMode === 'W' ? '주간로그' : '일간로그'}_${this.cateLabel[this.searchDB.cate].name}_(${this.getSelectedNodeLabel.replace(' ', '_')}_${this.selectedNode.name})`
        // console.log(rtn)
        return rtn
      },
      getTableResult () {
        const own = this.selectedNode[this.getDataName]
        const data = []
        if (this.logMode === 'W') {
          own.map((row, i) => {
            if (row.view_app != undefined || row.click_app != undefined || row.view_mobile != undefined || row.click_mobile != undefined) {
              data.push([
                row.idx,
                this.getVisibledData[i].name,
                row.view_app,
                row.click_app,
                row.view_mobile,
                row.click_mobile,
              ])
            }
          })
        } else {
          own.map((row, i) => {
            if (row.view_app != undefined || row.click_app != undefined || row.compare_pc != undefined || row.view_mobile != undefined || row.click_mobile != undefined || row.compare_mobile != undefined) {
              data.push([
                row.idx,
                this.getVisibledData[i].name,
                row.view_app,
                row.click_app,
                row.compare_pc,
                row.view_mobile,
                row.click_mobile,
                row.compare_mobile
              ])
            }
          })
        }
        // console.log(data)
        return data
      },
      getTableColumns () {
        const rtn = this.logMode === 'W' ? ['HID', '호텔명','APP_PV','APP_Click'] : ['HID', '호텔명','APP_PV','APP_Click']
        // console.log(rtn)
        return rtn
      }
    },
    methods: {
      ...commonMethods,
      handleScroll () {
        this.isSubFixed = window.pageYOffset > 150 ? true : false
      },
      getChartPeriod () {
        this.axios.get('/charts/period').then((rs) => {
          this.displayCateOrigin = rs.data

          this.getDisplayCateList()
          this.getHotelList()
        })
      },
      getDisplayCateList () {
        this.displayCate.value = this.cloneData(this.displayCateOrigin)
        this.displayCateRs.value = this.cloneData(this.displayCateOrigin)
        this.loading = false
      },
      getHotelList () {
        const loading = Loading.service()
        this.axios.get('/hotels/domestic').then((rs) => {
          this.hotelData.DOMESTIC = rs.data
          this.selectedNode[this.getDataName] = this.cloneData(this.hotelData.DOMESTIC)
          this.getChartDateItem(this.searchDate)
          setTimeout(function() {
            // this.getChartDateItem(this.searchDate)
            loading.close()
          }.bind(loading), 1000)
        })
        this.axios.get('/hotels/overseas').then((rs) => {
          this.hotelData.OVERSEAS = rs.data
          this.selectedNode[this.getDataName] = this.cloneData(this.hotelData.OVERSEAS)
        })
      },
      saveValue (obj, column) {
        // console.log(obj, this.selectedNode.idx)
        let params
        if (this.logMode === 'W') {
           params = {
            params: {
              dateweek: this.selectedNode.idx,
              hotel_idx: obj.idx,
              column: column,
              value: obj[column],
              hotel_gb: this.searchDB.cate
            }
          }
        } else if(this.logMode === 'D') {
          params = {
            params: {
              date: this.selectedNode.idx,
              hotel_idx: obj.idx,
              column: column,
              value: obj[column],
              hotel_gb: this.searchDB.cate
            }
          }
        }
        this.axios.get('/charts/update_log', params).then((rs) => {
          // if (rs.data) alert('저장이 되지 않았습니다. 잠시 후 다시 시도해 보세요')
        })
      },
      changeLogMode (mode) {
        this.selectedNode = {}
        if (mode === 'D') {
          this.getChartDateItem(this.searchDate)
        }
      },
      getChartItem (data) {
        const loading = Loading.service()
        // console.log(data)
        this.selectedNode = this.cloneData(data)
        this.selectedNode.domestic_data = this.cloneData(this.hotelData.DOMESTIC).map(row => {
          return {
            idx: row.idx,
            view_app: row.view_app,
            click_app: row.click_app
          }
        })
        this.selectedNode.overseas_data = this.cloneData(this.hotelData.OVERSEAS).map(row => {
          return {
            idx: row.idx,
            view_app: row.view_app,
            click_app: row.click_app
          }
        })

        const params = {params: {'dateweek': data.idx}}
        this.axios.get('/charts/search', params).then((rs) => {
          if (rs.data.length > 0) {
            this.newdata = false
            const bak = this.searchDB.cate
            this.searchDB.cate = ''
            this.searchDB.cate = bak
            let domestic = this.selectedNode.domestic_data
            domestic.map(slot => {
              rs.data.map(row => {
                if (slot.idx === row.hotel_idx) {
                  slot.dateweek = row.dateweek
                  slot.view_app = row.view_app
                  slot.click_app = row.click_app
                }
              })
            })
            let overseas = this.selectedNode.overseas_data
            overseas.map(slot => {
              rs.data.map(row => {
                if (slot.idx === row.hotel_idx) {
                  slot.dateweek = row.dateweek
                  slot.view_app = row.view_app
                  slot.click_app = row.click_app
                }
              })
            })
          } else {
            this.newdata = true
            const bak = this.searchDB.cate
            this.searchDB.cate = ''
            this.searchDB.cate = bak
          }
          // if (rs.data && rs.data[0]) {
          //   console.log(rs.data[0])
          //   this.newdata = false
          //   this.searchDB.cate = ''
          //   this.searchDB.cate = 'DOMESTIC'
          //   Object.assign(this.selectedNode.domestic_data, rs.data[0].domestic_data)
          //   Object.assign(this.selectedNode.overseas_data, rs.data[0].overseas_data)
          // } else {
          //   this.newdata = true
          //   this.searchDB.cate = ''
          //   this.searchDB.cate = 'DOMESTIC'
          // }
          loading.close()
        })
      },
      getChartDateItem (date) {
        const loading = Loading.service()
        this.selectedNode = this.cloneData({
          idx: date.replace(/\-/g, ''),
          year: date.split('-')[0],
          month: date.split('-')[1],
          day: date.split('-')[2],
          name: date.split('-')[2]+'일'
        })
        this.selectedNode.domestic_data = this.cloneData(this.hotelData.DOMESTIC).map(row => {
          return {
            idx: row.idx,
            view_app: row.view_app,
            click_app: row.click_app,
            compare_pc: row.compare_pc,
            view_mobile: row.view_mobile,
            click_mobile: row.click_mobile,
            compare_mobile: row.compare_mobile
          }
        })
        this.selectedNode.overseas_data = this.cloneData(this.hotelData.OVERSEAS).map(row => {
          return {
            idx: row.idx,
            view_app: row.view_app,
            click_app: row.click_app
          }
        })

        const params = {params: {'date': date}}
        this.axios.get('/charts/search', params).then((rs) => {
          // console.log(rs)
          if (rs.data.length > 0) {
            this.newdata = false
            const bak = this.searchDB.cate
            this.searchDB.cate = ''
            this.searchDB.cate = bak
            let domestic = this.selectedNode.domestic_data
            domestic.map(slot => {
              rs.data.map(row => {
                if (slot.idx === row.hotel_idx) {
                  slot.date = row.date
                  slot.view_app = row.view_app
                  slot.click_app = row.click_app
                }
              })
            })
            let overseas = this.selectedNode.overseas_data
            overseas.map(slot => {
              rs.data.map(row => {
                if (slot.idx === row.hotel_idx) {
                  slot.date = row.date
                  slot.view_app = row.view_app
                  slot.click_app = row.click_app
                }
              })
            })
          } else {
            this.newdata = true
            const bak = this.searchDB.cate
            this.searchDB.cate = ''
            this.searchDB.cate = bak
          }
          // if (rs.data && rs.data[0]) {
          //   console.log(rs.data[0])
          //   this.newdata = false
          //   this.searchDB.cate = ''
          //   this.searchDB.cate = 'DOMESTIC'
          //   Object.assign(this.selectedNode.domestic_data, rs.data[0].domestic_data)
          //   Object.assign(this.selectedNode.overseas_data, rs.data[0].overseas_data)
          // } else {
          //   this.newdata = true
          //   this.searchDB.cate = ''
          //   this.searchDB.cate = 'DOMESTIC'
          // }
          // console.log(this.selectedNode)
          loading.close()
        })
      },
      handleNodeClick (data) {
        // console.log(data)
        if (!data.sections) {
          if(!this.selectedNode || this.selectedNode.idx !== data.idx) this.getChartItem(data)
        } else {
          this.selectedNode = {}
        }
      },
      handleDateClick (date) {
        if (date) {
          this.getChartDateItem(date)
        } else {
          this.selectedNode = {}
        }
      },
      append(data) {
        const newChild = { id: id++, label: 'added item', children: [] };
        if (!data.children) {
          this.$set(data, 'children', []);
        }
        data.children.push(newChild);
      },
      remove(node, data) {
        const parent = node.parent;
        const children = parent.data.children || parent.data;
        const index = children.findIndex(d => d.id === data.id);
        children.splice(index, 1);
      },
      saveDisplayCateData () {
        const loading = Loading.service()
        let params = this.cloneData(this.selectedNode)
        let method = 'post'
        let action = baseAction

        if (!this.newdata) {
          action += params.idx
          method = 'put'
        }

        delete params.name
        params.domestic_data = params.domestic_data.reduce((acc, cur) => {
          if (cur.view_app || cur.click_app)
            acc.push({
              idx: cur.idx,
              view_app: cur.view_app ? cur.view_app : 0,
              click_app: cur.click_app ? cur.click_app : 0
            })
          return acc
        }, [])
        params.overseas_data = params.overseas_data.reduce((acc, cur) => {
          if (cur.view_app || cur.click_app)
            acc.push({
              idx: cur.idx,
              view_app: cur.view_app ? cur.view_app : 0,
              click_app: cur.click_app ? cur.click_app : 0
            })
          return acc
        }, [])

        // console.log(params)

        let formData = new FormData()
        for (let key in params) {
          if (typeof params[key] === 'object') {
            formData.append(key, JSON.stringify(params[key]))
          } else {
            formData.append(key, params[key])
          }
        }
        // for (var key of formData.entries()) {
        //   console.log(key[0] + ', ' + key[1]);
        // }

        this.axios.post(action, formData).then((rs) => {
          if (rs.data.status === 'success') {
            // console.log(rs.data.status)
            this.newdata = false
          } else {
            swal({
              title: 'ERROR',
              text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
              type: 'error',
              confirmButtonClass: 'btn btn-info btn-fill',
              buttonsStyling: false
            })
          }
          loading.close()

        }).catch( error => {
          loading.close()
          console.log('failed', error)
        })
      },
    }
  }
</script>
<style scoped>
  label {
    font-weight: bold !important;
  }

  .BUTTON {
    position: absolute;
    top: 2px;
    right: 15px;
  }

  .fixed {
    position: fixed;
    top: 10px;
    width: 63vmax;
  }

  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }

  /deep/ .btn.btn-icon.btn-sm, .base-btn.btn-icon.btn-sm, .navbar .navbar-nav > a.btn.btn-icon.btn-sm {
    height: 1.2rem;
    min-width: 1.2rem;
    width: 1.2rem;
  }

  /deep/ .select-default.square .el-input input {
    border-radius: 5px;
    border-color: #DCDFE6 !important;
  }

  .primary {background-color: #1b2838!important;}
  .secondary {background-color: #ff6e61!important;}
  .accent {background-color: #9c27b0!important;}
  .positive {background-color: #21ba45!important;}
  .negative {background-color: #c10015!important;}
  .info {background-color: #31ccec!important;}
  .warning {background-color: #f2c037!important;}
  .white {background-color: #fff!important;}
  .black {background-color: #000!important;}
</style>
