import axios from 'axios'

const otaAxios = axios.create({baseURL: ''})

export default {
  getHotelForm: state => {
    return state.form
  },
  getOtaFullNameSearch: state => payload => {
    const params = {params: {apiKey: state.otaApiKey, query: payload}}
    return otaAxios.get('/ota/search/full', params)
  },
  getOtaAutocompleteSearch: state => payload => {
    const location = payload.cate === 'DOMESTIC' ? '대한민국' : ''
    const search = location ? payload.keyword + ' ' + location : payload.keyword
    const languageCode = payload.language
    const params = {params: {search: search, limit: 10, languageCode: languageCode}}
    return otaAxios.get('/autocomplete', params).then(rs => {
      return rs.data.reduce((acc, cur) => {
        if (cur.t === 'hotels') {
          if (payload.cate === 'OVERSEAS') {
            if (cur.cc !== 'KR') acc.push(cur)
          } else if (payload.cate === 'DOMESTIC') {
            acc.push(cur)
          }
        }
        return acc
      }, [])
    })
  },
  getOtaSiteURL: state => {
    return state.otaSiteURL
  },
  getOtaSingleHotelData: state => {
    return state.otaSingleHotelData.data
  },
  getOtaSingleHotelDataCancelToken: state => {
    return state.otaSingleHotelDataCancelToken
  },
  getOtaSingleHotelStatus: state => {
    return state.otaSingleHotelData.status
  },
  getOtaSingleHotelDatafeeds: state => {
    return state.otaSingleHotelDatafeeds
  },
  getOtaSingleHotelDatafeedsCancelToken: state => {
    return state.otaSingleHotelDatafeedsCancelToken
  },
  getOtaCodePinpointName: state => payload => {
    const ids = payload.id.indexOf('|') > -1 ? payload.id.split('|').map(row => Number(row)) : [Number(payload.id)]
    const code = JSON.parse(JSON.stringify(state.allOtaCodeData[payload.type]))
    const rtn = code.reduce((acc, cur) => {
      if (ids.includes(cur.id)) acc.push(cur.name)
      return acc
    }, []).join(', ')
    return rtn
  },
  getOtaCodePropertyType: state => {
    return state.allOtaCodeData['PropertyType']
  },
  getOtaCodeChain: state => {
    return state.allOtaCodeData['Chain']
  },
  getAllOtaCodeDataCnt: state => {
    return Object.keys(state.allOtaCodeData).length
  },

  getMapDisplayCateData: state => {
    return state.mapDisplayCate
  },
  getAllHotelDataFast: state => {
    return state.allHotelDataFast
  },
  getAllHotelDataFastCnt: state => {
    return state.allHotelDataFast.length
  },
  getAllHashTagData: state => {
    return state.allHashTagData
  },
  getAllHashTagDataCnt: state => {
    return state.allHashTagData.length
  },
  getAllHashTagOptions: (state, context) => {
    return context.getAllHashTagData.map(row => {
      return { idx: row.idx, name: row.name }
    })
  },
  getAllHotelBrandData: state => {
    return state.allHotelBrandData
  },
  getAllHotelBrandDataCnt: state => {
    return state.allHotelBrandData.length
  },
  getAllHotelBrandOptions: (state, context) => {
    return context.getAllHotelBrandDataCnt > 0
      ? context.getAllHotelBrandData.map(row => {
        row.checked = false
        return row
      })
      : []
  },
  getAllHotelRegionData: state => {
    return state.allHotelRegionData
  },
  getAllHotelRegionDataCnt: state => {
    return state.allHotelRegionData.length
  },
  getAllHotelRegionDepthData: state => {
    return state.allHotelRegionDepthData
  },
  getAllHotelRegionDepthDataCnt: state => {
    return state.allHotelRegionDepthData.length
  },
  getAllHotelRegionNationOptions: (state, context) => {
    return context.getAllHotelRegionDataCnt > 0
      ? context.getAllHotelRegionData.reduce((acc, cur) => {
          if (cur.idx_p === 0) {
            cur.checked = false
            acc.push(cur)
          }
          return acc
        }, [])
      : []
  },
  getAllHotelRegionCityOptions: (state, context) => nationIdx => {
    return context.getAllHotelRegionDataCnt > 0 && nationIdx
      ? context.getAllHotelRegionData.reduce((acc, cur) => {
        if (cur.idx_p === nationIdx) {
          cur.checked = false
          acc.push(cur)
        }
        return acc
      }, [])
      : []
  },
  getAllAirlineCorpData: state => {
    return state.allAirlineCorpData
  },
  getAllAirlineCorpDataCnt: state => {
    return state.allAirlineCorpData.length
  },
  getAllPostDataFast: state => {
    return state.allPostDataFast
  },
  getAllPostDataFastCnt: state => {
    return state.allPostDataFast.length
  },
  getAllVideoDataFast: state => {
    return state.allVideoDataFast
  },
  getAllVideoDataFastCnt: state => {
    return state.allVideoDataFast.length
  },
  getAllPostCateData: state => {
    return state.allPostCateData
  },
  getAllPostCateDataCnt: state => {
    return state.allPostCateData.length
  },
  getPostCateOptions: (state, context) => {
    return context.getAllPostCateData.map(row => {
      return {idx: row.idx, name:row.name}
    })
  },
  getAllDisplayCateData: state => {
    return state.allDisplayCateData
  },
  getAllDisplayCateDataCnt: state => {
    return state.allDisplayCateData.length
  },
  getAllMemberData: state => {
    return state.allMemberData
  },
  getAllMemberDataCnt: state => {
    return state.allMemberData.length
  },
  getMemberOptions: (state, context) => {
    return context.getAllMemberData.map(row => {
      return {idx: row.idx, name: row.name, prof_img: row.prof_img, id: row.id}
    })
  },
  getAllMemberClassData: state => {
    return state.allMemberClassData
  },
  getAllMemberClassDataCnt: state => {
    return state.allMemberClassData.length
  },
  getMemberClassOptions: (state, context) => {
    return context.getAllMemberClassDataCnt > 0
      ? context.getAllMemberClassData
      : [
          {idx: 1, name: '-', logo_img: ''},
          {idx: 2, name: '-', logo_img: ''},
          {idx: 3, name: '-', logo_img: ''},
          {idx: 4, name: '-', logo_img: ''},
          {idx: 5, name: '-', logo_img: ''},
        ]
  },
  getAllMemberAuthData: state => {
    return state.allMemberAuthData
  },
  getAllMemberAuthDataCnt: state => {
    return state.allMemberAuthData.length
  },
  getSeoMetaDataDefault: state => {
    return state.seoMetaDataDefault
  },
  getSeoMetaDataDefaultCnt: state => {
    return state.seoMetaDataDefault.length
  },
  getCalendarData: state => {
    return state.calendarData
  },
  getCalendarDataCnt: state => {
    return state.calendarData.length
  },
  getMyMemberAuthData: state => {
    return state.myMemberAuthData
  },
  getMyMemberAuthDataCnt: state => {
    return state.myMemberAuthData.length
  },
  getThisPageAuthData: (state, context) => payload => {
    if (!payload) payload = vm.$route.path
    const rtn = context.getMyMemberAuthData ? context.getMyMemberAuthData.menu.find(row => row.path === payload) : ''
    return rtn ? rtn.auth : ''
  },
  getCheckThisPageCreate: (state, context) => {
    return !context.getMyMemberAuthData || context.getThisPageAuthData().includes('C')
  },
  getCheckThisPageRead: (state, context) => {
    return !context.getMyMemberAuthData || context.getThisPageAuthData().includes('R')
  },
  getCheckThisPageModify: (state, context) => {
    return !context.getMyMemberAuthData || context.getThisPageAuthData().includes('U')
  },
  getCheckThisPageDelete: (state, context) => {
    return !context.getMyMemberAuthData || context.getThisPageAuthData().includes('D')
  },
  getFirstMyPageAuthData: (state, context) => {
    return context.getMyMemberAuthData.menu ? context.getMyMemberAuthData.menu.find(row => row.auth.includes('R')) : null
  },
  getAllUserData: state => {
    return state.allUserData
  },
  getAllUserDataCnt: state => {
    return state.allUserData.length
  },

}
