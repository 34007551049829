<template>
  <div class="card">
    <div class="card-header">
      <h5 class="title">해시태그</h5>
    </div>
    <div class="card-body">

          <fg-input>
            <el-select multiple
                       class="select-default"
                       size="large"
                       value-key="idx"
                       v-model="hotel.hash_tags"
                       placeholder="해시태그 선택">
              <el-option v-for="option in getAllHashTagOptions"
                         class="select-primary"
                         :value="option"
                         :label="`#${option.name}`"
                         :key="option.idx">
              </el-option>
            </el-select>
          </fg-input>

      <label v-if="getAllHashTagDataCnt < 1">해시태그 관리에서 태그를 등록하세요.</label>

    </div>
  </div>
</template>
<script>
  import {Select, Option} from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import { mapGetters } from 'vuex'
  import commonMethods from 'src/mixins/common_methods'
  import forms from '../forms'
  export default {
    name: "HashTagFrom",
    components: {
      PSwitch,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data () {
      return {
        hotel: forms
      }
    },
    computed: {
      ...mapGetters([
        'getAllHashTagDataCnt',
        'getAllHashTagOptions'
      ])
    },
    methods: {
      ...commonMethods
    }
  }
</script>
<style scoped>
  .bootstrap-switch {
    font-weight: bold;
  }
  .SWITCH {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  label {
    font-weight: bold !important;
  }
</style>
