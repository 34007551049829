import Vue from "vue";
import { shinhanDisplayCateIdxs } from 'src/mixins/common_variables.js'

export default {
  unload ($event) {
    let confirmationMessage = "\o/";
    $event.returnValue = confirmationMessage
    return confirmationMessage
  },
  cloneData (original) {
    return JSON.parse(JSON.stringify( (original instanceof Array) ? Object.assign([], original) : Object.assign({}, original) ))
  },
  isFileExist (obj) {
    return obj && obj.url ? true : false
  },
  getFileList (obj) {
    return this.isFileExist(obj) ? [obj] : []
  },
  getImgOrientation (file, target) {
    let img = new Image()
    const vm = this
    img.addEventListener("load", function () {
      file.orientation = img.width > img.height ? 'landscape' : 'portrait'
      vm.setImgIntoData(file, target)
    })
    img.src = file.url
  },
  setImgIntoData (file, target) {
    const target_id = target.$el.id
    if (target.multiple) {
      this.selectDataByFile()[target_id].push(file)
    } else {
      this.selectDataByFile()[target_id] = file
    }
    if (this.uploadData) {
      if (target.multiple) {
        this.uploadData[target_id] ? this.uploadData[target_id].push(file) : this.uploadData[target_id] = [file]
        if (!this.hotel.main_img.url) this.hotel.main_img = this.cloneData(file)
      } else {
        this.uploadData[target_id] = file
      }
    }
  },
  handlePreview (file) {
    if (this.dialogImage) {
      this.dialogImage = file
    } else {
      this.dialogImageUrl = file.url
    }
    this.dialogVisible = true
  },
  handleChange (file) {
    if (event.path.length > 0) {
      const obj_id = event.path[2].id
      const target = this.$refs[obj_id]
      if (!target.autoUpload) {
        this.getImgOrientation(file, target)
      }
    }
  },
  handleRemove (file, fileList) {
    const depth =
      event.path[0].className === 'el-icon-delete' ? 5 :
      event.path[0].className === 'el-icon-close' ? 3 : 4
    const target_id = event.path[depth].id
    const target = this.$refs[target_id]
    if (target.multiple) { // multiple upload
      if (target.autoUpload) {
        this.removeUploadFile(file, target_id)
      } else {
        if (this.uploadData[target_id]) {
          this.uploadData[target_id].map((row, i) => {
            if (row.uid === file.uid) {
              this.uploadData[target_id].splice(i, 1)
            }
          })
          if (file.url === this.hotel.main_img.url) this.hotel.main_img.url = ''
        }
      }
    } else {  // single upload
      if (target) target.clearFiles()
      if (this.uploadData) this.uploadData[target_id] = {}
    }
  },
  selectDataByFile () {
    return this.hotel ? this.hotel :
      this.post ? this.post : this.formData
  },
  saveSorting () {
    this.loading = true
    const params = {idxs: this.tableData.map(row => row.idx)}
    this.axios.put(this.baseAction + 'sort', params).then(rs => {
      if (rs.data.status === 'success') {
        this.tableDataOrigin = this.cloneData(this.tableData)
      } else {
        alert('error')
      }
      this.loading = false
    })
  },
  limitInputLine (event, obj, keyName, limit) {
    if ( (event.match(/\n/g) || []).length > 1 ) {
      let tmp = event.split(/\n/g)
      obj[keyName] = (tmp.slice(0, limit)).join('\n')
    }
  },
  getCurrentDate () {
    const dt = new Date()
    const rtn = `${dt.getFullYear()}-${String(dt.getMonth()+1).padStart(2,'0')}-${String(dt.getDate()).padStart(2,'0')}`
    return rtn
  },
  downloadExcelFile (name, data, cols, type) {
// return
    const params = { name: name, data: JSON.stringify(data), cols: JSON.stringify(cols) }

    const form = document.createElement("form")
    form.setAttribute('charset', 'UTF-8')
    form.setAttribute('method', 'post')
    form.setAttribute('action', Vue.prototype.$apiURL + '/excel/download/' + type)

    Object.keys(params).map(key => {
      const input = document.createElement("input")
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', params[key])
      form.appendChild(input)
    })

    document.body.appendChild(form)
    form.submit()

    setTimeout(() => {
      document.body.removeChild(form)
    }, 2000)
  }
}
