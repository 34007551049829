import Vue from 'vue'
import App from './App.vue'
import store from './vuex'
import Bootaxios from 'src/boot/axios'
import Bootimports from 'src/boot/imports'
import VueRouter from 'vue-router'
import VueRouterPrefetch from 'vue-router-prefetch'
import VueCookies from 'vue-cookies'
import VueLocalStorage from 'vue-localstorage'
import VueNotify from 'vue-notifyjs'
import VeeValidate from 'vee-validate'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import './pollyfills'

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'

// router setup
import routes from './routes/routes'

// library imports
import './assets/sass/paper-dashboard.scss'
import './assets/sass/demo.scss'
import sidebarLinks from './sidebarLinks'
import './registerServiceWorker'

// Froala Editor
require('froala-editor/js/froala_editor.pkgd.min')
require('froala-editor/css/froala_editor.pkgd.min.css')
require('font-awesome/css/font-awesome.css')
require('froala-editor/css/froala_style.min.css')
import VueFroala from 'vue-froala-wysiwyg'


// plugin setup
Vue.use(VueRouter)
Vue.use(VueRouterPrefetch)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)
Vue.use(VueNotify)
Vue.use(SideBar, {sidebarLinks: sidebarLinks})
Vue.use(VeeValidate)
Vue.use(VueFroala)
Vue.use(VueCookies)
Vue.use(VueLocalStorage)
locale.use(lang)


// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// initProgress(router);

/* eslint-disable no-new */

async function start () {

  const app = {
    el: '#app',
    router,
    store,
    render: h => h(App)
  }

  const bootFiles = [Bootaxios, Bootimports]
  for (let i = 0; i < bootFiles.length; i++) {
    if (typeof bootFiles[i] !== 'function') {
      continue
    }

    try {
      await bootFiles[i]({
        app,
        router,
        store,
        Vue,
        ssrContext: null
      })
    }
    catch (err) {
      if (err && err.url) {
        window.location.href = err.url
        return
      }
      console.error('boot error:', err)
      return
    }
  }
  new Vue(app)
}

start()
