<template>
<div class="STAT_CHART">
    <div class="col-md-12 row pr-0">
      <div class="col-md-3">
        <h5 class="title">{{pageName}}</h5>
        <p class="category">{{pageName}} 입니다.</p>
      </div>
      <div class="col-md-9 pr-0">
        <div class="form-group">
          <br>
          <div class="pull-right mt-2">
            <p class="category">검색된 <b>통계</b>는 총 <b class="text-info">{{pagination.total}}</b>개 입니다. </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 card LIST">
      <div class="card-body table-full-width">
        <div class="row">
          <div class="col-sm-7">
            <fieldset>
              <div class="form-group">
                <div class="row">
                  <div class="col-sm-3 pr-0">
                    <el-select
                      class="select-info"
                      v-model="searchDB.cate"
                      @change="getChartList"
                      :min-width="100"
                      placeholder="호텔종류 전체">
                      <el-option value=""></el-option>
                      <el-option value="DOMESTIC" label="국내"></el-option>
                      <el-option value="OVERSEAS" label="해외"></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="col-sm-5">
            <div class="col-sm-10 pull-right">
              <div class="row">
                <div class="col-sm-3 pr-0">
                  <el-select
                    class="select-default square"
                    v-model="searchQuery.date_mode"
                    @change="getChartList"
                    placeholder="기간모드 선택">
                    <el-option class="text-grey-7" label="기간별" value="P"></el-option>
                    <el-option class="text-grey-7" label="주간별" value="W"></el-option>
                  </el-select>
                </div>
                <div class="col-sm-9 pr-0 pl-1">
                  <el-date-picker
                    v-if="searchQuery.date_mode === 'P'"
                    style="width: 100%"
                    v-model="searchDB.date"
                    @change="getChartList"
                    type="daterange"
                    unlink-panels
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions2">
                  </el-date-picker>

                  <el-select
                    v-else
                    class="select-default square"
                    v-model="searchDB.dateweek"
                    @change="getChartList"
                    placeholder="주간 전체">
                    <el-option
                      v-for="item in weeklyList"
                      :key="item.date"
                      :label="item.label"
                      :value="item.date"
                    />
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-7">
<!--            <div class="row">-->
<!--              <div class="col-sm-3 pr-0">-->
<!--                <el-select-->
<!--                  class="select-default"-->
<!--                  v-model="searchQuery.annual"-->
<!--                  placeholder="연회비 전체">-->
<!--                  <el-option value=""></el-option>-->
<!--                  <el-option value="0-10000" label="1만원 미만"></el-option>-->
<!--                  <el-option value="10000-30000" label="1만원 - 3만원"></el-option>-->
<!--                  <el-option value="30000-50000" label="3만원 - 5만원"></el-option>-->
<!--                  <el-option value="50000-10000000" label="5만원 이상"></el-option>-->
<!--                </el-select>-->
<!--              </div>-->
<!--              <div class="col-sm-3 pr-0 pl-1">-->
<!--                <el-select-->
<!--                  class="select-default"-->
<!--                  v-model="searchQuery.pre"-->
<!--                  placeholder="전월실적 전체">-->
<!--                  <el-option value=""></el-option>-->
<!--                  <el-option value="0-300000" label="30만원 이하"></el-option>-->
<!--                  <el-option value="300000-1000000000" label="30만원 초과"></el-option>-->
<!--                </el-select>-->
<!--              </div>-->
<!--              <div class="col-sm-6 pr-0 pl-1">-->

<!--              </div>-->
<!--            </div>-->
          </div>
          <div class="col-sm-5">
            <div class="col-10 pr-0 pull-right">
              <fg-input class=""
                        placeholder="호텔명 검색"
                        v-model="searchQuery.name"
                        addon-right-icon="nc-icon nc-zoom-split">
              </fg-input>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mt-1">
          <el-table class="table-striped"
                    header-row-class-name="text-default"
                    :data="queriedData"
                    v-loading="loading"
                    border
                    style="width: 100%">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :prop="column.prop"
                             :label="column.label">
              <template slot-scope="props" class="info-text">
                <div v-if="column.prop === 'name'">
                  <a v-if="!props.row.deleted" :href="`get/hotel/detail/${props.row.idx}`" target="_blank" >{{props.row.name}}</a>
                  <span v-else>{{props.row.name}}</span>
                </div>
                <div v-else-if="column.prop === 'chart_img'"><img v-if="isFileExist(props.row.chart_img)" :src="props.row.chart_img.url" width="30px" height="30px" /></div>
                <div v-else-if="column.prop.substr(0,15) === 'search_benefit_'">
                  <span style="font-size: 12px; line-height: 14px; display: inline-block">{{props.row[column.prop]}}</span>
                </div>
                <div v-else>
                  <span>{{props.row[column.prop]}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-for="column in tableColumns2"
                             v-if="column.prop !== 'gb' || (folding === 'no' && column.prop === 'gb')"
                             :key="column.label"
                             fixed="right"
                             class-name="td-actions text-center"
                             :min-width="column.minWidth"
                             :prop="column.prop"
                             :label="column.label">
              <template slot-scope="props">
                <div v-if="column.prop === 'gb'" class="text-right">
                  <span>total</span>
                  <div>pc</div>
                  <div>m</div>
                </div>
                <div v-else>
                  <span v-if="folding === 'yes'">{{props.row[column.prop]}}</span>
                  <div v-else>
                    <div>{{props.row[column.prop]}}</div>
                    <div v-if="column.prop === 'view_cnt' || column.prop === 'click_cnt'">
                      <div>{{props.row[column.prop.replace('_cnt', '_app')]}}</div>
                    </div>
                    <div v-else-if="column.prop === 'total' || column.prop === 'score'">
                      <div>{{props.row[column.prop + '_app']}}</div>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="card-body row table-full-width">
        <div class="col-sm-3">
          <div class="">
            <el-select
              class="select"
              v-model="pagination.perPage"
              placeholder="Per page">
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="`${item} rows`"
                :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="pagination-info mt-1">
            <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
          </div>
        </div>
        <div class="col-sm-6">
          <p-pagination v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </p-pagination>
        </div>
        <div class="col-sm-3">
          <p class="pull-right">
            <p-button type="info" round icon :disabled="loading" @click="downloadExcelFile(getFileName, getTableResult, getTableColumns, 'table_list')">
              <i class="fa fa-download"></i>
            </p-button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import {Table, TableColumn, Select, Option, DatePicker, Switch} from 'element-ui'
  import swal from 'sweetalert2'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import commonMethods from 'src/mixins/common_methods'
  import charts from './charts'
  const baseAction = '/charts/'
  export default{
    name: "ChartManager",
    components: {
      PSwitch,
      PPagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      [Switch.name]: Switch,
    },
    data () {
      return {
        baseAction: baseAction,
        tableData: charts,
        tableResult: [],
        pageName: '차트통계 관리',
        loading: true,
        pagination: {
          perPage: 100,
          currentPage: 1,
          perPageOptions: [10, 30, 50, 100, 400],
          total: 0
        },
        folding: 'yes',
        searchDB: {
          'cate': '',
          'date': [this.getCurrentDate(), this.getCurrentDate()],
          'dateweek': ''
        },
        searchQuery: {
          'date_mode': 'P',
          'corp': '',
          'c_type': '',
          'annual': '',
          'pre': '',
          'name': '',
          'search_benefit': []
        },
        propsToSearch: ['corp', 'c_type', 'annual', 'pre', 'name', 'search_benefit'],
        tableColumns: [
          {
            prop: 'ranking',
            label: '순위',
            minWidth: 65
          },
          {
            prop: 'cate_txt',
            label: '호텔종류',
            minWidth: 90
          },
          // {
          //   prop: 'corp_name',
          //   label: '카드사',
          //   minWidth: 120
          // },
          {
            prop: 'name',
            label: '호텔명',
            minWidth: 340,
          }
        ],
        tableColumns2: [
          {
            prop: 'gb',
            label: '',
            minWidth: 90
          },
          {
            prop: 'view_cnt',
            label: 'PV',
            minWidth: 80
          },
          {
            prop: 'click_cnt',
            label: '클릭수',
            minWidth: 80
          },
          {
            prop: 'total',
            label: '합계',
            minWidth: 100
          },
          {
            prop: 'score',
            label: '차트스코어',
            minWidth: 100
          },
        ],
        pickerOptions: {
          shortcuts: [{
            text: 'Today',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
            {
              text: 'Yesterday',
              onClick (picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
              }
            },
            {
              text: 'A week ago',
              onClick (picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', date)
              }
            }]
        },
        cateOption: [],
        weeklyList: [],
        keyword: '',
        datePicker: '',
        pickerOptions2: {
          shortcuts: [{
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      }
    },
    mounted () {
      this.$store.dispatch('callAllHashTagData')
      this.getChartList()
      this.$store.dispatch('callCalendarData').then(() => {
        this.getCalendarList()
      })
    },
    computed: {
      ...mapGetters([
        'getAllHashTagOptions',
        'getCalendarData'
      ]),
      pagedData () {
        this.tableResult = this.tableData
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        let searchQueryData = Object.values(this.searchQuery).join('')
        if (!searchQueryData) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        let result = this.tableData.filter((row) => {
          if (!row['deleted']) {
            let searchPropsCnt = 0
            let isIncludedCnt = 0
            for (let key of this.propsToSearch) {
              let rowValue, idxValue
              let searchValue = this.searchQuery[key].toString().toLowerCase()
              if (searchValue) {
                searchPropsCnt++
                if (key === 'name') {
                  rowValue = row[key].toString().toLowerCase()
                  idxValue = row['hotel_idx'].toString()
                  if ((rowValue.includes && rowValue.includes(searchValue))
                    || (idxValue.includes && idxValue === (searchValue))) {
                    isIncludedCnt++
                  }
                } else if (key === 'corp') {
                  rowValue = row[key].idx.toString().toLowerCase()
                  if (rowValue === searchValue) {
                    isIncludedCnt++
                  }
                } else if (key === 'annual') {
                  let arr = row.annual_fee_basic.split('[')
                  rowValue = arr && arr.length > 1 ? arr[1].split(']')[0] : arr[0]
                  rowValue = rowValue === '없음' ? 0 : Number(rowValue.replace(/\,/g, '').replace('원', ''))
                  const searchArr = searchValue.split('-')
                  const minValue = Number(searchArr[0])
                  const maxValue = Number(searchArr[1])
                  if (rowValue >= minValue && rowValue < maxValue) {
                    isIncludedCnt++
                  }
                } else if (key === 'pre') {
                  rowValue = row.pre_month_money.toString().replace(/\,/g, '')
                  const searchArr = searchValue.split('-')
                  const minValue = Number(searchArr[0])
                  const maxValue = Number(searchArr[1])
                  if (rowValue >= minValue && rowValue < maxValue) {
                    isIncludedCnt++
                  }
                } else if (key === 'search_benefit') {
                  searchValue = searchValue.split(',')
                  rowValue = row.search_benefit.reduce((acc, cur) => {
                    if (searchValue.indexOf(cur.value.toString()) > -1) {
                      acc.push(cur.value)
                    }
                    if (cur.options) {
                      cur.options.reduce((acc2, cur2) => {
                        if (searchValue.indexOf(cur2.value.toString()) > -1) {
                          acc.push(cur2.value)
                        }
                      }, [])
                    }
                    return acc
                  }, [])

                  rowValue = rowValue.reduce((a, b) => {
                    if (a.indexOf(b) < 0) a.push(b)
                    return a
                  }, [])

                  // if (rowValue.length>0) console.log(rowValue, '------', searchValue)

                  if (rowValue.length >= searchValue.length) {
                    isIncludedCnt++
                  }
                } else {
                  rowValue = row[key] ? row[key].toString().toLowerCase() : ''
                  if (rowValue.includes && rowValue === searchValue) {
                    isIncludedCnt++
                  }
                }
              }
            }
            return searchPropsCnt === isIncludedCnt
          }
        })
        this.pagination.total = result.length
        this.tableResult = result
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      getFileName () {
        return `${this.pageName.replace(/\ /g, '')}_${this.searchQuery.date_mode === 'P' ? '기간별' : '주간별'}_(${this.searchDB.date.join('_')})`
      },
      getTableResult () {
        return this.tableResult
      },
      getTableColumns () {
        return this.tableColumns.concat(this.tableColumns2)
      }
    },
    methods: {
      ...commonMethods,
      isFileExist (obj) {
        return obj && obj.url ? true : false
      },
      getCalendarList () {
        this.weeklyList = this.getCalendarData.weekly
        this.searchDB.dateweek = this.getCalendarData.weekly[0].date
      },
      getChartCateData () {
        this.axios.get('/chart_cates/all').then((rs) => {
          this.cateOption = rs.data.map(row => {
            return {idx: row.idx, name:row.name}
          })
        })
      },
      getChartList () {
        this.loading = true
        let params = {params: {}}
        params.params.date_mode = this.searchQuery.date_mode
        if (this.searchDB.cate) params.params.cate = this.searchDB.cate
        if (this.searchDB.date) params.params.date = this.searchDB.date.join(',')
        if (this.searchDB.dateweek) params.params.dateweek = this.searchDB.dateweek
        this.axios.get('/charts/statistics', params).then((rs) => {
          rs.data.map(row => {
            row.cate_txt = row.cate === 'DOMESTIC' ? '국내' : row.cate === 'OVERSEAS' ? '해외' : ''
            row.deleted = !row.name ? true : false
            row.name = row.name ? row.name : '삭제된 호텔입니다.'
          })
          this.tableData = rs.data
          // console.log(this.tableData)
          this.loading = false
        })
      },
      changeIsVisibled (row) {
        let params = {idx: row.idx, is_visible: row.is_visible};
        let action = baseAction + 'visible/' + params.idx
        this.axios.put(action, params).then((rs) => {
          if (rs.data.status === 'success') {
            this.setChartList('put', rs.data.data[0])
          }
        }).catch( error => {
          console.log('failed', error)
        })
      },
      setChartList (method, row) {
        if(method === 'put') {
          for (let key in this.tableData) {
            if (this.tableData[key].idx === row.idx) {
              row.no = this.tableData.length
              this.tableData.splice(key, 1, row)
              break
            }
          }
        } else {
          this.tableData.unshift(row)
        }
      },
      setFoldingList (val) {

      },
      handleEdit(index, row) {
        this.$refs.modal.openModal(row)
      },
      handleDelete(index, row) {
        let $this = this
        swal({
          title: '삭제하시겠습니까?',
          // text: '삭제하면 관련',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          buttonsStyling: false
        }).then(function () {
          $this.loading = true
          const action = baseAction + row.idx
          $this.axios.delete(action).then((rs) => {
            $this.loading = false
            if (rs.data.status === 'success') {
              const indexToDelete = $this.tableData.findIndex((tableRow) => tableRow.idx === row.idx)
              if (indexToDelete >= 0) {
                $this.tableData.splice(indexToDelete, 1)
              }
            } else {
              swal({
                title: 'ERROR',
                text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
                type: 'error',
                confirmButtonClass: 'btn btn-info btn-fill',
                buttonsStyling: false
              })
            }
          }).catch( error => {
            $this.loading = false
          })
        }, function (dismiss) {

        })
      },
    }
  }
</script>
<style scoped lang="scss">
  /deep/ .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
  /deep/ .LIST .el-range-separator {
    width: 30px !important;
  }
  /deep/ .LIST .el-select {
    width: 100% !important;
  }
  /deep/ .LIST .el-select:last-child {
    margin-right:0;
  }
  /deep/ .LIST .select-default.square .el-input input {
    border-radius: 5px;
    border-color: #DCDFE6 !important;
  }
  /deep/ .modal-dialog {
    padding-top: 0 !important;
    width: 95% !important;
    max-width: 1919px !important;
    margin: 2.5rem auto !important;
  }
  /deep/ .modal-body {
    background-color: #f4f3ef;
    padding: 20px 30px !important;
  }
</style>
