export default [
  {
    'idx': 1,
    'pid': '',
    'cate': '',
    'cate_txt': '',
    'post_img': '',
    'title': '',
    'editor': '',
    'reg_dt': '',
    'is_visible': false,
  },
]
