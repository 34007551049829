<template>
  <modal :show.sync="isModalOpen" :multiOpen="true" loading="true"
         footer-classes="justify-content-center"
  >
    <h5 slot="header" class="modal-title text-left">{{titles[model]}}선택</h5>
    <template class="bg-light">
      <div v-if="model === 'hotels'" class="row cards">
        <div class="col-sm-4 col-md-3 col-lg-2 px-1" v-for="(hotel, index) in getAllHotelDataFast" :key="index">
          <p-button class="card justify-content-center h6" @click="selectData(hotel)" block>
            <img v-if="hotel.main_img" :src="hotel.main_img.url" width="auto" height="auto" class="justify-content-center" />
            {{hotel.name}}
          </p-button>
        </div>
      </div>
      <div v-if="model === 'flights'" class="row cards">
        <div class="col-sm-4 col-md-3 col-lg-2 px-1" v-for="(airline, index) in getAllAirlineCorpData" :key="index">
          <p-button class="card justify-content-center h6" @click="selectData(airline)" block>
            <img v-if="isFileExist(airline.logo_img)" :src="airline.logo_img.url" width="auto" height="auto" class="justify-content-center" />
            {{airline.name}}
          </p-button>
        </div>
      </div>
      <ul v-else-if="model === 'videos'" class="el-upload-list el-upload-list--picture posts">
        <p-button class="el-upload-list__item is-ready border-white my-1 text-muted" v-for="(video, index) in getAllVideoDataFast" :key="index" @click="selectData(video)">
          <img v-if="video.thumbnail" :src="video.thumbnail" alt="" class="el-upload-list__item-thumbnail">
          <div class="position-absolute pt-3 pl-1 text-info">VIDEO</div>
          <a class="el-upload-list__item-name mt-4 pt-2 h6 text-left" style="font-size: 14px">
            <i class="el-icon-video-camera"></i>{{video.title}}
          </a>
          <label class="position-absolute pr-3 text-right" style="right:0; top:30px;">
            {{video.reg_dt}}
          </label>
        </p-button>
      </ul>
      <ul v-else-if="model === 'posts'" class="el-upload-list el-upload-list--picture posts">
        <p-button class="el-upload-list__item is-ready border-white my-1 text-muted" v-for="(post, index) in getAllPostDataFast" :key="index" @click="selectData(post)">
          <img v-if="isFileExist(post.post_img)" :src="post.post_img.url" alt="" class="el-upload-list__item-thumbnail">
          <div class="position-absolute pt-3 pl-1 text-info">{{post.post_cate_txt}}</div>
          <a class="el-upload-list__item-name mt-4 pt-2 h6 text-left" style="font-size: 14px">
            <i class="el-icon-document"></i>{{post.title}}
          </a>
          <label class="position-absolute pr-3 text-right" style="right:0; top:30px;">
            {{post.writer_name}} ({{post.writer_nick}})<br/>
            {{post.reg_dt}}
          </label>
        </p-button>
      </ul>
    </template>
    <div slot="footer" class="justify-content-center">
      <p-button type="info" size="lg" wide round @click="isModalOpen=false">{{btnText}}</p-button>
    </div>
  </modal>
</template>
<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import Modal from 'src/components/UIComponents/Modal.vue'
  import { Loading } from 'element-ui'
  export default {
    name: 'HotelSelector',
    components: {
      Modal,
      Loading
    },
    props: {
      model: ''
    },
    watch: {
      isModalOpen (val) {
        this.loading = val
      }
    },
    mounted () {
      this.$store.dispatch('callAllHotelDataFast')
      this.$store.dispatch('callAllAirlineCorpData')
      this.$store.dispatch('callAllVideoDataFast')
      this.$store.dispatch('callAllPostDataFast')
    },
    computed: {
      ...mapGetters([
        'getAllHotelDataFast',
        'getAllAirlineCorpData',
        'getAllVideoDataFast',
        'getAllPostDataFast'
      ])
    },
    methods: {
      isFileExist (obj) {
        return obj && obj.url ? true : false
      },
      cloneData (original) {
        return JSON.parse(JSON.stringify(
          (original instanceof Array) ? Object.assign([], original) : Object.assign({}, original)
        ))
      },
      selectData (data) {
        this.$emit('selected-data', data)
      }
    },
    data () {
      return {
        titles: {
          'hotels': '호텔',
          'flights': '항공',
          'videos': '비디오',
          'posts': '포스트'
        },
        isModalOpen: false,
        btnText: '닫기',
      }
    }
  }
</script>
<style scoped>
  .BUTTON {
    font-size: 1.2em;
  }
  .cards img {
    max-width: 100%;
    max-height: 100%;
  }
  .posts img {
    background-color: #eee;
    max-width: 80px;
    max-height: 80px;
  }
  /deep/ .card .card-body { min-height: 140px; }

</style>
