export default {
  allHotelDataFast: [],
  allHashTagData: [],
  allHotelBrandData: [],
  allHotelRegionData: [],
  allHotelRegionDepthData: [],
  allAirlineCorpData: [],
  allPostDataFast: [],
  allPostCateData: [],
  allVideoDataFast: [],
  allDisplayCateData: [],
  allMemberData: [],
  allMemberClassData: [],
  allMemberAuthData: [],
  seoMetaDataDefault: [],
  calendarData: [],
  myMemberAuthData: [],

  //OTA (Hotels Combined)
  // otaApiKey: 'F7538EB2-2B63-4AE1-8C43-2FAD2D83EACB',   // 테스트 sandbox 용
  // otaApiURL: 'http://sandbox.hotelscombined.com/api/2.0',  // 테스트 sandbox 용
  otaApiKey: 'B11145AC-D02B-4CFF-A36A-3F3F3D708B9E',
  otaApiURL: 'http://561087.api.hotelscombined.com/api/2.0',
  otaSiteURL: 'https://www.hotelscombined.com',
  otaApiMethod: {
    'fullNameSearch': '/search/full',
    'multipleHotelSearch': '/hotels',
    'basicMultipleHotelSearch': '/hotels/basic',
    'singleHotelSearch': '/hotel'
  },
  otaAutoCompleteURL: 'http://www.hotelscombined.com/AutoUniversal.ashx',
  otaDatafeedsURL: 'http://datafeeds.hotelscombined.com/Datafeed.ashx',
  otaDatafeedsID: '215766',
  otaDatafeedsPrivateDataKey: '3595877347',
  otaSingleHotelData: {},
  otaSingleHotelDataCancelToken: null,
  otaSingleHotelDatafeeds: {},
  otaSingleHotelDatafeedsCancelToken: null,
  allOtaCodeData: [],
  allUserData: [],

  //display cate map
  mapDisplayCate: [
    {
      "idx": 1,
      "name": "공통",
      "sections": [
        {
          "idx": 2,
          "name": "레이어배너",
          "arrow": true,
          "title": "",
          "width": "",
          "arrows": false,
          "effect": {"idx": 1},
          "height": "40px",
          "initial": "",
          "duration": "",
          "interval": 800000,
          "swipeable": false,
          "navigation": false,
          "control_color": "primary"
        },
        {
          "idx": 3,
          "name": "토스트 배너",
          "effect": {"idx": 1},
          "duration": 15000,
          "interval": 500000
        },
        {
          "idx": 4,
          "name": "메뉴바",
          "effect": {"idx": 1},
          "duration": 30000,
          "interval": 500000
        }
      ]
    },
    {
      "idx": 5,
      "name": "메인",
      "sections": [
        {
          "idx": 6,
          "name": "메인셀렉션[1]",
          "effect": {"idx": 1}
        },
        {
          "idx": 7,
          "name": "추천섹션[1]",
          "sections": [
            {
              "idx": 8,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 9,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 10,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 11,
              "name": "4 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 12,
              "name": "5 슬롯",
              "effect": {"idx": 1}
            }
          ]
        },
        {
          "idx": 13,
          "name": "콘텐츠섹션[1]",
          "sections": [
            {
              "idx": 14,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 15,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 16,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            }
          ]
        },
        {
          "idx": 17,
          "name": "호텔섹션[1]",
          "sections": [
            {
              "idx": 18,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 19,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 20,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            }
          ]
        },
        {
          "idx": 21,
          "name": "콘텐츠섹션[2]",
          "sections": [
            {
              "idx": 22,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 23,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 24,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            }
          ]
        },
        {
          "idx": 25,
          "name": "영상섹션[1]",
          "effect": {"idx": 1}
        }
      ]
    },
    {
      "idx": 33,
      "name": "추천",
      "sections": [
        {
          "idx": 34,
          "name": "메인셀렉션[1]",
          "effect": {"idx": 1}
        },
        {
          "idx": 35,
          "name": "콘텐츠섹션[1]",
          "sections": [
            {
              "idx": 36,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 37,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 38,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 39,
              "name": "4 슬롯",
              "effect": {"idx": 1}
            }
          ]
        },
        {
          "idx": 40,
          "name": "콘텐츠섹션[2]",
          "sections": [
            {
              "idx": 41,
              "name": "1 슬롯"
            },
            {
              "idx": 42,
              "name": "2 슬롯"
            },
            {
              "idx": 43,
              "name": "3 슬롯"
            }
          ]
        },
        {
          "idx": 44,
          "name": "콘텐츠섹션[3]",
          "sections": [
            {
              "idx": 45,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 46,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 47,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            }
          ]
        },
        {
          "idx": 48,
          "name": "콘텐츠섹션[4]",
          "sections": [
            {
              "idx": 49,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 50,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            }
          ]
        }
      ]
    },
    {
      "idx": 51,
      "name": "랭킹",
      "sections": [
        {
          "idx": 52,
          "name": "콘텐츠섹션[1]",
          "sections": [
            {
              "idx": 53,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 54,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 55,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 56,
              "name": "4 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 57,
              "name": "5 슬롯",
              "effect": {"idx": 1}
            }
          ]
        },
        {
          "idx": 58,
          "name": "콘텐츠섹션[2]",
          "sections": [
            {
              "idx": 59,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 60,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 61,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 62,
              "name": "4 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 63,
              "name": "5 슬롯",
              "effect": {"idx": 1}
            }
          ]
        }
      ]
    },
    {
      "idx": 64,
      "name": "검색메인",
      "sections": [
        {
          "idx": 65,
          "name": "메인셀렉션[1]",
          "effect": {"idx": 1}
        },
        {
          "idx": 66,
          "name": "추천섹션[1]",
          "sections": [
            {
              "idx": 67,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 68,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 69,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 70,
              "name": "4 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 71,
              "name": "5 슬롯",
              "effect": {"idx": 1}
            }
          ]
        },
        {
          "idx": 72,
          "name": "호텔섹션[1]",
          "sections": [
            {
              "idx": 73,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 74,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 75,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 76,
              "name": "4 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 77,
              "name": "5 슬롯",
              "effect": {"idx": 1}
            }
          ]
        },
        {
          "idx": 78,
          "name": "호텔섹션[2]",
          "sections": [
            {
              "idx": 79,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 80,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 81,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 82,
              "name": "4 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 83,
              "name": "5 슬롯",
              "effect": {"idx": 1}
            }
          ]
        },
        {
          "idx": 84,
          "name": "콘텐츠섹션[1]",
          "sections": [
            {
              "idx": 85,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 86,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 87,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 88,
              "name": "4 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 89,
              "name": "5 슬롯",
              "effect": {"idx": 1}
            }
          ]
        }
      ]
    },
    {
      "idx": 90,
      "name": "콘텐츠메인_CHECKIN",
      "sections": [
        {
          "idx": 91,
          "name": "메인셀렉션[1]",
          "sections": [
            {
              "idx": 92,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 93,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 94,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            }
          ]
        },
        {
          "idx": 95,
          "name": "콘텐츠섹션[1]",
          "sections": [
            {
              "idx": 96,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 97,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 98,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 99,
              "name": "4 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 100,
              "name": "5 슬롯",
              "effect": {"idx": 1}
            }
          ]
        }
      ]
    },
    {
      "idx": 101,
      "name": "콘텐츠메인_PICK",
      "sections": [
        {
          "idx": 102,
          "name": "메인셀렉션[1]",
          "sections": [
            {
              "idx": 103,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 104,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 105,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            }
          ]
        },
        {
          "idx": 106,
          "name": "콘텐츠섹션[1]",
          "sections": [
            {
              "idx": 107,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 108,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 109,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 110,
              "name": "4 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 111,
              "name": "5 슬롯",
              "effect": {"idx": 1}
            }
          ]
        }
      ]
    },
    {
      "idx": 112,
      "name": "콘텐츠메인_STORY",
      "sections": [
        {
          "idx": 113,
          "name": "메인셀렉션[1]",
          "sections": [
            {
              "idx": 114,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 115,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 116,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            }
          ]
        },
        {
          "idx": 117,
          "name": "콘텐츠섹션[1]",
          "sections": [
            {
              "idx": 118,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 119,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 120,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 121,
              "name": "4 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 122,
              "name": "5 슬롯",
              "effect": {"idx": 1}
            }
          ]
        }
      ]
    },
    {
      "idx": 123,
      "name": "콘텐츠메인_NEWS",
      "sections": [
        {
          "idx": 124,
          "name": "메인셀렉션[1]",
          "sections": [
            {
              "idx": 125,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 126,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 127,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            }
          ]
        },
        {
          "idx": 128,
          "name": "콘텐츠섹션[1]",
          "sections": [
            {
              "idx": 129,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 130,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 131,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 132,
              "name": "4 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 133,
              "name": "5 슬롯",
              "effect": {"idx": 1}
            }
          ]
        }
      ]
    },
    {
      "idx": 134,
      "name": "콘텐츠상세페이지",
      "sections": [
        {
          "idx": 135,
          "name": "콘텐츠섹션[1]",
          "sections": [
            {
              "idx": 136,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 137,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 138,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            }
          ]
        }
      ]
    },
    {
      "idx": 139,
      "name": "항공사메인",
      "sections": [
        {
          "idx": 140,
          "name": "메인셀렉션[1]",
          "effect": {"idx": 1}
        },
        {
          "idx": 141,
          "name": "콘텐츠섹션[1]",
          "sections": [
            {
              "idx": 142,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 143,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 144,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 145,
              "name": "4 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 146,
              "name": "5 슬롯",
              "effect": {"idx": 1}
            }
          ]
        },
        {
          "idx": 147,
          "name": "콘텐츠섹션[2]",
          "sections": [
            {
              "idx": 148,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 149,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 150,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 151,
              "name": "4 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 152,
              "name": "5 슬롯",
              "effect": {"idx": 1}
            }
          ]
        }
      ]
    },
    {
      "idx": 153,
      "name": "비디오",
      "sections": [
        {
          "idx": 154,
          "name": "추천섹션[1]",
          "sections": [
            {
              "idx": 155,
              "name": "1 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 156,
              "name": "2 슬롯",
              "effect": {"idx": 1}
            },
            {
              "idx": 157,
              "name": "3 슬롯",
              "effect": {"idx": 1}
            }
          ]
        }
      ]
    }
  ]
}
