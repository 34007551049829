import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

const loc = JSON.parse(JSON.stringify(window.location))
const firstname = (loc.hostname).substr(0,7) === '192.168' ? loc.hostname
  : loc.hostname.indexOf('test.') > -1 ? (loc.hostname.split('.').slice(-3)).join('.').replace('http://','').replace('https://','')
  : loc.hostname.split('.').length > 1 ? (loc.hostname.split('.').slice(-2)).join('.').replace('http://','').replace('https://','')
  : loc.hostname
Vue.prototype.$hostURL = loc.hostname
Vue.prototype.$appURL = loc.protocol + '//' + firstname
Vue.prototype.$svrURL = firstname === 'localhost' ? loc.protocol + '//' + firstname + ':8080' : loc.protocol + '//api.' + firstname + ':8080'
Vue.prototype.$apiURL = Vue.prototype.$svrURL + '/v1'

// Vue.prototype.$apiURL = 'http://api.test.prestigegorilla.net:8080/v1'
// Vue.prototype.$apiURL = 'https://api.prestigegorilla.net:8080/v1'
Vue.axios.defaults.baseURL = Vue.prototype.$apiURL
Vue.axios.defaults.withCredentials = false

const otaAxios = axios.create({baseURL: ''})
Vue.prototype.$otaAxios = otaAxios

export default async ({ Vue }) => {
  Vue.prototype.$axios = axios
}
