import Vue from 'vue'
import vuex from '../vuex'
import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// Dashboard pages
const Overview = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Overview.vue')
const Widgets = () => import(/* webpackChunkName: "widgets" */ 'src/components/Dashboard/Views/Dashboard/Widgets.vue')

// Display
import DisplayCuration from 'src/components/Dashboard/Views/Display/DisplayCuration.vue'
import DisplayAds from 'src/components/Dashboard/Views/Display/DisplayAds.vue'
import DisplayCate from 'src/components/Dashboard/Views/Display/DisplayCate.vue'

// Stat
import StatChart from 'src/components/Dashboard/Views/Stat/StatChart.vue'
import StatChartData from 'src/components/Dashboard/Views/Stat/StatChartData.vue'
import StatSearch from 'src/components/Dashboard/Views/Stat/StatSearch.vue'

// Hotel
import HotelManager from 'src/components/Dashboard/Views/Hotel/HotelManager.vue'
import HashTag from 'src/components/Dashboard/Views/Hotel/HashTag.vue'
import HotelRegion from 'src/components/Dashboard/Views/Hotel/HotelRegion.vue'
import HotelBrand from 'src/components/Dashboard/Views/Hotel/HotelBrand.vue'

// Post
import PostManager from 'src/components/Dashboard/Views/Post/PostManager.vue'
import VideoManager from 'src/components/Dashboard/Views/Post/VideoManager.vue'
import PostCate from 'src/components/Dashboard/Views/Post/PostCate.vue'

// Flight
import AirlineCorp from 'src/components/Dashboard/Views/Flight/AirlineCorp.vue'

// User
import UserManager from 'src/components/Dashboard/Views/User/UserManager.vue'

// Member
import MemberManager from 'src/components/Dashboard/Views/Member/MemberManager.vue'
import MemberAuth from 'src/components/Dashboard/Views/Member/MemberAuth.vue'
import MemberClass from 'src/components/Dashboard/Views/Member/MemberClass.vue'

// Pages
import User from 'src/components/Dashboard/Views/Pages/UserProfile.vue'
import Login from 'src/components/Dashboard/Views/Pages/Login.vue'
import Register from 'src/components/Dashboard/Views/Pages/Register.vue'
import Lock from 'src/components/Dashboard/Views/Pages/Lock.vue'


// TableList pages
const RegularTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/RegularTables.vue');
const ExtendedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/ExtendedTables.vue');
const PaginatedTables = () => import(/* webpackChunkName: "tables" */ 'src/components/Dashboard/Views/Tables/PaginatedTables.vue');


const requireAuth = (from, to, next) => {

  let {accessToken, accessMember} = localStorage
  accessMember = accessMember ? JSON.parse(accessMember) : null
  if (!accessToken) {
    return next('login')
  } else if (accessMember && accessMember.class) {
    // console.log(2,`classIdx: ${accessMember.class.idx}`)
    // console.log(vuex.getters.getMyMemberAuthData)
    vuex.dispatch('callMyMemberAuthData', accessMember.class.idx).then(() => {
      const fromData = vuex.getters.getThisPageAuthData(from.path)
      const toData = vuex.getters.getThisPageAuthData(to.path)
      // console.log(vuex.getters.getMyMemberAuthData)
      // console.log(fromData, toData, from.path, to.path)
      if (fromData && fromData.includes('R')) next()
      else if (vuex.getters.getFirstMyPageAuthData) vm.$router.push(vuex.getters.getFirstMyPageAuthData.path)
      else next()
      // else vm.$router.push('/')
    })
  } else {
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
    next()
  }
}


let displayMenu = {
  path: '/display',
  component: DashboardLayout,
  redirect: '/display/curation',
  children: [
    {
      path: 'curation',
      name: 'curation',
      component: DisplayCuration,
      beforeEnter: requireAuth
    },
    {
      path: 'ads',
      name: 'ads',
      component: DisplayAds,
      beforeEnter: requireAuth
    },
    {
      path: 'cate',
      name: 'cate',
      component: DisplayCate,
      beforeEnter: requireAuth
    }
  ]
}

let statMenu = {
  path: '/stat',
  component: DashboardLayout,
  redirect: '/stat/chart',
  children: [
    {
      path: 'chart',
      name: 'chart',
      component: StatChart,
      beforeEnter: requireAuth
    },
    {
      path: 'chartdata',
      name: 'chartdata',
      component: StatChartData,
      beforeEnter: requireAuth
    },
    {
      path: 'search',
      name: 'search',
      component: StatSearch,
      beforeEnter: requireAuth
    },
  ]
}

let hotelMenu = {
  path: '/hotel',
  component: DashboardLayout,
  redirect: '/hotel/manager',
  children: [
    {
      path: 'manager',
      name: 'hotelManager',
      component: HotelManager,
      beforeEnter: requireAuth
    },
    {
      path: 'hash_tag',
      name: 'HashTag',
      component: HashTag,
      beforeEnter: requireAuth
    },
    {
      path: 'region',
      name: 'region',
      component: HotelRegion,
      beforeEnter: requireAuth
    },
    {
      path: 'brand',
      name: 'brand',
      component: HotelBrand,
      beforeEnter: requireAuth
    },
  ]
}

let postMenu = {
  path: '/post',
  component: DashboardLayout,
  redirect: '/post/manager',
  children: [
    {
      path: 'manager',
      name: 'postManager',
      component: PostManager,
      beforeEnter: requireAuth
    },
    {
      path: 'video',
      name: 'videoManager',
      component: VideoManager,
      beforeEnter: requireAuth
    },
    {
      path: 'cate',
      name: 'memberCate',
      component: PostCate,
      beforeEnter: requireAuth
    },
  ]
}

let flightMenu = {
  path: '/flight',
  component: DashboardLayout,
  redirect: '/flight/airline',
  children: [
    {
      path: 'airline',
      name: 'airline',
      component: AirlineCorp,
      beforeEnter: requireAuth
    }
  ]
}

let userMenu = {
  path: '/user',
  component: DashboardLayout,
  redirect: '/user/manager',
  children: [
    {
      path: 'manager',
      name: 'userManager',
      component: UserManager,
      beforeEnter: requireAuth
    }
  ]
}

let memberMenu = {
  path: '/member',
  component: DashboardLayout,
  redirect: '/member/manager',
  children: [
    {
      path: 'manager',
      name: 'memberManager',
      component: MemberManager,
      beforeEnter: requireAuth
    },
    {
      path: 'auth',
      name: 'auth',
      component: MemberAuth,
      beforeEnter: requireAuth
    },
    {
      path: 'class',
      name: 'class',
      component: MemberClass,
      beforeEnter: requireAuth
    },
  ]
}

let tablesMenu = {
  path: '/table-list',
  component: DashboardLayout,
  redirect: '/table-list/regular',
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      component: RegularTables
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      component: ExtendedTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }
  ]
}

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Register
}

let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock
}

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/hotel'
  },
  displayMenu,
  statMenu,
  hotelMenu,
  postMenu,
  flightMenu,
  userMenu,
  memberMenu,
  tablesMenu,
  pagesMenu,
  loginPage,
  registerPage,
  lockPage,
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview,
        beforeEnter: requireAuth
      },
      {
        path: 'widgets',
        name: 'Widgets',
        component: Widgets,
        beforeEnter: requireAuth
      }
    ]
  },
  {path: '*', component: NotFound}
];

export default routes
