<template>
  <div class="row DISPLAY_CATE">

    <!-- 헤더 및 컨트롤 -->
    <div class="col-md-12">
      <h5 class="title">DP 카테고리 관리</h5>
      <p class="category">
        <!--We combine <a href="http://element.eleme.io/#/en-US/component/quickstart" target="_blank" rel="noopener">Element-UI</a>-->
        <!--table functionalities together with a custom pagination component-->
        <!--which should provide a very good starting point to integrate tables in your application.-->
        <!--Check out more functionalities at <a href="http://element.eleme.io/#/en-US/component/table" target="_blank" rel="noopener">Element-UI table documentation</a>.-->
        디스플레이 카테고리 관리입니다.
      </p>
    </div>

    <!-- 카테고리 -->
    <div class="col-md-5">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">카테고리</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12">

              <el-tree
                v-loading="loading"
                :props="props"
                :data="displayCate.value"
                node-key="id"
                default-expand-all
                highlight-current
                @node-click="handleNodeClick"
                :expand-on-click-node="false">

                <!--<span class="custom-tree-node" slot-scope="{ node, data }">-->
                <!--<span>{{ node.label }}</span>-->
                <!--<span>-->
                <!--<p-button-->
                <!--type="success" size="sm" icon-->
                <!--@click="() => append(data)">-->
                <!--<i class="fa fa-plus"></i>-->
                <!--</p-button>-->
                <!--<p-button-->
                <!--type="danger" size="sm" icon-->
                <!--@click="() => remove(node, data)">-->
                <!--<i class="fa fa-close"></i>-->
                <!--</p-button>-->
                <!--</span>-->
                <!--</span>-->

              </el-tree>

            </div>
          </div>
        </div>
        <div>
          <p class="pull-right mr-3">

          </p>
        </div>
      </div>
    </div>

    <!-- 상세설정 -->
    <div class="col-md-7 px-md-0">
      <div class="card" :class="{fixed: isSubFixed}">
        <div class="card-header">
          <h5 class="card-title">상세 설정 </h5>
        </div>
        <div class="card-body">

          <div v-if="selectedNode.idx">

            <div class="row">
              <div class="col-md-6 pr-md-1">
                <fg-input type="text"
                          label="카테고리 이름"
                          placeholder="카테고리 이름 입력"
                          class="required"
                          readonly
                          v-model="selectedNode.name">
                </fg-input>
              </div>
              <div class="col-md-6 pl-md-1">
                <fg-input type="text"
                          label="섹션 IDX"
                          placeholder=""
                          class="required"
                          :readonly="selectedNode.idx"
                          v-model="selectedNode.idx">
                </fg-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-2 pr-md-1">
                <fg-input type="text"
                          label="이니셜"
                          placeholder="이니셜"
                          v-model="selectedNode.initial">
                </fg-input>
              </div>
              <div class="col-md-10 pl-md-1">
                <fg-input type="text"
                          label="카테고리 타이틀 "
                          placeholder="타이틀"
                          v-model="selectedNode.title">
                </fg-input>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <el-input type="textarea"
                          class="mb-1"
                          @input="limitInputLine($event, selectedNode, 'subtitle', 2)"
                          :autosize="{ minRows: 1, maxRows:2 }"
                          placeholder="서브 타이틀"
                          v-model="selectedNode.subtitle">
                </el-input>
              </div>
            </div>

            <el-divider content-position="left">영역 사이즈 및 효과</el-divider>

            <div class="row pb-2">
              <div class="col-4 col-md-3 pr-md-0 text-center">
                <div>
                  <label class="pr-2">좌우전환버튼</label>
                  <div>
                    <p-switch v-model="selectedNode.arrows" type="warning" on-text="ON" off-text="OFF"></p-switch>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-3 px-md-0 text-center">
                <div>
                  <label class="pr-2">네비게이션</label>
                  <div>
                    <p-switch v-model="selectedNode.navigation" type="warning" on-text="ON" off-text="OFF"></p-switch>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-3 px-md-0 text-center">
                <div>
                  <label class="pr-2">스와이핑</label>
                  <div>
                    <p-switch v-model="selectedNode.swipeable" type="warning" on-text="ON" off-text="OFF"></p-switch>
                  </div>
                </div>
              </div>
              <div class="col-md-3 pl-md-2 pt-md-1 text-center">
                <div class="form-group">
                  <el-select
                    class="select"
                    @change="changeEffect"
                    v-model="selectedNode.control_color"
                    placeholder="컨트롤러 색상">
                    <el-option v-for="color in controlColors" :key="color"
                      :class="color"
                      :label="color"
                      :value="color">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <fg-input type="number"
                          label="등장 시간 (밀리초 단위: 1초 = 1000)"
                          placeholder="입력하지 않으면 바로 노출"
                          class=""
                          v-model.number="selectedNode.duration">
                </fg-input>
              </div>
              <div class="col-md-6">
                <fg-input type="number"
                          label="전환 시간 (밀리초 단위: 1초 = 1000)"
                          placeholder="입력하지 않으면 autoplay 비활성"
                          class=""
                          v-model.number="selectedNode.interval">
                </fg-input>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label>전환효과</label>
                <div class="form-group">
                  <el-select
                    class="select"
                    @change="changeEffect"
                    v-model="selectedNode.effect.idx"
                    placeholder="전환 효과 선택">
                    <el-option
                      class="select-default"
                      v-for="(effect, index) in effects"
                      :key="index"
                      :label="effect.name"
                      :value="effect.idx">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <!--<div class="col" v-if="selectedNode.idx === 8">-->
                <!--<label>배너 노출방향 선택</label>-->
                <!--<div class="form-group">-->
                  <!--<el-select-->
                    <!--class="select"-->
                    <!--@change="changeEffect"-->
                    <!--v-model="selectedNode.direction"-->
                    <!--placeholder="배너 노출방향 선택">-->
                    <!--<el-option-->
                      <!--class="select-default"-->
                      <!--v-for="direction in directions" :key="direction"-->
                      <!--:label="direction.label"-->
                      <!--:value="direction.value">-->
                    <!--</el-option>-->
                  <!--</el-select>-->
                <!--</div>-->
              <!--</div>-->
            </div>

            <div class="row">

              <div class="col-6 pr-md-1">
                <fg-input class="pr-0 m-0" label="가로 사이즈 (단위 포함)" input-classes="text-center px-0" type="text" placeholder="너비" maxlength="6" v-model="selectedNode.width" />
              </div>

              <div class="col-6 pl-md-1">
                <fg-input class="pl-0 m-0" label="세로 사이즈 (단위 포함)" input-classes="text-center px-0" type="text" placeholder="높이" maxlength="6" v-model="selectedNode.height" />
              </div>
            </div>

          </div>
          <div v-else>
            설정할 카테고리 노드를 선택하세요.<br/>
            마지막 노드만 설정을 할 수 있습니다.

            <br><span class="text-danger">다른 관리자와 동시에 작업하면 변경본이 유실될(이전으로 돌아갈) 가능성이 있습니다.<br>화면에 들어온지 오래되었다면 새로고침을 하고 진행하기를 권장합니다</span>
          </div>

        </div>
        <div>
          <p class="pull-right mr-3">
            <p-button type="info" round @click="saveDisplayCateData()" v-if="getCheckThisPageModify">설정 저장</p-button>
          </p>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import {Tree, Button, ButtonGroup, Select, Option, Loading, Dialog, Switch, Divider, Input} from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch'
  import swal from 'sweetalert2'
  import commonMethods from 'src/mixins/common_methods'
  const baseAction = '/display_cates/'
  let id = 174
  export default {
    name: 'DisplayCate',
    components: {
      PSwitch,
      [Tree.name]: Tree,
      [Button.name]: Button,
      [ButtonGroup.name]: ButtonGroup,
      [Select.name]: Select,
      [Option.name]: Option,
      [Dialog.name]: Dialog,
      [Switch.name]: Switch,
      [Divider.name]: Divider,
      [Input.name]: Input,
    },
    beforeRouteLeave (to, from, next) {
      // if (this.tab) {
      //   next(false)
      // } else {
        next()
      // }
    },
    data () {
      return {
        baseAction: baseAction,
        displayCate: {
          idx: '',
          column: '',
          value: []
        },
        displayCateRs: {},
        selectedNode: {},
        cateOpen: false,
        accordion: true,
        props: {
          id: 'idx',
          label: 'name',
          children: 'sections'
        },
        controlColors: [
          'primary',
          'secondary',
          'accent',
          'positive',
          'negative',
          'info',
          'warning',
          'white',
          'black'
        ],
        effects: [
          {idx: 1, name: '페이드', effect: {'prev': 'fade', 'next': 'fade'}},
          {idx: 2, name: '스케일', effect: {'prev': 'scale', 'next': 'scale'}},
          {idx: 3, name: '회전', effect: {'prev': 'rotate', 'next': 'rotate'}},
          {idx: 4, name: '슬라이드 가로', effect: {'prev': 'slide-right', 'next': 'slide-left'}},
          {idx: 5, name: '슬라이드 세로', effect: {'prev': 'slide-up', 'next': 'slide-down'}},
          {idx: 6, name: '슬라이드 LEFT', effect: {'prev': 'slide-up', 'next': 'slide-down'}},
          {idx: 7, name: '슬라이드 LEFT', effect: {'prev': 'slide-up', 'next': 'slide-down'}},
          {idx: 8, name: '슬라이드 UP', effect: {'prev': 'slide-up', 'next': 'slide-up'}},
          {idx: 9, name: '슬라이드 DOWN', effect: {'prev': 'slide-down', 'next': 'slide-down'}},
          {idx: 10, name: '점프 가로', effect: {'prev': 'jump-right', 'next': 'jump-left'}},
          {idx: 11, name: '점프 세로', effect: {'prev': 'jump-up', 'next': 'jump-down'}},
          {idx: 12, name: '점프 LEFT', effect: {'prev': 'jump-up', 'next': 'jump-down'}},
          {idx: 13, name: '점프 LEFT', effect: {'prev': 'jump-up', 'next': 'jump-down'}},
          {idx: 14, name: '점프 UP', effect: {'prev': 'jump-up', 'next': 'jump-up'}},
          {idx: 15, name: '점프 DOWN', effect: {'prev': 'jump-down', 'next': 'jump-down'}},
          {idx: 16, name: '플립 가로', effect: {'prev': 'filp-right', 'next': 'filp-left'}},
          {idx: 17, name: '플립 세로', effect: {'prev': 'filp-up', 'next': 'filp-down'}},
          {idx: 18, name: '플립 LEFT', effect: {'prev': 'filp-up', 'next': 'filp-down'}},
          {idx: 19, name: '플립 LEFT', effect: {'prev': 'filp-up', 'next': 'filp-down'}},
          {idx: 20, name: '플립 UP', effect: {'prev': 'filp-up', 'next': 'filp-up'}},
          {idx: 21, name: '플립 DOWN', effect: {'prev': 'filp-down', 'next': 'filp-down'}}
        ],
        directions: [
          {label:'오른쪽', value:'right'},
          {label:'왼쪽', value:'left'},
        ],
        loading: true,
        isSubFixed: false,
        btnText: '저장',
      }
    },
    created () {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    mounted () {
      this.$store.dispatch('callAllDisplayCateData').then(() => {
        this.getDisplayCateList()
      })
    },
    computed: {
      ...mapGetters([
        'getMapDisplayCateData',
        'getAllDisplayCateData',
        'getCheckThisPageCreate',
        'getCheckThisPageRead',
        'getCheckThisPageModify',
        'getCheckThisPageDelete'
      ]),
    },
    methods: {
      ...commonMethods,
      handleScroll () {
        this.isSubFixed = window.pageYOffset > 150 ? true : false
      },
      getDisplayCateList () {
        this.loading = true
        if (this.getAllDisplayCateData.value && this.getAllDisplayCateData.value.length > 0) {
          this.displayCate = this.cloneData(this.getAllDisplayCateData)
          this.displayCateRs = this.cloneData(this.displayCate)
        } else {
          this.displayCate.value = this.cloneData(this.getMapDisplayCateData)
          this.displayCateRs.value = this.cloneData(this.displayCate.value)
        }
        this.loading = false
      },
      changeEffect (idx) {
        const from = this.cloneData(this.effects.find(opt => opt.idx === idx))
        if (from) {
          this.selectedNode.effect.name = this.cloneData(from.name)
          this.selectedNode.effect.json = this.cloneData(from.effect)
        }
      },
      handleNodeClick (data) {
        if (!data.sections) {
          this.selectedNode = data
          if (!this.selectedNode.effect || Object.keys(this.selectedNode.effect).length < 2) this.selectedNode.effect = {idx: 1}
        } else {
          this.selectedNode = {}
        }
      },
      append(data) {
        const newChild = { id: id++, label: 'added item', children: [] };
        if (!data.children) {
          this.$set(data, 'children', []);
        }
        data.children.push(newChild);
      },
      remove(node, data) {
        const parent = node.parent;
        const children = parent.data.children || parent.data;
        const index = children.findIndex(d => d.id === data.id);
        children.splice(index, 1);
      },
      saveDisplayCateData () {
        if (!confirm('갱신없이 오랫동안 편집했다면 다른작업자의 설정을 덮어 쓸 가능성이 있습니다.\n새로고침을 하시고 진행하는 것을 권장합니다.\n바로 저장 하시겠습니까?')) return
        const loading = Loading.service()
        let params = this.cloneData(this.displayCate)
        let method = 'post'
        let action = baseAction

        if (params.idx) {
          action += params.idx
          method = 'put'
        }

        let formData = new FormData()
        for (let key in this.uploadData) {
          delete params[key]
          if (Object.keys(this.uploadData[key]).length > 0) {
            formData.append(key, this.uploadData[key].raw)
          } else {
            formData.append(key, null)
          }
        }
        for (let key in params) {
          if (typeof params[key] === 'object') {
            formData.append(key, JSON.stringify(params[key]))
          } else {
            formData.append(key, params[key])
          }
        }

        // for (var key of formData.entries()) {
        //   console.log(key[0] + ', ' + key[1]);
        // }

        this.axios.post(action, formData).then((rs) => {
          if (rs.data.status === 'success') {
            this.$store.dispatch('loadAllDisplayCateData')
          } else {
            swal({
              title: 'ERROR',
              text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
              type: 'error',
              confirmButtonClass: 'btn btn-info btn-fill',
              buttonsStyling: false
            })
          }
          loading.close()

        }).catch( error => {
          loading.close()
          console.log('failed', error)
        })
      },
    }
  }
</script>
<style scoped>
  label {
    font-weight: bold !important;
  }

  .fixed {
    position: fixed;
    top: 10px;
    width: 46vmax;
  }

  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }

  /deep/ .btn.btn-icon.btn-sm, .base-btn.btn-icon.btn-sm, .navbar .navbar-nav > a.btn.btn-icon.btn-sm {
    height: 1.2rem;
    min-width: 1.2rem;
    width: 1.2rem;
  }

  .primary {background-color: #1b2838!important;}
  .secondary {background-color: #ff6e61!important;}
  .accent {background-color: #9c27b0!important;}
  .positive {background-color: #21ba45!important;}
  .negative {background-color: #c10015!important;}
  .info {background-color: #31ccec!important;}
  .warning {background-color: #f2c037!important;}
  .white {background-color: #fff!important;}
  .black {background-color: #000!important;}
</style>

