<template>
  <div class="POST_MANAGER">
    <div class="col-md-12 row pr-0">
      <div class="col-md-2">
        <h5 class="title">{{typeLabel[searchDB.type]}} {{pageName}}</h5>
        <p class="category">{{pageName}} 입니다.</p>
      </div>
      <div class="col-md-10 pr-0">
        <div class="form-group">
          <p-radio class="float-left mr-3" label="" v-model="searchDB.type" @input="resetCurrentPage">전체</p-radio>
          <p-radio class="float-left mr-3" label="HOTEL" v-model="searchDB.type" @input="resetCurrentPage">{{typeLabel['HOTEL']}}</p-radio>
          <p-radio class="float-left" label="FLIGHT" v-model="searchDB.type" @input="resetCurrentPage">{{typeLabel['FLIGHT']}}</p-radio><br>
          <div class="pull-right mt-2">
            <p class="category">검색된 <b>{{typeLabel[searchDB.cate]}} 포스트</b>는 총 <b class="text-info">{{pagination.total}}</b>개 입니다. </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 card LIST">
      <div class="card-body row table-full-width">
        <div class="col-md-5">
          <fieldset>
            <div class="form-group">
              <el-select
                class="select-info"
                v-model="searchDB.post_cate"
                @change="getPostList"
                placeholder="카테고리">
                <el-option value=""></el-option>
                <el-option
                  class="select-info"
                  v-for="item in getPostCateOptions"
                  :key="item.idx"
                  :label="item.name"
                  :value="item.idx">
                </el-option>
              </el-select>

              <el-select
                multiple
                filterable
                collapse-tags
                class="select-info"
                v-model="searchDB.hash_tags"
                @change="getPostList"
                placeholder="해시태그">
                <el-option v-for="tag in getAllHashTagOptions" :key="tag.idx"
                           :label="`#${tag.name}`"
                           :value="tag.idx" />
              </el-select>

              <el-select
                class="select-info small"
                v-model="searchDB.writer"
                @change="getPostList"
                placeholder="작성자">
                <el-option value=""></el-option>
                <el-option
                  class="select-info"
                  v-for="item in getMemberOptions"
                  :key="item.idx"
                  :label="item.name"
                  :value="item.idx">
                </el-option>
              </el-select>

            </div>
          </fieldset>
        </div>
        <div class="col-md-4">
          <div class="text-center">
            <el-date-picker
              v-model="searchDB.date"
              @change="getPostList"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions2">
            </el-date-picker>
          </div>
        </div>
        <div class="col-sm-3 pr-md-0">
          <div class="col-sm-12">
            <fg-input class="input-sm"
                      placeholder="타이틀 검색"
                      v-model="searchDB.title"
                      @keyup.enter="getPostList"
                      addon-left-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-md-1">
          <el-table class="table-striped"
                    header-row-class-name="text-default"
                    :data="tableData"
                    v-loading="loading"
                    border
                    style="width: 100%">
            <el-table-column v-for="column in tableColumns"
                             class=""
                             :key="column.label"
                             :min-width="column.minWidth"
                             :prop="column.prop"
                             :label="column.label">
              <template slot-scope="props" class="info-text">
                <span v-if="column.prop === 'no'">{{getNo(props.row.key)}}</span>
                <a v-else-if="column.prop === 'title'" :href="`${appUrl}/contents/detail/${props.row.idx}`" target="_blank" class="text-info-darken" >{{props.row.title}}</a>
                <div v-else-if="column.prop === 'post_img'"><img v-if="isFileExist(props.row.post_img)" :src="props.row.post_img.url" width="auto" height="auto" style="max-width:60px; max-height:60px;" /></div>
                <div v-else-if="column.prop === 'hash_tags'">
                  <el-tag
                    size="mini"
                    class="mt-0 mr-1 ml-0 bg-white text-muted"
                    v-for="tag in props.row.hash_tags" :key="tag.idx"
                    :closable="false"
                  >
                    #{{tag.name}}
                  </el-tag>
                </div>
                <span v-else>{{props.row[column.prop]}}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="getCheckThisPageModify"
              :min-width="100"
              fixed="right"
              class-name="td-actions text-center"
              label="공개설정">
              <template slot-scope="props">
                <el-switch v-model="props.row.is_visible" @change="changeIsVisibled(props.row)" active-color="#EB5E28" :disabled="!getCheckThisPageModify"></el-switch>
              </template>
            </el-table-column>
            <el-table-column
              v-if="getCheckThisPageModify || getCheckThisPageDelete"
              :min-width="100"
              fixed="right"
              class-name="td-actions text-center primary"
              label="수정/삭제">
              <template slot-scope="props">
                <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)" :disabled="!getCheckThisPageModify">
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button type="danger" size="sm" icon @click="handleDelete(props.$index, props.row)" :disabled="!getCheckThisPageDelete">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="card-body row table-full-width">
        <div class="col-sm-3">
          <div class="">
            <el-select
              class="select"
              v-model="pagination.perPage"
              @change="getPostList"
              placeholder="Per page">
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="`${item} rows`"
                :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="pagination-info mt-1">
            <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
          </div>
        </div>
        <div class="col-sm-6 text-center">
          <!--<p-pagination v-model="pagination.currentPage"-->
                        <!--:per-page="pagination.perPage"-->
                        <!--:total="pagination.total">-->
          <!--</p-pagination>-->
          <el-pagination background
                         :value="pagination.currentPage"
                         :page-size="pagination.perPage"
                         :total="pagination.total"
                         layout="pager"
                         @current-change="setCurrentPage"
                         :disabled="loading">
          </el-pagination>
        </div>
        <div class="col-sm-3">
          <p class="pull-right">
            <p-button class="pull-right" type="info" round icon :disabled="total < 1" @click="downloadExcelFile(getFileName, getTableResult, getTableColumns, 'table_list')">
              <i class="fa fa-download"></i>
            </p-button>
          </p>
          <p class="pull-right mr-3">
            <p-button type="info" round @click.native="loading=false; $refs.modal.openModal()" ref="editor" v-if="getCheckThisPageCreate">포스트 등록</p-button>
          </p>
        </div>
      </div>
    </div>
    <post-detail ref="modal" @change-table-data="setPostList"></post-detail>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option, DatePicker, Switch, Loading, Pagination, Tag} from 'element-ui'
  import swal from 'sweetalert2'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import { mapGetters } from 'vuex'
  import commonMethods from 'src/mixins/common_methods'
  import PostDetail from './PostDetail'
  import posts from './posts'
  const baseAction = '/posts/'
  export default{
    name: "PostManager",
    components: {
      PSwitch,
      PostDetail,
      PPagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      [Switch.name]: Switch,
      [Pagination.name]: Pagination,
      [Tag.name]: Tag,
      Loading,
    },
    data () {
      return {
        baseAction: baseAction,
        tableData: posts,
        pageName: '포스트 관리',
        loading: true,
        pagination: {
          perPage: 30,
          currentPage: 1,
          perPageOptions: [10, 30, 50, 100, 400, 1000],
          total: 0
        },
        typeLabel: {
          'HOTEL': '호텔',
          'FLIGHT': '항공'
        },
        searchDB: {
          'type': '',
          'post_cate': '',
          'hash_tags': '',
          'writer': '',
          'date': '',

          'title': '',
        },
        tableColumns: [
          {
            prop: 'no',
            label: '#',
            minWidth: 70
          },
          {
            prop: 'pid',
            label: 'PID',
            minWidth: 80
          },
          {
            prop: 'post_img',
            label: '썸네일',
            minWidth: 90,
          },
          {
            prop: 'post_cate_txt',
            label: '카테고리',
            minWidth: 120
          },
          {
            prop: 'title',
            label: '타이틀',
            minWidth: 450
          },
          {
            prop: 'hash_tags',
            label: '해시태그',
            minWidth: 190
          },
          {
            prop: 'writer_name',
            label: '에디터',
            minWidth: 90
          },
          {
            prop: 'view_cnt',
            label: '조회수',
            minWidth: 90
          },
          {
            prop: 'reg_dt',
            label: '작성일',
            minWidth: 110
          },
        ],
        pickerOptions: {
          shortcuts: [{
            text: 'Today',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
            {
              text: 'Yesterday',
              onClick (picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
              }
            },
            {
              text: 'A week ago',
              onClick (picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', date)
              }
            }]
        },
        keyword: '',
        datePicker: '',
        pickerOptions2: {
          shortcuts: [{
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      }
    },
    beforeRouteLeave (to, from, next) {
      if (this.$refs.modal.isModalOpen) {
        next(false)
      } else {
        next()
      }
    },
    mounted () {
      this.getPostList()
      this.$store.dispatch('callAllHotelDataFast')
      this.$store.dispatch('callAllPostCateData')
      this.$store.dispatch('callAllHashTagData')
      this.$store.dispatch('callAllPostDataFast')
      this.$store.dispatch('callAllMemberData')
      this.$store.dispatch('callAllAirlineCorpData')
      this.$store.dispatch('callSeoMetaDataDefault')
    },
    computed: {
      ...mapGetters([
        'getPostCateOptions',
        'getAllMemberData',
        'getMemberOptions',
        'getAllHashTagOptions',
        'getCheckThisPageCreate',
        'getCheckThisPageRead',
        'getCheckThisPageModify',
        'getCheckThisPageDelete'
      ]),
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      to () {
        let highBound = this.from + this.pagination.perPage
        // if (this.total < highBound) {
        //   highBound = this.total
        // }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        // this.pagination.total = this.tableData.length
        // return this.tableData.length
        return this.pagination.total
      },
      appUrl () {
        return Vue.prototype.$appURL
      },
      getFileName () {
        const cate = this.searchDB.post_cate ? `${this.getPostCateOptions.find(cate => cate.idx === this.searchDB.post_cate).name} ` : ''
        const keyword = this.searchDB.title ? `_(${this.searchDB.title})` : ''
        const date = this.searchDB.date ? `_(${this.searchDB.date.join('_')})` : ''
        return `${cate}${this.pageName.replace(/\ /g, '')}${keyword}${date}`
      },
      getTableResult () {
        const tableResult = this.tableData.reduce((acc, cur) => {
          const row = {}
          this.getTableColumns.map(col => {
            if (col.prop !== 'post_img' && cur[col.prop] !== undefined ) {
              if (col.prop === 'no') {
                row[col.prop] = this.getNo(cur.key)
              } else if (col.prop === 'is_visible') {
                row[col.prop] = cur[col.prop] ? 'on' : 'off'
              } else {
                row[col.prop] = cur[col.prop]
              }
            }
          })
          acc.push(row)
          return acc
        }, [])
        return tableResult
      },
      getTableColumns () {
        const rtn = this.tableColumns
        rtn.push({
          prop: 'is_visible',
          label: '검색노출',
        })
        return rtn
      }
    },
    methods: {
      ...commonMethods,
      getPostList () {
        this.loading = true
        let params = {params: {}}
        params.params.p = this.pagination.currentPage
        params.params.perPage = this.pagination.perPage
        if (this.searchDB.type) params.params.type = this.searchDB.type
        if (this.searchDB.post_cate) params.params.post_cate = this.searchDB.post_cate
        if (this.searchDB.hash_tags) params.params.hash_tags = this.searchDB.hash_tags
        if (this.searchDB.writer) params.params.writer = this.searchDB.writer
        if (this.searchDB.date) params.params.date = this.searchDB.date.join(',')
        if (this.searchDB.title) params.params.title = this.searchDB.title
        this.axios.get('/posts/search/all', params).then((rs) => {
          this.tableData = rs.data.data
          if (this.pagination.total !== rs.data.total) this.pagination.total = rs.data.total
          this.loading = false
        })
      },
      resetCurrentPage () {
        this.setCurrentPage(1)
      },
      changeIsVisibled (row) {
        let params = {idx: row.idx, is_visible: row.is_visible};
        let action = '/posts/visible/' + params.idx
        this.axios.put(action, params).then((rs) => {
          if (rs.data.status === 'success') {
            this.setPostList('put', rs.data.data[0])
          }
        }).catch( error => {
          console.log('failed', error)
        })
      },
      setPostList (method, row) {
        if(method === 'put') {
          for (let key in this.tableData) {
            if (this.tableData[key].idx === row.idx) {
              row.no = this.tableData.length
              this.tableData.splice(key, 1, row)
              break
            }
          }
        } else {
          row.no = this.tableData.length + 1
          this.tableData.unshift(row)
        }
      },
      handleEdit(index, row) {
        this.$refs.modal.openModal(row)
      },
      handleDelete(index, row) {
        let $this = this
        swal({
          title: '삭제하시겠습니까?',
          // text: '삭제하면 관련',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          buttonsStyling: false
        }).then(function () {
          $this.loading = true
          const action = '/posts/' + row.idx
          $this.axios.delete(action).then((rs) => {
            $this.loading = false
            if (rs.data.status === 'success') {
              const indexToDelete = $this.tableData.findIndex((tableRow) => tableRow.idx === row.idx)
              if (indexToDelete >= 0) {
                $this.tableData.splice(indexToDelete, 1)
              }
            } else {
              swal({
                title: 'ERROR',
                text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
                type: 'error',
                confirmButtonClass: 'btn btn-info btn-fill',
                buttonsStyling: false
              })
            }
          }).catch( error => {
            $this.loading = false
          })
        }, function (dismiss) {

        })
      },
      getNo (no) {
        const rtn = this.pagination.total - (no + (this.pagination.perPage * (this.pagination.currentPage-1)))
        return !isNaN(rtn) ? rtn : ''
      },
      setCurrentPage (p) {
        // if (p !== this.pagination.currentPage) {
          this.pagination.currentPage = p
          this.getPostList()
        // }
      }
    }
  }
</script>
<style scoped lang="scss">
  /deep/ .LIST .el-range-separator {
    width: 30px !important;
  }
  /deep/ .LIST .el-select {
    width: 36% !important;
    min-width: 110px;
    margin-right: 5px;
  }
  /deep/ .LIST .el-select.small {
    width: 25% !important;
    min-width: 80px;
  }
  /deep/ .LIST .el-select:last-child {
    margin-right:0;
  }
  /deep/ .modal-dialog {
    padding-top: 0 !important;
    width: 95% !important;
    max-width: 1919px !important;
    margin: 2.5rem auto !important;
  }
  /deep/ .modal-body {
    background-color: #f4f3ef;
    padding: 20px 30px !important;
  }
</style>
