<template>
  <modal :show.sync="isModalOpen"
         footer-classes="justify-content-center"
         @close="closeModal"
         >
    <h5 slot="header" class="modal-title text-left">호텔 {{btnText}}</h5>
    <template class="bg-light">
      <div class="row ">
        <div class="col-lg-8 col-md-7">
          <hotel-basicForm ref="basic" />
          <hotel-cont-form ref="cont" />
        </div>
        <div class="col-lg-4 col-md-5">
          <hotel-preview ref="preview" />
          <hash-tag-form ref="tag" />
        </div>
      </div>
    </template>
    <div slot="footer" class="justify-content-center">
      <p-button type="info" size="lg" wide round @click.native="saveHotelData">{{btnText}}</p-button>
      <!--<p-button type="info" size="lg" wide round @click.native="saveHotelData" >{{btnText}}</p-button>-->
    </div>
  </modal>
</template>
<script>
  import HotelBasicForm from './HotelDetail/HotelBasicForm'
  import HotelContForm from './HotelDetail/HotelContForm'
  import HotelPreview from './HotelDetail/HotelPreview.vue'
  import HashTagForm from "./HotelDetail/HashTagForm"
  import Modal from 'src/components/UIComponents/Modal.vue'
  import swal from 'sweetalert2'
  import { Loading } from 'element-ui'
  import { mapGetters } from 'vuex'
  import { cancelSource } from 'src/mixins/cancel_tocken'
  import commonMethods from 'src/mixins/common_methods'
  import forms from './forms'
  const baseAction = '/hotels/'
  export default {
    components: {
      HotelBasicForm,
      HotelContForm,
      HotelPreview,
      HashTagForm,
      Modal
    },
    computed: {
      ...mapGetters([
        'getAllHashTagOptions'
      ])
    },
    methods: {
      ...commonMethods,
      resetFormData () {
        for( let key in this.hotel) {
          if (typeof this.hotel[key] === 'string') this.hotel[key] = ''
          else if (typeof this.hotel[key] === 'number') this.hotel[key] = 0
          else if (typeof this.hotel[key] === 'boolean') this.hotel[key] = false
          else if (this.hotel[key] instanceof Array) this.hotel[key] = []
          else if (this.hotel[key] instanceof Object) this.hotel[key] = {}
        }
        this.hotel.cate = 'DOMESTIC'
        this.hotel.seo_meta = this.cloneData(this.$refs.basic.getSeoMetaDataDefault)
        this.hotel.related_review_videos = this.cloneData(this.relatedReviewVideosDefault)
        this.hotel.related_review_posts = this.cloneData(this.relatedReviewPostsDefault)
        this.hotel.related_hotels = this.cloneData(this.relatedHotelsDefault)
        this.hotel.related_contents = this.cloneData(this.relatedContentsDefault)
        this.hotel.theme_ranking_contents = this.cloneData(this.themeRankingContentsDefault)
        this.hotel.package_contents = this.cloneData(this.packageContentsDefault)
        this.hotel.related_review_videos_ori = this.cloneData(this.relatedReviewVideosDefault)
        this.hotel.related_review_posts_ori = this.cloneData(this.relatedReviewPostsDefault)
        this.hotel.related_hotels_ori = this.cloneData(this.relatedContentsDefault)
        this.hotel.related_contents_ori = this.cloneData(this.relatedContentsDefault)
        this.hotel.theme_ranking_contents_ori = this.cloneData(this.themeRankingContentsDefault)
        this.hotel.package_contents_ori = this.cloneData(this.packageContentsDefault)

        if (this.$refs.preview) this.$refs.preview.uploadData = []
      },
      openModal (row) {

        // 페이지 이동금지
        window.addEventListener('beforeunload', this.unload)

        setTimeout(() => this.$refs.cont.isOpen = true, 200)

        const loading = Loading.service()
        this.resetFormData()

        if(row) {
          this.btnText = '수정'
          this.axios.get('/hotels/'+row.idx).then((rs) => {
            for( var key in rs.data) {
              let val = rs.data[key]
              if (val===null) {
                // null 이면 반영 안함
              } else if (val.constructor === Array) {
                if (val.length > 0) {
                  this.hotel[key] = val
                }
              } else if (val.constructor === Object) {
                if (Object.keys(val).length > 0) {
                  this.hotel[key] = val
                }
              } else {
                this.hotel[key] = val
              }
            }

            if (this.hotel.related_review_videos.length > 0) {
              this.hotel.related_review_videos = this.cloneData(Object.assign([], this.relatedReviewVideosDefault, this.hotel.related_review_videos))
              this.hotel.related_review_videos_ori = this.cloneData(Object.assign([], this.relatedReviewVideosDefault, this.hotel.related_review_videos))
            }
            if (this.hotel.related_review_posts.length > 0) {
              this.hotel.related_review_posts = this.cloneData(Object.assign([], this.relatedReviewPostsDefault, this.hotel.related_review_posts))
              this.hotel.related_review_posts_ori = this.cloneData(Object.assign([], this.relatedReviewPostsDefault, this.hotel.related_review_posts))
            }
            if (this.hotel.related_hotels.length > 0) {
              this.hotel.related_hotels = this.cloneData(Object.assign([], this.relatedHotelsDefault, this.hotel.related_hotels))
              this.hotel.related_hotels_ori = this.cloneData(Object.assign([], this.relatedHotelsDefault, this.hotel.related_hotels))
            }
            if (this.hotel.related_contents.length > 0) {
              this.hotel.related_contents = this.cloneData(Object.assign([], this.relatedContentsDefault, this.hotel.related_contents))
              this.hotel.related_contents_ori = this.cloneData(Object.assign([], this.relatedContentsDefault, this.hotel.related_contents))
            }
            if (this.hotel.theme_ranking_contents.length > 0) {
              this.hotel.theme_ranking_contents = this.cloneData(Object.assign([], this.themeRankingContentsDefault, this.hotel.theme_ranking_contents))
              this.hotel.theme_ranking_contents_ori = this.cloneData(Object.assign([], this.themeRankingContentsDefault, this.hotel.theme_ranking_contents))
            }
            if (this.hotel.package_contents.length > 0) {
              this.hotel.package_contents = this.cloneData(Object.assign([], this.packageContentsDefault, this.hotel.package_contents))
              this.hotel.package_contents_ori = this.cloneData(Object.assign([], this.packageContentsDefault, this.hotel.package_contents))
            }

            // Object.assign(this.hotel, rs.data) // ie, android webview not support
            setTimeout(() => {
              this.hotelRs = this.cloneData(this.hotel)
            })

            loading.close()
          })
        } else {
          this.hotelRs = this.cloneData(this.hotel)
          this.btnText = '등록'
          loading.close()
        }
        this.isModalOpen = true
      },
      closeModal () {
        const hotel = this.cloneData(this.hotel)
        const hotelRs = this.cloneData(this.hotelRs)
        if (JSON.stringify(hotelRs) !== JSON.stringify(hotel)) {
          if (!confirm('변경된 내용이 있습니다. 무시하고 창을 닫을까요?')) {
            this.isModalOpen = true
            return false;
          }
        }

        setTimeout(() => this.$refs.cont.isOpen = false)
        this.isModalOpen = false

        // forms 초기화
        this.resetFormData()

        // 페이지 이동금지 취소
        window.removeEventListener('beforeunload', this.unload)

        // ota request 취소
        this.$store.dispatch("cancelRequestOtaSingleHotel")
      },
      saveHotelData () {
        const loading = Loading.service()
        let params = this.cloneData(this.hotel)
        let uploadDataPreview = this.$refs.preview.uploadData
        let uploadData = Object.assign({}, uploadDataPreview)
        let method = 'post'
        let action = baseAction

        // console.log(params)

        if (!params.name) {
          // console.log(params)
          setTimeout(() => {
            swal({
              title: 'VALIDATION',
              text: '필수항목을 모두 입력해 주세요',
              type: 'warning',
              confirmButtonClass: 'btn btn-info btn-fill',
              buttonsStyling: false
            })
          }, 500)
          loading.close()
          return false
        }
        if (params.longitude) {
          const isLongitude = num => isFinite(num) && Math.abs(num) <= 180
          if (!isLongitude(params.longitude)) {
            setTimeout(() => {
              swal({
                text: '경도를 정확한 범위(-180 ~ 180)의 값으로 입력해 주세요',
                type: 'warning',
                confirmButtonClass: 'btn btn-info btn-fill',
                buttonsStyling: false
              })
            }, 500)
            loading.close()
            return false
          }
        }
        if (params.latitude) {
          const isLatitude = num => isFinite(num) && Math.abs(num) <= 90
          if (!isLatitude(params.latitude)) {
            setTimeout(() => {
              swal({
                text: '위도를 정학한 범위(-90 ~ 90)의 값으로 입력해 주세요.',
                type: 'warning',
                confirmButtonClass: 'btn btn-info btn-fill',
                buttonsStyling: false
              })
            }, 500)
            loading.close()
            return false
          }
        }

        if (params.idx && params.idx > 0) {
          action += params.idx
          method = 'put'
        }

        let formData = new FormData()
        for (let key in uploadData) {
          if (uploadData[key].length > 0) { // multiple upload
            uploadData[key].map(file => {
              formData.append(`${key}[]`, file.raw)
            })
          } else {  // single upload
            delete params[key]
            // formData.append(key, null)
          }
          // console.log(key, params[key], uploadData[key])
        }
        for (let key in params) {
          if (typeof params[key] === 'object') {
            formData.append(key, JSON.stringify(params[key]).toString())
          } else {
            formData.append(key, params[key])
          }
        }

        // console.log('------------------------------') // 폼데이터는 직접 console.log를 찍으면 값이 전송되지 않는다
        // for (var key of formData.entries()) {
        //   console.log(key[0] + ', ' + key[1]);
        // }
        // return

        this.axios.post(action, formData).then((rs) => {
          if (rs.data.status === 'success') {
            this.hotelRs = this.cloneData(this.hotel)
            let data = rs.data.data[0]
            this.$emit('change-table-data', method, data)
            this.$store.dispatch('loadAllHotelDataFast')
            this.$refs.preview.uploadData = []
          } else {
            swal({
              title: 'ERROR',
              text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
              type: 'error',
              confirmButtonClass: 'btn btn-info btn-fill',
              buttonsStyling: false
            })
          }
          loading.close()
          this.closeModal()

        }).catch( error => {
          alert('등록에러!\n이미 등록된 호텔이거나 많은 이미지를 한번에 업로드하는 경우일 수 있습니다.\n프고이미지는 수정화면에서 더 쾌적하게 업로드할 수 있습니다.')
          loading.close()
          console.log('failed', error)
        })
      }
    },
    data () {
      return {
        baseAction: baseAction,
        hotel: forms,
        hotelRs: {},
        relatedReviewVideosDefault: [
          {idx: '', title: '', url: '', video_img: {}},
          {idx: '', title: '', url: '', video_img: {}},
          {idx: '', title: '', url: '', video_img: {}},
          {idx: '', title: '', url: '', video_img: {}},
          {idx: '', title: '', url: '', video_img: {}},
          {idx: '', title: '', url: '', video_img: {}},
        ],
        relatedReviewPostsDefault: [
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
        ],
        relatedHotelsDefault: [
          {idx: '', name: '', main_img: {url: ''}},
          {idx: '', name: '', main_img: {url: ''}},
          {idx: '', name: '', main_img: {url: ''}},
          {idx: '', name: '', main_img: {url: ''}},
          {idx: '', name: '', main_img: {url: ''}},
          {idx: '', name: '', main_img: {url: ''}},
        ],
        relatedContentsDefault: [
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
        ],
        themeRankingContentsDefault: [
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
        ],
        packageContentsDefault: [
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
          {idx: '', post_cate: {idx: ''}, title: '', post_img: {}},
        ],
        loading: true,
        isModalOpen: false,
        btnText: '등록',
      }
    }
  }

</script>
<style>

</style>
