export default [
  // {
  //   name: 'Dashboard',
  //   icon: 'nc-icon nc-bank',
  //   path: '/admin/overview'
  // },
  {
    name: 'Stat Manager',
    icon: 'nc-icon nc-chart-bar-32',
    children: [
      {
        name: '차트통계 관리',
        path: '/stat/chart'
      },
      {
        name: '차트 데이터 관리',
        path: '/stat/chartdata'
      },
      // {
      //   name: '검색통계 관리',
      //   path: '/stat/search'
      // },
    ]
  },
  {
    name: 'DP Manager',
    icon: 'nc-icon nc-tv-2',
    children: [
      {
        name: '큐레이션 관리',
        path: '/display/curation'
      },
      {
        name: '광고 관리',
        path: '/display/ads'
      },
      {
        name: '섹션 카테고리 관리',
        path: '/display/cate'
      },
    ]
  },
  {
    name: 'Hotel Manager',
    icon: 'nc-icon nc-istanbul',
    children: [
      {
        name: '호텔 관리',
        path: '/hotel/manager'
      },
      {
        name: '해시태그 관리',
        path: '/hotel/hash_tag'
      },
      {
        name: '지역 관리',
        path: '/hotel/region'
      },
      {
        name: '브랜드 관리',
        path: '/hotel/brand'
      },
    ]
  },
  {
    name: 'Post Manager',
    icon: 'nc-icon nc-single-copy-04',
    children: [
      {
        name: '포스트 관리',
        path: '/post/manager'
      },
      {
        name: '비디오 관리',
        path: '/post/video'
      },
      {
        name: '콘텐츠 카테고리 관리',
        path: '/post/cate'
      },
    ]
  },
  {
    name: 'Flight Manager',
    icon: 'nc-icon nc-send',
    children: [
      {
        name: '항공사 관리',
        path: '/flight/airline'
      },
    ]
  },
  // {
  //   name: 'User Manager',
  //   icon: 'nc-icon nc-single-02',
  //   children: [
  //     {
  //       name: '회원 관리',
  //       path: '/user/manager'
  //     },
  //   ]
  // },
  {
    name: 'Member Manager',
    icon: 'nc-icon nc-badge',
    children: [
      {
        name: '사용자 관리',
        path: '/member/manager'
      },
      {
        name: '사용자 권한 관리',
        path: '/member/auth'
      },
      {
        name: '사용자 분류 관리',
        path: '/member/class'
      }
    ]
  },
  // {
  //   name: 'Pages',
  //   icon: 'nc-icon nc-book-bookmark',
  //   children: [
  //     {
  //       name: 'Timeline',
  //       path: '/pages/timeline'
  //     },
  //     {
  //       name: 'User Profile',
  //       path: '/pages/user'
  //     },
  //     {
  //       name: 'Login',
  //       path: '/login'
  //     },
  //     {
  //       name: 'Register',
  //       path: '/register'
  //     },
  //     {
  //       name: 'Lock',
  //       path: '/lock'
  //     }
  //   ]
  // },
  // {
  //   name: 'Components',
  //   icon: 'nc-icon nc-layout-11',
  //   children: [{
  //     name: 'Buttons',
  //     path: '/components/buttons'
  //   },
  //   {
  //     name: 'Grid System',
  //     path: '/components/grid-system'
  //   },
  //   {
  //     name: 'Panels',
  //     path: '/components/panels'
  //   },
  //   {
  //     name: 'Sweet Alert',
  //     path: '/components/sweet-alert'
  //   },
  //   {
  //     name: 'Notifications',
  //     path: '/components/notifications'
  //   },
  //   {
  //     name: 'Icons',
  //     path: '/components/icons'
  //   },
  //   {
  //     name: 'Typography',
  //     path: '/components/typography'
  //   }]
  // },
  // {
  //   name: 'Forms',
  //   icon: 'nc-icon nc-ruler-pencil',
  //   children: [{
  //     name: 'Regular Forms',
  //     path: '/forms/regular'
  //   },
  //   {
  //     name: 'Extended Forms',
  //     path: '/forms/extended'
  //   },
  //   {
  //     name: 'Validation Forms',
  //     path: '/forms/validation'
  //   },
  //   {
  //     name: 'Wizard',
  //     path: '/forms/wizard'
  //   }
  //   ]
  // },
  // {
  //   name: 'Table List',
  //   icon: 'nc-icon nc-single-copy-04',
  //   collapsed: true,
  //   children: [{
  //     name: 'Regular Tables',
  //     path: '/table-list/regular'
  //   },
  //   {
  //     name: 'Extended Tables',
  //     path: '/table-list/extended'
  //   },
  //   {
  //     name: 'Paginated Tables',
  //     path: '/table-list/paginated'
  //   }
  //   ]
  // },
  // {
  //   name: 'Maps',
  //   icon: 'nc-icon nc-pin-3',
  //   children: [{
  //     name: 'Google Maps',
  //     path: '/maps/google'
  //   },
  //   {
  //     name: 'Full Screen Maps',
  //     path: '/maps/full-screen'
  //   },
  //   {
  //     name: 'Vector Maps',
  //     path: '/maps/vector-map'
  //   }
  //   ]
  // },
  // {
  //   name: 'Widgets',
  //   icon: 'nc-icon nc-box',
  //   path: '/admin/widgets'
  // },
  // {
  //   name: 'Charts',
  //   icon: 'nc-icon nc-chart-bar-32',
  //   path: '/charts'
  // },
  // {
  //   name: 'Calendar',
  //   icon: 'nc-icon nc-calendar-60',
  //   path: '/calendar'
  // },
]
