<template>
  <modal :show.sync="isModalOpen" :multiOpen="true"
         footer-classes="justify-content-center"
  >
    <div slot="header" class="row">
      <h5 class="modal-title text-left">
        카드선택
      </h5>
      <div class="col-md-11 mr-0 pr-0 text-right">
        <fg-input type="text"
                  class="col-md-8 mb-0 pull-right"
                  inputClasses="text-right"
                  addon-left-icon="fa fa-search"
                  placeholder="검색 키워드"
                  v-model="keyword"
                  >
        </fg-input>
      </div>
    </div>
    <template class="bg-light">
      <div class="row ">
        <div :class="`col-md-3`" v-for="(hotel, index) in queriedData" :key="index">
          <p-button class="card justify-content-center h6" @click.native="selectHotel(hotel.idx)">
            <img v-if="hotel.main_img && hotel.main_img.url" :src="hotel.main_img.url" width="auto" height="auto" class="justify-content-center" />
            {{hotel.name}}
          </p-button>
        </div>
      </div>
    </template>
    <div slot="footer" class="justify-content-center">
      <p-button type="info" size="lg" wide round @click.native="isModalOpen=false">{{btnText}}</p-button>
    </div>
  </modal>
</template>
<script>
  import Vue from 'vue'
  import Modal from 'src/components/UIComponents/Modal.vue'
  import { Loading } from 'element-ui'
  import { mapGetters } from 'vuex'
  export default {
    name: 'HotelSelector',
    components: {
      Modal
    },
    data () {
      return {
        isModalOpen: false,
        keyword: '',
        btnText: '닫기',
      }
    },
    watch: {
      isModalOpen (val) {
        if (!val) {

        }
      }
    },
    computed: {
      ...mapGetters([
        'getAllHotelDataFast',
        'getAllHotelDataFastCnt'
      ]),
      queriedData () {
        const result = this.getAllHotelDataFast.filter(row => {
          let searchPropsCnt = 1
          let isIncludedCnt = 0
          if (this.keyword) {
            const searchValue = this.keyword.toString().toLowerCase().replace(/\ /g, '')
            // name 검색
            const nameValue = row.name.toString().toLowerCase().replace(/\ /g, '')
            if (nameValue.includes && nameValue.includes(searchValue)) {
              isIncludedCnt ++
            }
          } else {
            isIncludedCnt = 1
          }
          return searchPropsCnt <= isIncludedCnt
        })
        return result
      }
    },
    methods: {
      isFileExist (obj) {
        return obj && obj.url ? true : false
      },
      selectHotel (idx) {
        this.$emit('selected-hotel', idx)
      }
    },
  }
</script>
<style scoped>
  .BUTTON {
    font-size: 1.2em;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  .card .card-body { min-height: 140px; }

</style>
