<template>
  <div class="HOTEL_MANAGER">
    <div class="col-md-12 row pr-0">
      <div class="col-md-2">
        <h5 class="title">{{cateLabel[searchDB.cate]}}{{pageName}}</h5>
        <p class="category">{{pageName}} 입니다.</p>
      </div>
      <div class="col-md-10 pr-0">
        <div class="form-group">
          <p-radio class="float-left mr-3" label="" v-model="searchDB.cate" @input="resetCurrentPage">전체</p-radio>
          <p-radio class="float-left mr-3" label="DOMESTIC" v-model="searchDB.cate" @input="resetCurrentPage">{{cateLabel['DOMESTIC']}}</p-radio>
          <p-radio class="float-left" label="OVERSEAS" v-model="searchDB.cate" @input="resetCurrentPage">{{cateLabel['OVERSEAS']}}</p-radio><br>
          <div class="pull-right mt-2">
            <p class="category">검색된 <b>{{cateLabel[searchDB.cate]}}호텔</b>은 총 <b class="text-info">{{pagination.total}}</b>개 입니다. </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 card LIST">
      <div class="card-body row table-full-width">
        <div class="col-sm-8">
          <fieldset>
            <div class="form-group">
              <el-select
                class="select-default small"
                v-model="searchDB.star_rating"
                @change="resetCurrentPage"
                placeholder="성급">
                <el-option value=""></el-option>
                <el-option v-for="i in [0,1,2,3,4,5]" :key="i"
                           class="select-info"
                           :label="`${i}`"
                           :value="i" />
              </el-select>

              <el-select
                class="select-default"
                v-model="searchDB.is_visible"
                @change="resetCurrentPage"
                placeholder="노출유무">
                <el-option label="" value=""></el-option>
                <el-option label="노출중" :value="true"></el-option>
                <el-option label="미노출" :value="false"></el-option>
              </el-select>

              <el-select
                class="select-default"
                v-model="searchDB.is_review"
                @change="resetCurrentPage"
                placeholder="프고리뷰유무">
                <el-option label="" value=""></el-option>
                <el-option label="있음" :value="true"></el-option>
                <el-option label="없음" :value="false"></el-option>
              </el-select>

              <el-cascader
                clearable
                :show-all-levels="true"

                class="select-default medium"
                placeholder="도시 선택"
                @change="setRegionData"
                :options="getRegionDataOptions"
                :props="{ value: 'idx', label: 'name', children: 'sub_data', expandTrigger: 'click', checkStrictly: true, emitPath: false }"
                filterable>
              </el-cascader>

              <el-select
                multiple
                filterable
                collapse-tags
                class="select-default large mt-2"
                v-model="searchDB.hash_tags"
                @change="resetCurrentPage"
                placeholder="해시태그">
                <el-option v-for="tag in getAllHashTagOptions" :key="tag.idx"
                           :label="`#${tag.name}`"
                           :value="tag.idx" />
              </el-select>

              <el-select
                filterable
                class="select-default medium mt-2"
                v-model="searchDB.brand"
                @change="resetCurrentPage"
                placeholder="브랜드">
                <el-option label="" value=""></el-option>
                <el-option v-for="row in getAllHotelBrandOptions" :key="row.idx"
                           :label="row.name"
                           :value="row.idx" />
              </el-select>

            </div>
          </fieldset>
        </div>
        <div class="col-sm-4 pr-0">
          <div class="col-sm-12 pull-right">
            <fg-input placeholder="키워드검색"
                      v-model="searchDB.keyword"
                      @keyup.enter="resetCurrentPage"
                      addon-left-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mt-1">
          <el-table class="table-striped"
                    header-row-class-name="text-default"
                    :data="tableData"
                    v-loading="loading"
                    border
                    style="width: 100%">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :prop="column.prop"
                             :label="column.label">
              <template slot-scope="props">
                <span v-if="column.prop === 'no'">{{getNo(props.row.key)}}</span>
                <a v-else-if="column.prop === 'name'" :href="`${appUrl}/hotel/detail/${props.row.idx}`" target="_blank" class="text-info-darken">{{props.row.name}}</a>
                <div v-else-if="column.prop === 'main_img'" class="text-center"><img v-if="props.row.main_img" :src="props.row.main_img.url" width="auto" height="auto" style="max-width:60px; max-height:6-px" /></div>
                <div v-else-if="column.prop === 'hash_tags'">
                  <el-tag
                    size="mini"
                    class="mt-0 mr-1 ml-0 bg-white text-muted"
                    v-for="tag in props.row.hash_tags" :key="tag.idx"
                    :closable="false"
                  >
                    #{{tag.name}}
                  </el-tag>
                </div>
                <div v-else-if="column.prop === 'is_review'">
                  {{props.row.is_review === true ? 'On' : ''}}
                </div>
                <span v-else>{{props.row[column.prop]}}</span>
              </template>
            </el-table-column>
            <!--<el-table-column-->
              <!--v-if="getCheckThisPageModify"-->
              <!--:min-width="100"-->
              <!--fixed="right"-->
              <!--class-name="td-actions text-center"-->
              <!--label="프고리뷰">-->
              <!--<template slot-scope="props">-->
                <!--<el-switch v-model="props.row.is_review" @change="changeIsVisibled(props.row)" active-color="#EB5E28" :disabled="!getCheckThisPageModify"></el-switch>-->
              <!--</template>-->
            <!--</el-table-column>-->
            <el-table-column
              v-if="getCheckThisPageModify"
              :min-width="90"
              fixed="right"
              class-name="td-actions text-center"
              label="검색노출">
              <template slot-scope="props">
                <el-switch v-model="props.row.is_visible" @change="changeIsVisibled(props.row)" active-color="#EB5E28" :disabled="!getCheckThisPageModify"></el-switch>
              </template>
            </el-table-column>
            <el-table-column
              v-if="getCheckThisPageModify || getCheckThisPageDelete"
              :min-width="100"
              fixed="right"
              class-name="td-actions text-center primary"
              label="수정/삭제">
              <template slot-scope="props">
                <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)" :disabled="!getCheckThisPageModify">
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button type="danger" size="sm" icon @click="handleDelete(props.$index, props.row)" :disabled="!getCheckThisPageDelete">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="card-body row table-full-width">
        <div class="col-sm-3">
          <div class="">
            <el-select
              class="select"
              v-model="pagination.perPage"
              @change="getHotelList"
              placeholder="Per page">
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="`${item} rows`"
                :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="pagination-info mt-1">
            <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
          </div>
        </div>
        <div class="col-sm-6 text-center">
          <!--<p-pagination v-model="pagination.currentPage"-->
          <!--:per-page="pagination.perPage"-->
          <!--:total="pagination.total">-->
          <!--</p-pagination>-->
          <el-pagination background
                         :current-page="pagination.currentPage"
                         :page-size="pagination.perPage"
                         :total="pagination.total"
                         layout="pager"
                         @current-change="setCurrentPage"
                         :disabled="loading">
          </el-pagination>
        </div>
        <div class="col-sm-3">
          <p class="pull-right">
            <p-button class="pull-right" type="info" round icon :disabled="total < 1" @click="downloadExcelFile(getFileName, getTableResult, getTableColumns, 'table_list')">
              <i class="fa fa-download"></i>
            </p-button>
          </p>
          <p class="pull-right mr-3">
            <p-button type="info" round @click.native="loading=false; $refs.modal.openModal()" v-if="getCheckThisPageCreate">호텔 등록</p-button>
          </p>
        </div>
      </div>
    </div>
    <hotel-detail ref="modal" @change-table-data="setHotelList"></hotel-detail>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option, DatePicker, Switch, Loading, Pagination, Upload, Tag, Cascader} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import PSwitch from "../../../UIComponents/Switch.vue";
  import swal from 'sweetalert2'
  import { mapGetters } from 'vuex'
  import commonMethods from 'src/mixins/common_methods'
  import HotelDetail from './HotelDetail'
  const baseAction = '/hotels/'
  export default{
    name: "HotelManager",
    components: {
      PSwitch,
      HotelDetail,
      PPagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      [Switch.name]: Switch,
      [Pagination.name]: Pagination,
      [Upload.name]: Upload,
      [Tag.name]: Tag,
      [Cascader.name]: Cascader,
      Loading,
    },
    data () {
      return {
        baseAction: baseAction,
        tableData: [{},{},{},{},{}], //hotels,
        pageName: '호텔 관리',
        loading: true,
        cateLabel: {
          'DOMESTIC': '국내',
          'OVERSEAS': '해외'
        },
        pagination: {
          perPage: 30,
          currentPage: 1,
          perPageOptions: [10, 30, 50, 100, 400],
          total: 0
        },
        searchDB: {
          'cate': '',
          'date': '',
          'keyword': '',
          'star_rating': '',
          'region_idxs': '',
          'hash_tags': '',
          'brand': '',
          'is_review': '',
          'is_visible': '',
        },
        tableColumns: [
          {
            prop: 'no',
            label: '#',
            minWidth: 70
          },
          {
            prop: 'hid',
            label: 'HID',
            minWidth: 80
          },
          {
            prop: 'star_rating',
            label: '성급',
            minWidth: 70
          },
          {
            prop: 'brand_name',
            label: '호텔브랜드',
            minWidth: 120
          },
          {
            prop: 'region_txt',
            label: '국가/도시',
            minWidth: 120
          },
          {
            prop: 'main_img',
            label: '썸네일',
            minWidth: 90
          },
          {
            prop: 'name',
            label: '호텔명(한글)',
            minWidth: 300
          },
          {
            prop: 'hash_tags',
            label: '해시태그',
            minWidth: 250
          },
          {
            prop: 'is_review',
            label: '프고리뷰',
            minWidth: 85
          },
        ],
        pickerOptions: {
          shortcuts: [{
            text: 'Today',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
            {
              text: 'Yesterday',
              onClick (picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
              }
            },
            {
              text: 'A week ago',
              onClick (picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', date)
              }
            }]
        },
        pickerOptions2: {
          shortcuts: [{
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      }
    },
    beforeRouteLeave (to, from, next) {
      if (this.$refs.modal.isModalOpen) {
        next(false)
      } else {
        next()
      }
    },
    mounted () {
      this.getHotelList()
      this.$store.dispatch('callAllHashTagData')
      this.$store.dispatch('callAllHotelBrandData')
      this.$store.dispatch('callAllHotelRegionDepthData')
      this.$store.dispatch('callAllPostCateData')
      this.$store.dispatch('callAllPostDataFast')
      this.$store.dispatch('callAllVideoDataFast')
      this.$store.dispatch('callAllHotelDataFast')
      this.$store.dispatch('callSeoMetaDataDefault')
    },
    computed: {
      ...mapGetters([
        'getAllHashTagOptions',
        'getAllHotelBrandOptions',
        'getAllHotelRegionDepthData',
        'getCheckThisPageCreate',
        'getCheckThisPageRead',
        'getCheckThisPageModify',
        'getCheckThisPageDelete'
      ]),
      to () {
        let highBound = this.from + this.pagination.perPage
        // if (this.total < highBound) {
        //   highBound = this.total
        // }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        // this.pagination.total = this.tableData.length
        // return this.tableData.length
        return this.pagination.total
      },
      appUrl () {
        return Vue.prototype.$appURL
      },
      getFileName () {
        const cate = this.searchDB.cate ? this.cateLabel[this.searchDB.cate] : ''
        const keyword = this.searchDB.name ? `_(${this.searchDB.name})` : ''
        return `${cate}${this.pageName.replace(/\ /g, '')} ${keyword}`
      },
      getTableResult () {
        const tableResult = this.tableData.reduce((acc, cur) => {
          const row = {}
          this.getTableColumns.map(col => {
            if (col.prop !== 'main_img' && cur[col.prop] !== undefined ) {
              if (col.prop === 'no') {
                row[col.prop] = this.getNo(cur.key)
              } else if (col.prop === 'is_visible') {
                row[col.prop] = cur[col.prop] ? 'on' : 'off'
              } else {
                row[col.prop] = cur[col.prop]
              }
            }
          })
          acc.push(row)
          return acc
        }, [])
        return tableResult
      },
      getTableColumns () {
        const rtn = this.tableColumns
        rtn.push({
          prop: 'is_visible',
          label: '검색노출',
        })
        return rtn
      },
      getRegionDataOptions () {
        return this.searchDB.cate === 'DOMESTIC'
          ? this.cloneData(this.getAllHotelRegionDepthData).filter(row => row.idx === 1 || row.idx_p === 1)
          : this.searchDB.cate === 'OVERSEAS' ?
            this.cloneData(this.getAllHotelRegionDepthData).filter(row => row.idx !== 1 && row.idx_p !== 1)
          : this.cloneData(this.getAllHotelRegionDepthData)
      }
    },
    methods: {
      ...commonMethods,
      getHotelList () {
        this.loading = true
        const params = { params: {cate: this.searchDB.cate} }
        params.params.p = this.pagination.currentPage
        params.params.perPage = this.pagination.perPage
        params.params.is_review = this.searchDB.is_review
        params.params.is_visible = this.searchDB.is_visible
        if (this.searchDB.keyword) params.params.keyword = this.searchDB.keyword
        if (this.searchDB.star_rating.toString().length) params.params.star_rating = this.searchDB.star_rating
        if (this.searchDB.brand) params.params.brand = this.searchDB.brand
        if (this.searchDB.region_idxs) params.params.region_idxs = this.searchDB.region_idxs
        if (this.searchDB.hash_tags) params.params.hash_tags = (this.searchDB.hash_tags)
        if (this.searchDB.date) params.params.date = this.searchDB.date.join(',')
        // 모두 가져오기
        // this.axios.get('/hotels/search/all', params).then((rs) => {
        //   this.tableData = rs.data
        //   this.loading = false
        // })
        this.axios.get('/hotels/search/all', params).then((rs) => {
          this.tableData = rs.data.data
          if (this.pagination.total !== rs.data.total) this.pagination.total = rs.data.total
          this.loading = false
        })
      },
      resetCurrentPage () {
        this.setCurrentPage(1)
      },
      setRegionData (idx) {
        this.searchDB.region_idxs = idx
        this.resetCurrentPage()
      },
      changeIsVisibled (row) {
        let params = {idx: row.idx, is_visible: row.is_visible};
        let action = '/hotels/visible/' + params.idx
        this.axios.put(action, params).then((rs) => {
          if (rs.data.status === 'success') {
            this.setHotelList('put', rs.data.data[0])
          }
        }).catch( error => {
          console.log('failed', error)
        })
      },
      setHotelList (method, row) {
        if(method === 'put') {
          for (let key in this.tableData) {
            if (this.tableData[key].idx === row.idx) {
              row.no = this.tableData.length
              this.tableData.splice(key, 1, row)
              break
            }
          }
        } else {
          row.no = this.tableData.length + 1
          this.tableData.unshift(row)
        }
      },
      handleEdit(index, row) {
        this.$refs.modal.openModal(row)
      },
      handleDelete(index, row) {
        let $this = this
        swal({
          title: '삭제하시겠습니까?',
          // text: '삭제하면 관련',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          buttonsStyling: false
        }).then(function () {
          $this.loading = true
          const action = '/hotels/' + row.idx
          $this.axios.delete(action).then((rs) => {
            $this.loading = false
            if (rs.data.status === 'success') {
              const indexToDelete = $this.tableData.findIndex((tableRow) => tableRow.idx === row.idx)
              if (indexToDelete > -1) {
                $this.tableData.splice(indexToDelete, 1)
              }
            } else {
              swal({
                title: 'ERROR',
                text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
                type: 'error',
                confirmButtonClass: 'btn btn-info btn-fill',
                buttonsStyling: false
              })
            }
          }).catch( error => {
            $this.loading = false
          })
        }, function (dismiss) {

        })
      },
      getNo (no) {
        const rtn = this.pagination.total - (no + (this.pagination.perPage * (this.pagination.currentPage-1)))
        return !isNaN(rtn) ? rtn : ''
      },
      setCurrentPage (p) {
        // if (p !== this.pagination.currentPage) {
          this.pagination.currentPage = p
          this.getHotelList()
        // }
      }
    }
  }
</script>
<style scoped lang="scss">
  /deep/ .LIST .el-range-separator {
    width: 30px !important;
  }
  /deep/ .LIST .el-select,
  /deep/ .LIST .el-cascader {
    width: 22%;
    min-width: 110px;
    margin-right: 5px;
  }
  /deep/ .LIST .el-select.small,
  /deep/ .LIST .el-cascader.small {
    width: 15%;
    min-width: 80px;
  }
  /deep/ .LIST .el-select.medium,
  /deep/ .LIST .el-cascader.medium {
    width: 30%;
    min-width: 120px;
  }
  /deep/ .LIST .el-select.large,
  /deep/ .LIST .el-cascader.large {
    width: 60.5%;
    min-width: 140px;
  }
  /deep/ .LIST .el-select:last-child {
    margin-right:0;
  }
  /deep/ .modal-dialog {
    padding-top: 0 !important;
    width: 95% !important;
    max-width: 1919px !important;
    margin: 2.5rem auto !important;
  }
  /deep/ .modal-body {
    background-color: #f4f3ef;
    padding: 20px 30px !important;
  }
</style>
