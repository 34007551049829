<template>
  <div class="DISPLAY_ADS">

    <!-- 헤더 및 컨트롤 -->
    <div class="col-md-12 row pr-0">
      <div class="col-md-3">
        <h5 class="title">광고 관리</h5>
        <p class="category">
          <!--We combine <a href="http://element.eleme.io/#/en-US/component/quickstart" target="_blank" rel="noopener">Element-UI</a>-->
          <!--table functionalities together with a custom pagination component-->
          <!--which should provide a very good starting point to integrate tables in your application.-->
          <!--Check out more functionalities at <a href="http://element.eleme.io/#/en-US/component/table" target="_blank" rel="noopener">Element-UI table documentation</a>.-->
          광고 관리 입니다.
        </p>
      </div>
      <div class="col-md-9 pr-0">
        <div class="form-group">
          <div class="pull-right mt-4">
            <p class="category">검색된 <b>광고</b>는 총 <b class="text-info">{{pagination.total}}</b>개 입니다. </p>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <el-container class="card-body">
        <el-header class="position-relative row">
          <div class="col-sm-3">
            <el-radio-group v-model="isCollapse" class="position-absolute">
              <el-radio-button :label="false">펼치기</el-radio-button>
              <el-radio-button :label="true">접기</el-radio-button>
            </el-radio-group>
          </div>
          <div class="col-sm-6">
            <transition name="fade" mode="in-out">
              <h5 class="pt-2 modal-title text-center" v-if="selectedDisplayCate.pathName">
                {{selectedDisplayCate.pathName}}
              </h5>
            </transition>
          </div>
          <div class="col-sm-3">
            <!--<fg-input v-model="searchQuery.keyword" />-->
            <fg-input class="input-sm"
                      placeholder="키워드검색"
                      v-model="searchDB.keyword"
                      @keyup.enter="getDisplayList()"
                      addon-right-icon="nc-icon nc-zoom-split"
            />
          </div>
        </el-header>
        <el-container style="max-height: 700px">
          <el-aside :width="`${isCollapse ? 64 : 220}px`">
            <el-menu v-loading="loadingCate"
                     element-loading-spinner="el-icon-loading mt-3"
                     class="el-menu-vertical-demo" :unique-opened="true" :collapse="isCollapse" @select="setDisplayCateSelectedItem" @open="setDisplayCateOpenedItem" ref="menu">
              <el-menu-item index="" @click="menuClose">
                전체
              </el-menu-item>
              <el-submenu v-for="page in displayCate" :key="page.idx" :index="page.idx.toString()">
                <template slot="title">
                  <div v-if="isCollapse" class="pull-left overflow-hidden" style="width:25px">{{page.name}}</div>
                  <span slot="title">{{page.name}}</span>
                </template>
                <div v-for="section in page.sections" :key="section.idx">
                  <el-submenu v-if="section.sections && section.sections.length>0" :index="section.idx.toString()">
                    <template slot="title">
                      {{section.name}}
                    </template>
                    <el-menu-item v-if="section.sections" v-for="slot in section.sections" :key="slot.idx" :index="slot.idx.toString()">
                      {{slot.name}}
                      <i class="nc-icon nc-settings-gear-65 pull-right mt-2"/>
                    </el-menu-item>
                  </el-submenu>
                  <el-menu-item v-else :index="section.idx.toString()">
                    {{section.name}}
                    <i class="nc-icon nc-settings-gear-65 pull-right mt-2"/>
                  </el-menu-item>
                </div>
              </el-submenu>
            </el-menu>
          </el-aside>
          <el-container>
            <el-main class="p-0">

              <el-table-draggable handle=".handle">
                <el-table :data="tableData"
                          v-loading="loading"
                          header-row-class-name="text-default">

                  <el-table-column :min-width="30">
                    <template slot-scope="props" v-if="selectedDisplayCate.idx">
                      <div class="handle" :class="isReSorted ? 'text-warning' : 'text-gray' ">
                        <i class="nc-icon nc-tile-56 mt-2"></i>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column :width="70"
                                   label="#">
                    <template slot-scope="props">
                      <div>{{ !selectedDisplayCate.idx ? props.row.no : props.$index+1 }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="did"
                                   :min-width="70"
                                   label="DID">
                  </el-table-column>
                  <el-table-column v-if="!selectedDisplayCate.idx"
                                   prop="section.pathName"
                                   label="색션"
                                   :min-width="250">
                  </el-table-column>
                  <el-table-column prop="ad_title"
                                   :min-width="200"
                                   label="캠페인">
                  </el-table-column>
                  <el-table-column prop="ad_channel_txt"
                                   :min-width="80"
                                   label="채널">
                  </el-table-column>
                  <el-table-column prop="ad_code_app"
                                   :min-width="70"
                                   label="M 코드">
                  </el-table-column>
                  <el-table-column prop="dp_type_txt"
                                   align="center"
                                   :min-width="80"
                                   label="노출 소재">
                  </el-table-column>
                  <el-table-column :min-width="120"
                                   label="연동DB">
                    <template slot-scope="props">
                      <div v-if="props.row.model_idx">{{props.row.model}} / {{props.row.model_idx}}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="reg_dt"
                                   :min-width="100"
                                   label="등록일자">
                  </el-table-column>
                  <el-table-column :min-width="170"
                                   label="노출기간">
                    <template slot-scope="props">
                      <div v-if="props.row.dp_status === 'T'" style="font-size: 11px">
                        {{props.row.dp_start_time ? props.row.dp_start_time : '즉시'}} ~ {{props.row.dp_end_time ? props.row.dp_end_time : '무기한'}}
                      </div>
                      <div v-else-if="props.row.dp_status === 'Y'">항상</div>
                      <div v-else>없음</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :min-width="64"
                    align="center"
                    fixed="right"
                    class-name="td-actions text-center"
                    label="노출">
                    <template slot-scope="props">
                      <div v-if="props.row.dp_status === 'T' || !getCheckThisPageModify">{{props.row.dp_status_txt}}</div>
                      <div v-else>
                        <el-switch v-model="props.row.dp_status" @change="changeDpStatus(props.row)" active-value="Y" inactive-value="N" active-color="#EB5E28"></el-switch>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-if="getCheckThisPageCreate || getCheckThisPageModify || getCheckThisPageDelete"
                    :min-width="140"
                    align="center"
                    fixed="right"
                    class-name="action-buttons td-actions"
                    label-class-name="text-center"
                    label="수정/삭제/복사">
                    <template slot-scope="props">
                      <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)" :disabled="!getCheckThisPageModify">
                        <i class="fa fa-edit"></i>
                      </p-button>
                      <p-button type="danger" size="sm" icon @click="handleDelete(props.$index, props.row)" :disabled="!getCheckThisPageDelete">
                        <i class="fa fa-times"></i>
                      </p-button>
                      <p-button type="warning" size="sm" icon @click="handleCopy(props.$index, props.row)" :disabled="!getCheckThisPageCreate">
                        <i class="fa fa-copy"></i>
                      </p-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-table-draggable>

              <div class="row m-0 pt-2 pr-0">
                <div class="col-sm-9" v-if="selectedDisplayCate.idx && getCheckThisPageModify">
                  <p class="pull-left">
                    <p-button :type="isReSorted ? 'warning' : 'default'" :disabled="!isReSorted" round @click="saveSorting()">순서 저장</p-button>
                  </p>
                </div>

                <div class="col-sm-3" v-if="!selectedDisplayCate.idx">
                  <div class="">
                    <el-select
                      class="select"
                      v-model="pagination.perPage"
                      placeholder="Per page">
                      <el-option
                        class="select-default"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="`${item} rows`"
                        :value="item">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="pagination-info mt-1">
                    <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
                  </div>
                </div>
                <div class="col-sm-6" v-if="!selectedDisplayCate.idx">
                  <!--<p-pagination v-model="pagination.currentPage"-->
                                <!--:per-page="pagination.perPage"-->
                                <!--:total="pagination.total">-->
                  <!--</p-pagination>-->
                  <el-pagination background
                                 :value="pagination.currentPage"
                                 :page-size="pagination.perPage"
                                 :total="pagination.total"
                                 layout="pager"
                                 @current-change="setCurrentPage"
                                 :disabled="loading">
                  </el-pagination>
                </div>

                <div class="col-sm-3 pr-0">
                  <p class="pull-right">
                    <p-button type="info text-nowrap" round @click="openModal(); loading=false" v-if="getCheckThisPageCreate">광고 등록</p-button>
                  </p>
                </div>
              </div>

            </el-main>
          </el-container>
        </el-container>
      </el-container>
    </div>


    <!-- 폼 모달 -->
    <modal :show.sync="isModalOpen"
           footer-classes="justify-content-center">

      <div slot="header" class="pull-left">
        <span class="modal-title text-left h5">광고 {{btnText}}</span>
        <span class="small-title pl-3 text-danger title" v-if="doCopy">업로드된 이미지는 자동으로 복사되지 않습니다. 복사 후 수정화면에서 재업로드해 주세요.</span>
      </div>
      <template class="bg-light">
        <div class="row ">
          <div class="col-sm-6 col-md-7 col-lg-8">

            <div class="card">
              <div class="card-header">
                <h5 class="title">
                  광고 정보
                </h5>
              </div>
              <div class="card-body">
                <form>
                  <div class="row">
                    <div class="col-md-12">
                      <fg-input type="text"
                                label="캠페인"
                                placeholder="캠페인 입력"
                                class="required"
                                v-model="formData.ad_title">
                      </fg-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <fg-input type="text"
                                label="추적코드"
                                placeholder="추적코드 입력"
                                class="required text-nowrap"
                                v-model.trim="formData.ad_code_app">
                      </fg-input>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <h5 class="title">
                  큐레이션 정보
                </h5>
              </div>
              <div class="card-body">
                <form>
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group">
                        <label class="required">카테고리</label>
                        <div>
                          <el-cascader v-model="formData.section.path"
                                       class="col-md-12 p-0"
                                       placeholder="카테고리 선택"
                                       :options="displayCate"
                                       :props="{value: 'idx', label: 'name', children: 'sections'}"
                                       @change="changeDisplayCateData"
                                       filterable>
                          </el-cascader>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 pl-md-0">
                      <label class="required">인터렉티브 효과</label>
                      <div class="form-group">
                        <el-select
                          class="select col-md-12 p-0"
                          v-model="formData.dp_interactive"
                          placeholder="익터렉티브 효과 선택">
                          <el-option
                            class=""
                            label="적용 안함"
                            value="N">
                          </el-option>
                          <el-option
                            class=""
                            label="축소 효과"
                            value="IN">
                          </el-option>
                          <el-option
                            class=""
                            label="확장 효과"
                            value="OUT">
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-1">
                    <div class="col-md-12">
                      <label class="required">L/P URL</label>
                      <div class="pull-right">
                        <p-radio class="float-left mr-3 mb-0" label="_blank" v-model="formData.link_target_app">blank</p-radio>
                        <p-radio class="float-left mr-3 mb-0" label="_self" v-model="formData.link_target_app">self</p-radio>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <fg-input type="url"
                                placeholder="URL 입력"
                                v-model="formData.link_url_app">
                      </fg-input>
                    </div>
                  </div>

                  <div class="row mt-1">
                    <div class="col-md-12">
                      <label class="required">노출 소재 선택</label>
                      <div class="pull-right p-0 pb-1">
                        <p-radio class="pull-left mr-1 mb-0" v-for="(radio, index) in dpTypeValue" :key="index" :label="radio.value" v-model="formData.dp_type">{{radio.label}}</p-radio>
                        <el-tooltip class="pull-right" :open-delay="300" placement="top" effect="light">
                          <div slot="content" class="line">
                            <h6 class="notification-text">노출 소재는 다음과 같이 동작합니다.</h6>
                            <div class="text-black-50 py-1">- <b>원본 이미지 사용</b> : DB연동 된 데이터의 원래 이미지를 그대로 사용합니다. (DB연동 필수)</div>
                            <div class="text-black-50 py-1">- <b>원본 이미지 교체</b> : DB연동 된 데이터의 원본 이미지를 업로드한 이미지로 교체합니다. (DB연동 필수)</div>
                            <div class="text-black-50 py-1">- <b>배너 이미지</b> : 업로드한 이미지를 꽉찬 배너로 이미지로 사용합니다. (DB연동 선택)</div>
                            <div class="text-black-50 py-1">- <b>HTML</b> : 작성한 HTML을 꽉찬 배너로 사용합니다. (DB연동 선택)</div>
                          </div>
                          <p-button class="px-1 py-0 mt-1 mb-0" type="warning" size="sm" link>
                            <i class="fa fa-info-circle"></i>
                          </p-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                  <div v-if="formData.dp_type === 'ORIGINAL' || formData.dp_type === 'THUMB'">
                    <div class="row">
                      <div class="col-md-12">
                        <fg-input type="text"
                                  class="mb-1"
                                  placeholder="DP 타이틀 입력"
                                  v-model="formData.dp_title">
                        </fg-input>
                      </div>
                      <div class="col-md-12">
                        <el-input type="textarea"
                                  class="mb-1"
                                  @input="limitInputLine($event, formData, 'dp_text1', 2)"
                                  :autosize="{ minRows: 1, maxRows:2 }"
                                  placeholder="DP 텍스트 1"
                                  v-model="formData.dp_text1">
                        </el-input>
                      </div>
                      <div class="col-md-12">
                        <el-input type="textarea"
                                  class="mb-1"
                                  @input="limitInputLine($event, formData, 'dp_text2', 4)"
                                  :autosize="{ minRows: 1, maxRows:4 }"
                                  placeholder="DP 텍스트 2"
                                  v-model="formData.dp_text2">
                        </el-input>
                      </div>
                    </div>
                  </div>

                  <div v-if="formData.dp_type === 'THUMB' || formData.dp_type === 'BANNER'">
                    <div class="row">
                      <div class="col-md-12 pt-md-0 pt-2">
                        <el-upload
                          id="app_img"
                          ref="app_img"
                          class="upload-demo"
                          action="about:blank"
                          :file-list="getFileList(formData.app_img)"
                          :on-preview="handlePreview"
                          :on-change="handleChange"
                          :on-remove="handleRemove"
                          :auto-upload="false"
                          :limit="1"
                          list-type="picture">
                          <div class="row px-md-3">
                            <span class="col-md-7 p-md-0 NO_RADIUS_RIGHT">
                              <p-button class="m-0 mb-1 text-left text-nowrap" v-if="!isFileExist(formData.app_img)" block>모바일 이미지 업로드</p-button>
                              <p-button class="m-0 mb-1 text-left text-nowrap" v-else @click.prevent.stop="handleRemove" block>모바일 이미지 삭제</p-button>
                            </span>
                            <span class="col-md-2 p-md-0 col-5 pr-0 NO_RADIUS NO_BORDER_X">
                              <fg-input class="p-0 m-0" input-classes="text-right px-0" type="text" placeholder="너비" maxlength="4" @click="eventCancel" @focus="eventCancel" v-model.number="formData.app_img_size.width" />
                            </span>
                            <span class="col-md-1 p-0 col-2 NO_RADIUS NO_BORDER_X">
                              <fg-input class="p-0 m-0" input-classes="text-center p-0 bg_fafafa"  type="text" value="x" disabled></fg-input>
                            </span>
                            <span class="col-md-2 p-md-0 col-5 pl-0 NO_RADIUS_LEFT NO_BORDER_LEFT">
                              <fg-input class="p-0 m-0" input-classes="text-left px-0" type="text" placeholder="높이" maxlength="4" @click="eventCancel" @focus="eventCancel" v-model.number="formData.app_img_size.height" />
                            </span>
                          </div>
                        </el-upload>
                      </div>
                    </div>

                    <transition name="fade">
                      <div class="row" v-show="formData.dp_interactive !== 'N'">
                        <div class="col-md-12 pt-md-0 pt-2">
                          <el-upload
                            id="app_img_interactive"
                            ref="app_img_interactive"
                            class="upload-demo"
                            action="about:blank"
                            :file-list="getFileList(formData.app_img_interactive)"
                            :on-preview="handlePreview"
                            :on-change="handleChange"
                            :on-remove="handleRemove"
                            :auto-upload="false"
                            :limit="1"
                            list-type="picture">
                            <div class="row px-md-3">
                              <span class="col-md-7 p-md-0 NO_RADIUS_RIGHT">
                                <p-button class="m-0 mb-1 text-left text-nowrap" v-if="!isFileExist(formData.app_img_interactive)" block>모바일 인터렉티브 업로드</p-button>
                              <p-button class="m-0 mb-1 text-left text-nowrap" v-else @click.prevent.stop="handleRemove" block>모바일 인터렉티브 삭제</p-button>
                              </span>
                              <span class="col-md-2 p-md-0 col-5 pr-0 NO_RADIUS NO_BORDER_X">
                                <fg-input class="p-0 m-0" input-classes="text-right px-0" type="text" placeholder="너비" maxlength="4" @click="eventCancel" @focus="eventCancel" v-model.number="formData.app_img_interactive_size.width" />
                              </span>
                              <span class="col-md-1 p-0 col-2 NO_RADIUS NO_BORDER_X">
                                <fg-input class="p-0 m-0" input-classes="text-center p-0 bg_fafafa"  type="text" value="x" disabled></fg-input>
                              </span>
                              <span class="col-md-2 p-md-0 col-5 pl-0 NO_RADIUS_LEFT NO_BORDER_LEFT">
                                <fg-input class="p-0 m-0" input-classes="text-left px-0" type="text" placeholder="높이" maxlength="4" @click="eventCancel" @focus="eventCancel" v-model.number="formData.app_img_interactive_size.height" />
                              </span>
                            </div>
                          </el-upload>
                        </div>
                      </div>
                    </transition>
                  </div>

                  <div class="row pt-md-0 pt-2" v-if="formData.dp_type === 'HTML'">
                    <div class="col-md-12">
                      <div class="form-group">
                        <!--<textarea rows="5" class="form-control border-input"-->
                        <!--placeholder="상세 연회비 입력"-->
                        <!--v-model="card.annual_fee_detail">-->
                        <!--</textarea>-->
                        <froala :tag="'textarea'" :config="config_dp_html" :readonly="true" v-model="formData.dp_html"></froala>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>

          </div>
          <div class="col-sm-6 col-md-5 col-lg-4">

            <div class="card">
              <div class="card-header">
                <h5 class="title">
                  DB연동 정보
                </h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <label :class="{required: (formData.dp_type === 'ORIGINAL' || formData.dp_type === 'THUMB')}">DB MODEL</label>
                    <div class="form-group row">
                      <div class="col-md-9 pr-0">
                        <el-select
                          class="select NO_RADIUS_RIGHT"
                          v-model="formData.model"
                          @change="formData.model_idx = ''; formData.model_data = {} "
                          placeholder="DB MODEL">
                          <el-option class="select-default" value=""></el-option>
                          <el-option
                            class="select-default"
                            label="호텔"
                            value="hotels">
                          </el-option>
                          <el-option
                            class="select-default"
                            label="항공"
                            value="flights">
                          </el-option>
                          <el-option
                            class="select-default"
                            label="비디오"
                            value="videos">
                          </el-option>
                          <el-option
                            class="select-default"
                            label="포스트"
                            value="posts">
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-md-3 pl-0 NO_RADIUS_LEFT">
                        <p-button type="default" class="m-0 text-nowrap" style="height:40px" block @click="changeModel(formData.model)">선택</p-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <fg-input type="number"
                              label="DB MODEL INDEX"
                              :placeholder="`${formData.model === 'hotels' ? 'CID' : formData.model === 'posts' ? 'PID' : 'INDEX' } 입력`"
                              :class="{required: formData.model}"
                              :disabled="!formData.model"
                              v-model.number="formData.model_idx">
                    </fg-input>
                  </div>
                </div>
                <transition name="fade">
                  <div class="row" v-if="formData.model_data.idx && formData.model_data.idx === formData.model_idx">
                    <div class="col-md-12">
                      <ul v-if="formData.model === 'hotels'" class="el-upload-list el-upload-list--picture">
                        <li class="el-upload-list__item is-ready my-1 text-muted">
                          <img v-if="formData.model_data.main_img" :src="formData.model_data.main_img.small" class="el-upload-list__item-thumbnail" style="width: auto; height: auto; max-height: 70px;" />
                          <a class="el-upload-list__item-name mr-0" style="font-size: 14px">
                            {{formData.model_data.name}}
                          </a>
                        </li>
                      </ul>
                      <ul v-else-if="formData.model === 'flights'" class="el-upload-list el-upload-list--picture">
                        <li class="el-upload-list__item is-ready my-1 text-muted">
                          <img v-if="isFileExist(formData.model_data.logo_img)" :src="formData.model_data.logo_img.url" class="el-upload-list__item-thumbnail" style="width: auto; height: auto; max-height: 70px;" />
                          <a class="el-upload-list__item-name mr-0" style="font-size: 14px">
                            {{formData.model_data.name}}
                          </a>
                        </li>
                      </ul>
                      <ul v-else-if="formData.model === 'videos'" class="el-upload-list el-upload-list--picture">
                        <li class="el-upload-list__item is-ready my-1 text-muted" >
                          <img v-if="formData.model_data.thumbnail" :src="formData.model_data.thumbnail" alt="" class="el-upload-list__item-thumbnail">
                          <div class="position-absolute pt-2   pl-1 text-info">VIDEO</div>
                          <a class="el-upload-list__item-name mt-4 mr-0 pt-2 h6 text-left" style="font-size: 14px">
                            <i class="el-icon-document"></i>{{formData.model_data.title}}
                          </a>
                        </li>
                      </ul>
                      <ul v-else-if="formData.model === 'posts'" class="el-upload-list el-upload-list--picture">
                        <li class="el-upload-list__item is-ready my-1 text-muted" >
                          <img v-if="isFileExist(formData.model_data.post_img)" :src="formData.model_data.post_img.url" alt="" class="el-upload-list__item-thumbnail">
                          <div class="position-absolute pt-2   pl-1 text-info">{{formData.model_data.post_cate_txt}}</div>
                          <a class="el-upload-list__item-name mt-4 mr-0 pt-2 h6 text-left" style="font-size: 14px">
                            <i class="el-icon-document"></i>{{formData.model_data.title}}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </transition>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <label class="pull-left">노출 설정</label>
                    <span class="pull-right">
                      <p-radio class="pull-left mr-1" v-for="(radio, index) in dpStatusValue" :key="index" :label="radio.value" v-model="formData.dp_status">{{radio.label}}</p-radio>
                      <el-tooltip class="pull-right" :open-delay="300" placement="top" effect="light">
                        <div slot="content" class="line">
                          <h6 class="notification-text">기간 선택 후 선택하지 않으면 다음과 같이 동작합니다.</h6>
                          <div class="text-black-50">시작일시 공란 : 즉시노출</div>
                          <div class="text-black-50">종료일시 공란 : 무제한</div>
                        </div>
                        <p-button class="px-1 py-0 mt-1 mb-0" type="warning" size="sm" link>
                          <i class="fa fa-info-circle"></i>
                        </p-button>
                      </el-tooltip>
                    </span>
                  </div>
                </div>
                <div class="row" >
                  <div class="col-md-12">
                    <fg-input>
                      <el-date-picker v-model="formData.dp_start_time"
                                      type="date"
                                      class="DATE_PICKER"
                                      placeholder="시작일시"
                                      value-format="yyyy-MM-dd"
                                      :disabled="formData.dp_status !== 'T'">
                      </el-date-picker>
                    </fg-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <fg-input>
                      <el-date-picker v-model="formData.dp_end_time"
                                      type="date"
                                      class="DATE_PICKER"
                                      placeholder="종료일시"
                                      value-format="yyyy-MM-dd"
                                      :disabled="formData.dp_status !== 'T'">
                      </el-date-picker>
                    </fg-input>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </template>
      <div slot="footer" class="justify-content-center">
        <p-button :type="doCopy ? 'warning' : 'info'" size="lg" wide round @click="saveDisplayData">{{btnText}}</p-button>
      </div>
    </modal>

    <!-- 이미지뷰어 다이얼로그 -->
    <el-dialog :visible.sync="dialogVisible"
               :modal-append-to-body="false">
      <img :src="dialogImageUrl" alt="">
    </el-dialog>

    <!-- DB연동 모달 -->
    <data-selector ref="modal" :model="formData.model" @selected-data="selectedModelData"></data-selector>

  </div>
</template>
<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import {
    Container, Header, Aside, Main, Menu, MenuItem, MenuItemGroup, Submenu,
    RadioGroup, RadioButton,
    Table, TableColumn, Pagination,
    Select, Option, Cascader,
    Loading, Upload, Dialog, Switch,
    DatePicker, Tooltip, Divider, Input,
  } from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import ElTableDraggable from 'element-ui-el-table-draggable'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import Modal from 'src/components/UIComponents/Modal'
  import swal from 'sweetalert2'
  import commonMethods from 'src/mixins/common_methods'
  import DataSelector from './DisplayDetail/DataSelector'
  import editors from './editors'
  import forms from './forms'
  import displays from './displays'
  const baseAction = '/displays/'
  const IMG_SIZE_DEFAULT = {width: '', height: ''}
  export default{
    components: {
      PSwitch, Modal, ElTableDraggable, DataSelector, PPagination,
      [Container.name]: Container,
      [Header.name]: Header,
      [Aside.name]: Aside,
      [Main.name]: Main,
      [Menu.name]: Menu,
      [MenuItem.name]: MenuItem,
      [MenuItemGroup.name]: MenuItemGroup,
      [Submenu.name]: Submenu,
      [RadioGroup.name]: RadioGroup,
      [RadioButton.name]: RadioButton,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      [Cascader.name]: Cascader,
      [Upload.name]: Upload,
      [Dialog.name]: Dialog,
      [Switch.name]: Switch,
      [DatePicker.name]: DatePicker,
      [Tooltip.name]: Tooltip,
      [Divider.name]: Divider,
      [Input.name]: Input,
      [Pagination.name]: Pagination,
    },
    data () {
      return {
        baseAction: baseAction,
        formData: forms,
        formDataRs: {},
        searchDB: {
          'keyword': '',
          '': ''
        },
        searchQuery: {
          'section': '',
          'ad_title': ''
        },
        propsToSearch: ['section', 'ad_title'],
        pagination: {
          perPage: 30,
          currentPage: 1,
          perPageOptions: [10, 30, 50, 100, 400],
          total: 0
        },
        dpTypeValue: [
          {label: "원본 이미지 사용", value: "ORIGINAL", checked: true},
          {label: "원본 이미지 교체", value: "THUMB", checked: false},
          {label: "배너 이미지", value: "BANNER", checked: false},
          {label: "HTML", value: "HTML", checked: false},
        ],
        dpStatusValue: [
          {label: "미노출", value: "N", checked: true},
          {label: "노출", value: "Y", checked: false},
          {label: "기간", value: "T", checked: false},
        ],
        selectedDisplayCate: {
          idx: 0,
          path: [],
          pathName: '전체'
        },
        openedDisplayCate: {
          idx: 0,
          path: []
        },
        displayCate: [],
        displayCateOptions: [],
        uploadData: [],
        doCopy: false,
        isCollapse: false,
        tableData: displays,
        tableDataOrigin: [],
        loading: true,
        loadingCate: true,
        isModalOpen: false,
        dialogImageUrl: '',
        dialogVisible: false,
        btnText: '등록',
        config_dp_html: {
          key: editors.keys,
          events: editors.events,
          htmlRemoveTags: editors.htmlRemoveTags,
          imageEditButtons: editors.imageEditButtons,
          imageDefaultWidth: editors.imageDefaultWidth,
          imageResize: editors.imageResize,
          placeholderText: "HTML",
          useClasses: true,
          height: 120,
          imageUploadURL: Vue.prototype.$apiURL + '/displays/upload/editor',
          imageUploadParams: {column: 'dp_html'},
          imageMove: true,
        },
        pickerOptions: {
          shortcuts: [
            {
              text: 'Today',
              onClick (picker) {
                picker.$emit('pick', new Date())
              }
            },
            {
              text: 'Yesterday',
              onClick (picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
              }
            },
            {
              text: 'A week ago',
              onClick (picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', date)
              }
            }
          ]
        },
      }
    },
    watch: {
      isModalOpen (val) {
        if (!val) {
          if (JSON.stringify(this.formDataRs) !== JSON.stringify(this.formData)) {
            if (!confirm('변경된 내용이 있습니다. 무시하고 창을 닫을까요?')) {
              this.isModalOpen = true
              return false;
            }
          }

          // forms 초기화
          this.resetFormData ()

          window.removeEventListener('beforeunload', this.unload)
        } else {
          window.addEventListener('beforeunload', this.unload)
        }
      }
    },
    beforeRouteLeave (to, from, next) {
      if (this.isModalOpen) {
        next(false)
      } else {
        next()
      }
    },
    created () {
      window.vm = this
    },
    mounted () {
      this.getDisplayList()
      this.$store.dispatch('callAllDisplayCateData').then(() => {
        this.getDisplayCateList()
      })
      // this.setDisplayCateOptions()
    },
    computed: {
      ...mapGetters([
        'getAllDisplayCateData',
        'getCheckThisPageCreate',
        'getCheckThisPageRead',
        'getCheckThisPageModify',
        'getCheckThisPageDelete'
      ]),
      getDisplayCateFlat () {
        return this.displayCate.reduce((acc, cur) => {
          acc.push({idx: cur.idx, name: cur.name})
          cur.sections.map(cur2 => {
            acc.push({idx: cur2.idx, name: cur2.name})
            if (cur2.sections) cur2.sections.map(cur3 => {
              acc.push({idx: cur3.idx, name: cur3.name})
            })
          })
          return acc
        }, [])
      },
      isReSorted () {
        const cur = this.tableData.map(row => row.idx).toString()
        const ori = this.tableDataOrigin.map(row => row.idx).toString()
        return cur && cur !== ori
      },
      // queriedData () {
      //   // if (!this.selectedDisplayCate.idx) {
      //     let searchQueryData = Object.values(this.searchQuery).join('')
      //     if (!searchQueryData) {
      //       this.pagination.total = this.tableData.length
      //       return this.pagedData
      //     }
      //     let result = this.tableData.filter((row) => {
      //       let searchPropsCnt = 0
      //       let isIncludedCnt = 0
      //       for (let key of this.propsToSearch) {
      //         let rowValue, idxValue
      //         let searchValue = this.searchQuery[key].toString().toLowerCase().replace(/\ /g, '')
      //         if (searchValue) {
      //           searchPropsCnt++
      //           if (key === 'section') {
      //             rowValue = row[key] ? row[key]['pathName'].toString().toLowerCase().replace(/\ /g, '') : ''
      //             if (rowValue.includes && rowValue.includes(searchValue)) {
      //               isIncludedCnt++
      //             }
      //           } else {
      //             rowValue = row[key] ? row[key].toString().toLowerCase().replace(/\ /g, '') : ''
      //             if (rowValue.includes && rowValue.includes(searchValue)) {
      //               isIncludedCnt++
      //             }
      //           }
      //         }
      //       }
      //       return searchPropsCnt === isIncludedCnt
      //     })
      //     this.pagination.total = result.length
      //     return result.slice(this.from, this.to)
      //   // } else {
      //   //   return this.tableData
      //   // }
      // },
      to () {
        let highBound = this.from + this.pagination.perPage
        // if (this.total < highBound) {
        //   highBound = this.total
        // }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        // this.pagination.total = this.tableData.length
        // return this.tableData.length
        return this.pagination.total
      }
    },
    methods: {
      ...commonMethods,
      resetFormData () {
        for( let key in this.formData) {
          if (typeof this.formData[key] === 'string') this.formData[key] = ''
          else if (typeof this.formData[key] === 'number') this.formData[key] = 0
          else if (typeof this.formData[key] === 'boolean') this.formData[key] = false
          else if (this.formData[key] instanceof Array) this.formData[key] = []
          else if (this.formData[key] instanceof Object) this.formData[key] = {}
        }
        this.formData.link_target = '_self'
        this.formData.link_target_app = '_self'
        this.formData.dp_type = 'ORIGINAL'
        this.formData.dp_status = 'N'
        this.formData.dp_interactive = 'N'
        this.formData.is_advertising = false
        this.formData.ad_channel = 'M'
        this.formData.model_idx = ''
        this.formData.app_img_size = IMG_SIZE_DEFAULT
        this.formData.app_img_interactive_size = IMG_SIZE_DEFAULT
      },
      getDisplayCateList () {
        this.displayCate = this.cloneData(this.getAllDisplayCateData.value)
        this.loadingCate = false
      },
      getDisplayList (displayCateIdx) {
        this.loading = true
        let params = {params: {}}
        let action = ''
        params.params.p = this.pagination.currentPage
        params.params.perPage = this.pagination.perPage
        if (this.searchDB.keyword) params.params.keyword = this.searchDB.keyword
        if (this.selectedDisplayCate.idx) params.params.section = this.selectedDisplayCate.idx

        // if (displayCateIdx) {
        //   params.params.section = displayCateIdx
        //   params.params.is_advertising = true
        //   action = baseAction + 'search/all'
        // } else {
          action = baseAction + 'advertising/all'
        // }
        this.axios.get(action, params).then((rs) => {
          this.tableData = rs.data.data
          this.tableDataOrigin = this.cloneData(this.tableData)
          if (this.pagination.total !== rs.data.total) this.pagination.total = rs.data.total
          this.loading = false
        })
      },
      setDisplayCateOptions () {
        this.displayCateOptions = this.cloneData(this.displayCate)
      },
      setDisplayCateSelectedItem (idx, path) {
        this.selectedDisplayCate.idx = idx
        this.selectedDisplayCate.path = path
        this.selectedDisplayCate.data = (this.getDisplayCateFlat).filter(row => {
          return path.indexOf(row.idx.toString()) > -1
        })

        let rtn = this.selectedDisplayCate.data.map(row => row.name).join(' / ')
        rtn = rtn ? rtn : '전체'
        if (this.selectedDisplayCate.pathName !== rtn) {
          this.selectedDisplayCate.pathName = ''
          setTimeout(function() {
            this.selectedDisplayCate.pathName = rtn
          }.bind(this), 100)
        }

        this.getDisplayList(idx)
      },
      setDisplayCateOpenedItem (idx, path) {
        if (idx) {
          this.openedDisplayCate.idx = idx
          this.openedDisplayCate.path = path
        }
      },
      menuClose () {
        const idx = this.openedDisplayCate.path[0]
        if (idx) this.$refs.menu.close(idx)
      },
      changeModel (val) {
        if (val) this.$refs.modal.isModalOpen = true
      },
      changeDisplayCateData (path) {
        if (path) {
          this.formData.section.idx = path[path.length - 1]
          this.formData.section.path = path
          this.formData.section.data = (this.getDisplayCateFlat).filter(row => {
            return path.indexOf(row.idx) > -1
          })
          let rtn = this.formData.section.data.map(row => row.name).join(' / ')
          if (this.formData.section.pathName !== rtn) {
            this.formData.section.pathName = ''
            setTimeout(function () {
              this.formData.section.pathName = rtn
            }.bind(this), 100)
          }
        }
      },
      changeIsAdvertising (row) {
        let params = {idx: row.idx, is_advertising: row.is_advertising};
        let action = baseAction + 'advertising/' + params.idx
        this.axios.put(action, params).then((rs) => {
          if (rs.data.status === 'success') {
            this.setDisplayList('put', rs.data.data[0])
          }
        }).catch( error => {
          console.log('failed', error)
        })
      },
      changeDpStatus (row) {
        let params = {idx: row.idx, dp_status: row.dp_status};
        let action = baseAction + 'dp_status/' + params.idx
        this.axios.put(action, params).then((rs) => {
          if (rs.data.status === 'success') {
            this.setDisplayList('put', rs.data.data[0])
          }
        }).catch( error => {
          console.log('failed', error)
        })
      },
      setDisplayList (method, row) {
        if(method === 'put') {
          for (let key in this.tableData) {
            if (this.tableData[key].idx === row.idx) {
              row.no = this.tableData[key].no
              Vue.set(this.tableData, key, row)
              this.tableDataOrigin = this.cloneData(this.tableData)
              break
            }
          }
        } else {
          if (this.selectedDisplayCate.idx) {
            this.tableData.push(row)
          } else {
            row.no = this.tableData.length + 1
            this.tableData.unshift(row)
          }
          this.tableDataOrigin = this.cloneData(this.tableData)
        }
      },
      openModal (row, doCopy=false) {
        const loading = Loading.service()
        this.resetFormData()
        this.doCopy = doCopy
        if(row) {
          this.btnText = this.doCopy ? '복사' : '수정'
          this.axios.get(baseAction + row.idx).then((rs) => {
            for ( var key in rs.data) {
              let val = rs.data[key]
              if (val!==null) {
                this.formData[key] = val
              }
            }

            setTimeout(function () {
              this.formDataRs = this.cloneData(this.formData)
            }.bind(this))

            loading.close()
          })
        } else {
          if (this.selectedDisplayCate.idx) {
            const path = this.selectedDisplayCate.path.map(row => Number(row))
            this.changeDisplayCateData(path)
          }
          this.formDataRs = this.cloneData(this.formData)
          this.btnText = '등록'
          loading.close()
        }
        this.isModalOpen = !this.isModalOpen
      },
      saveDisplayData () {
        const loading = Loading.service()
        let params = this.formData
        let method = 'post'
        let action = baseAction
        if (params.idx && !this.doCopy) {
          action += params.idx
          method = 'put'
        } else if (this.doCopy) {
          if (!confirm('복사를 진행하시겠습니까?')) return
          delete params.idx
        }

        params.is_advertising = true

        let formData = new FormData()
        for (let key in this.uploadData) {
          delete params[key]
          if (Object.keys(this.uploadData[key]).length > 0) {
            formData.append(key, this.uploadData[key].raw)
          } else {
            formData.append(key, null)
          }
        }
        for (let key in params) {
          if (typeof params[key] === 'object') {
            formData.append(key, JSON.stringify(params[key]))
          } else {
            formData.append(key, params[key])
          }
        }

        if (formData.dp_start_time === 'null') formData.dp_start_time = null
        if (formData.dp_end_time === 'null') formData.dp_end_time = null

        // console.log('------------------------------') // 폼데이터는 직접 console.log를 찍으면 값이 전송되지 않는다
        // for (var key of formData.entries()) {
        //   console.log(key[0] + ', ' + key[1]);
        // }

        this.axios.post(action, formData).then((rs) => {
          if (rs.data.status === 'success') {
            this.formDataRs = this.cloneData(this.formData)
            let data = rs.data.data[0]
            this.setDisplayList(method, data)
            this.uploadData = []
          } else {
            swal({
              title: 'ERROR',
              text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
              type: 'error',
              confirmButtonClass: 'btn btn-info btn-fill',
              buttonsStyling: false
            })
          }
          loading.close()
          this.isModalOpen = false

        }).catch( error => {
          loading.close()
          console.log('failed', error)
        })
      },

      handleCopy (index, row) {
        this.openModal(row, true)
      },
      handleEdit (index, row) {
        this.openModal(row)
      },
      handleDelete (index, row) {
        let $this = this
        swal({
          title: '삭제하시겠습니까?',
          // text: '삭제하면 관련',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          buttonsStyling: false
        }).then(function () {
          $this.loading = true
          $this.axios.delete(baseAction + row.idx).then((rs) => {
            $this.loading = false
            if (rs.data.status === 'success') {
              if ($this.selectedDisplayCate.idx) {
                $this.tableData.splice(index, 1)
              } else {
                const indexToDelete = $this.tableData.findIndex((tableRow) => tableRow.idx === row.idx)
                if (indexToDelete > -1) {
                  $this.tableData.splice(indexToDelete, 1)
                }
              }
              $this.tableDataOrigin = $this.cloneData($this.tableData)
            } else {
              swal({
                title: 'ERROR',
                text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
                type: 'error',
                confirmButtonClass: 'btn btn-info btn-fill',
                buttonsStyling: false
              })
            }
          }).catch( error => {
            $this.loading = false
          })
        }, function (dismiss) {

        })
      },

      selectedModelData (data) {
        this.formData.model_idx = data.idx
        this.formData.model_data = data
        this.$refs.modal.isModalOpen = false
      },

      eventCancel (event) {
        event.preventDefault()
        event.stopPropagation()
      },

      getNo (no) {
        const rtn = this.pagination.total - (no + (this.pagination.perPage * (this.pagination.currentPage-1)))
        return !isNaN(rtn) ? rtn : ''
      },
      setCurrentPage (p) {
        if (p !== this.pagination.currentPage) {
          this.pagination.currentPage = p
          this.getDisplayList()
        }
      }
    }
  }
</script>
<style scoped>
  .SWITCH {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  /deep/ .bg_fafafa {
    background-color: #FAFAFA !important;
  }

  /deep/ .upload-demo {
    overflow: hidden;
  }
  /deep/ .upload-demo .el-upload {
    display: block !important;
  }
  /deep/ .upload-demo .el-upload-list {
    margin-bottom: 3px !important;
  }
  /deep/ .upload-demo .el-upload-list__item:first-child {
    margin-top: 0 !important;
  }

  /deep/ .NO_BORDER_X,
  /deep/ .NO_BORDER_X input {
    border-left: 0;
    border-right: 0;
  }

  /deep/ .NO_BORDER_LEFT,
  /deep/ .NO_BORDER_LEFT input {
    border-left: 0;
  }

  /deep/ .NO_RADIUS,
  /deep/ .NO_RADIUS input,
  /deep/ .NO_RADIUS button {
    border-radius: 0 !important;
  }
  /deep/ .NO_RADIUS_LEFT,
  /deep/ .NO_RADIUS_LEFT input,
  /deep/ .NO_RADIUS_LEFT button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  /deep/ .NO_RADIUS_RIGHT,
  /deep/ .NO_RADIUS_RIGHT input,
  /deep/ .NO_RADIUS_RIGHT button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  /deep/ .DATE_PICKER .el-input__inner {
    padding-left: 40px !important;
  }

  .el-table th .cell {
    padding-right: 15px;
  }
  label {
    font-weight: bold !important;
  }

  .el-header {
    border-bottom: 1px solid #eee;
  }

  /deep/ .el-menu > .el-submenu {
    border-top: 1px solid #eee;
    /*border-bottom: 1px solid #eee;*/
  }
  /deep/ .el-menu .el-menu-item {
    padding-right: 19px;
  }
  /deep/ .el-menu .el-menu .el-submenu {
    /*background-color: #f2f2f2;*/
  }
  /deep/ .el-submenu__title, .el-menu-item {
    height: 35px;
    line-height: 35px;
  }

  .card-user .image { height: 114px; }
  .card-user .card-body { min-height: 140px; }

  /deep/ .avatar-uploader .el-upload-list {
    position: relative;
    z-index: 2;
  }
  /deep/ .avatar-uploader .el-upload-list__item {
    position: absolute;
    z-index: 0;
  }
  /deep/ .avatar-uploader .el-upload-list__item.is-success{
    z-index: 2;
  }

  /deep/ .el-table .cell {
    font-size: 12px !important;
  }

  /deep/ .modal-dialog {
    padding-top: 0 !important;
    width: 95% !important;
    max-width: 1919px !important;
    margin: 2.5rem auto !important;
  }
  /deep/ .modal-body {
    background-color: #f4f3ef;
    padding: 20px 30px !important;
  }
</style>
