import { editorKeys } from 'src/mixins/common_variables.js'

export default {
  htmlRemoveTags: ['script', 'style', 'base'],
  toolbarButtons: ['fullscreen', '|', 'undo', 'redo' , '|', 'fontSize' , '|', 'bold', 'italic', 'underline', 'strikeThrough', 'align', '|', 'insertImage', 'insertLink', '|', 'html'],
  toolbarButtonsNoSize: ['fullscreen', '|', 'undo', 'redo' , '|', 'bold', 'italic', 'underline', 'strikeThrough', '|', 'insertImage', 'insertLink', '|', 'html'],
  imageEditButtons: ['imageDisplay', 'imageReplace', 'imageAlign', 'imageInfo', 'imageRemove', 'imageSize', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove'],
  imageDefaultWidth: '100%',
  imageResize: true,
  keys: editorKeys,
  events: {
    'froalaEditor.initialized': function (e, editor) {
      editor.toolbar.hide()
    },
    'froalaEditor.click': function (e, editor, clickEvent) {
      if (!editor.fullscreen.isActive()) {
        editor.fullscreen.toggle()
        editor.toolbar.show()
      }
    },
    // 'froalaEditor.focus': function (e, editor) {
    //   if (!editor.fullscreen.isActive()) {
    //     editor.fullscreen.toggle()
    //     editor.toolbar.show()
    //   }
    // },
    'froalaEditor.commands.after': function (e, editor, cmd, param1) {
      if (cmd === 'fullscreen') {
        if (editor.fullscreen.isActive()) {
          editor.toolbar.show()
        } else {
          editor.toolbar.hide()
          if (editor.codeView.isActive()) editor.codeView.toggle()
        }
      }
    },
    'froalaEditor.image.beforeUpload': function (e, editor, images) {
      if (vm.formData) editor.opts.imageUploadParams.idx = vm.formData.idx
      else if (vm.card) editor.opts.imageUploadParams.idx = vm.card.idx

      //TODO 등록 시 에디버 이미지 업로드 막음
      if ( !(editor.opts.imageUploadParams.idx >= 1) ) {
        alert('신규등록에서는 에디터 이미지 업로드할 수 없습니다.\n수정화면에서 시도해 주세요')
        return false
      } else if (vm.doCopy) {
        alert('복사화면에서는 에디터 이미지를 업로드할 수 없습니다.\n수정화면이나 복사완료 이후에 시도해 주세요')
        return false
      }
    },
    'froalaEditor.image.removed': function (e, editor, $img) {
      if (vm.formData) editor.opts.imageUploadParams.idx = vm.formData.idx
      else if (vm.card) editor.opts.imageUploadParams.idx = vm.card.idx

      //TODO 등록 시 에디터 이미지 업로드 막음
      if ( !(editor.opts.imageUploadParams.idx >= 1) ) {
        alert('신규등록에서는 에디터 이미지 삭제할 수 없습니다.\n수정화면에서 시도해 주세요')
        return false
      }
    }
  },
}