<template>
  <div class="card">
    <div class="card-header">
      <h5 class="title pointer" @click="isBoxFolded=!isBoxFolded">
        연관콘텐츠정보
        <p-button type="neutral" size="sm" class="m-md-0">
          <i class="nc-icon" :class="isBoxFolded?'nc-minimal-down':'nc-minimal-up'"></i>
        </p-button>
      </h5>
    </div>
    <div class="card-body table-full-width pt-0" v-show="isBoxFolded" v-if="isOpen">
      <el-tabs v-model="tab" type="card">

        <el-tab-pane label="리뷰" name="review">

          <card class="text-left ml-4 mr-4 CARD">
            <div class="col-md-12">
              <div class="form-group">
                <label>영상</label>
                <div>
                  <el-table-draggable handle=".handle">
                    <el-table :data="hotel.related_review_videos"
                              cell-class-name="px-0 py-1 ml-0"
                              row-class-name="ml-0 pl-0"
                              :show-header="false"
                    >

                      <el-table-column>

                        <template slot-scope="props">

                          <div class="row">
                            <div class="col-md-12">
                              <el-select filterable
                                         placeholder="타이틀 검색"
                                         v-model="props.row.idx">
                                <el-option class="select-default" value=""></el-option>
                                <el-option
                                  class="select-info"
                                  v-for="option in getAllVideoDataFast"
                                  :key="option.idx"
                                  :label="option.title"
                                  :value="option.idx">
                                </el-option>
                              </el-select>
                            </div>
                          </div>

                        </template>
                      </el-table-column>

                      <el-table-column :width="30">
                        <template slot-scope="item">
                          <div class="handle" :class="isReSorted('related_review_videos') ? 'text-warning' : 'text-gray' ">
                            <i class="nc-icon nc-tile-56 mt-2"></i>
                          </div>
                        </template>
                      </el-table-column>

                    </el-table>
                  </el-table-draggable>
                </div>
                <label class="mt-md-3">글</label>
                <div v-if="tab === 'review'">
                  <el-table-draggable handle=".handle">
                    <el-table :data="hotel.related_review_posts"
                              cell-class-name="px-0 py-1 ml-0"
                              row-class-name="ml-0 pl-0"
                              :show-header="false"
                    >

                      <el-table-column>

                        <template slot-scope="props">

                          <div class="row">
                            <div class="col-md-4">
                              <el-select class="select"
                                         placeholder="카테고리 선택"
                                         @change="changePostCate(props.row)"
                                         v-model="props.row.post_cate.idx">
                                <el-option class="select-default" value=""></el-option>
                                <el-option
                                  class="select-default"
                                  v-for="cate in getPostCateOptions"
                                  :key="cate.idx"
                                  :label="cate.name"
                                  :value="cate.idx">
                                </el-option>
                              </el-select>
                            </div>

                            <div class="col-md-8 pl-0">
                              <el-select filterable
                                         remote
                                         placeholder="타이틀 검색"
                                         v-model="props.row.idx">
                                <el-option class="select-default" value=""></el-option>
                                <el-option
                                  class="select-info"
                                  v-for="option in queriedAllPostData(props.row.post_cate.idx, 'review')"
                                  :key="option.idx"
                                  :label="option.title"
                                  :value="option.idx">
                                </el-option>
                              </el-select>
                            </div>
                          </div>

                        </template>
                      </el-table-column>

                      <el-table-column :width="30">
                        <template slot-scope="item">
                          <div class="handle" :class="isReSorted('related_review_posts') ? 'text-warning' : 'text-gray' ">
                            <i class="nc-icon nc-tile-56 mt-2"></i>
                          </div>
                        </template>
                      </el-table-column>

                    </el-table>
                  </el-table-draggable>
                </div>

              </div>
            </div>
          </card>

        </el-tab-pane>

        <el-tab-pane label="호텔" name="hotel">

          <card class="text-left ml-4 mr-4 CARD">
            <div class="col-md-12">
              <div class="form-group">
                <label>연관호텔</label>
                <div v-if="tab === 'hotel'">
                  <el-table-draggable handle=".handle">
                    <el-table :data="hotel.related_hotels"
                              :show-header="false"
                              cell-class-name="px-0 py-1 ml-0"
                              row-class-name="ml-0 pl-0">
                      <el-table-column>

                        <template slot-scope="props">

                          <div class="row">
                            <div class="col-md-12">
                              <el-select filterable
                                         placeholder="호텔 검색"
                                         v-model="props.row.idx">
                                <el-option class="select-default" value=""></el-option>
                                <el-option
                                  class="select-info"
                                  v-for="option in getAllHotelDataFast"
                                  :key="option.idx"
                                  :label="option.name"
                                  :value="option.idx">
                                </el-option>
                              </el-select>
                            </div>
                          </div>

                        </template>
                      </el-table-column>

                      <el-table-column :width="30">
                        <template slot-scope="item">
                          <div class="handle" :class="isReSorted('related_hotels') ? 'text-warning' : 'text-gray' ">
                            <i class="nc-icon nc-tile-56 mt-2"></i>
                          </div>
                        </template>
                      </el-table-column>

                    </el-table>
                  </el-table-draggable>
                </div>

              </div>
            </div>
          </card>

        </el-tab-pane>

        <el-tab-pane label="콘텐츠" name="contents">

          <card class="text-left ml-4 mr-4 CARD">
            <div class="col-md-12">
              <div class="form-group">
                <label>에디터 추천 콘텐츠</label>
                <div v-if="tab === 'contents'">
                  <el-table-draggable handle=".handle">
                    <el-table :data="hotel.related_contents"
                              :show-header="false"
                              cell-class-name="px-0 py-1 ml-0"
                              row-class-name="ml-0 pl-0">
                      <el-table-column>

                        <template slot-scope="props">

                          <div class="row">
                            <div class="col-md-4">
                              <el-select class="select"
                                         placeholder="카테고리 선택"
                                         @change="changePostCate(props.row)"
                                         v-model="props.row.post_cate.idx">
                                <el-option class="select-default" value=""></el-option>
                                <el-option
                                  class="select-default"
                                  v-for="cate in getPostCateOptions"
                                  :key="cate.idx"
                                  :label="cate.name"
                                  :value="cate.idx">
                                </el-option>
                              </el-select>
                            </div>

                            <div class="col-md-8 pl-0">
                              <el-select filterable
                                         remote
                                         placeholder="타이틀 검색"
                                         v-model="props.row.idx">
                                <el-option class="select-default" value=""></el-option>
                                <el-option
                                  class="select-info"
                                  v-for="option in  queriedAllPostData(props.row.post_cate.idx, 'contents')"
                                  :key="option.idx"
                                  :label="option.title"
                                  :value="option.idx">
                                </el-option>
                              </el-select>
                            </div>
                          </div>

                        </template>
                      </el-table-column>

                      <el-table-column :width="30">
                        <template slot-scope="item">
                          <div class="handle" :class="isReSorted('related_contents') ? 'text-warning' : 'text-gray' ">
                            <i class="nc-icon nc-tile-56 mt-2"></i>
                          </div>
                        </template>
                      </el-table-column>

                    </el-table>
                  </el-table-draggable>

                </div>

              </div>
            </div>
          </card>

        </el-tab-pane>

        <el-tab-pane label="테마랭킹" name="theme_ranking">

          <card class="text-left ml-4 mr-4 CARD">
            <div class="col-md-12">
              <div class="form-group">
                <label>에디터 추천 콘텐츠</label>
                <div v-if="tab === 'theme_ranking'">
                  <el-table-draggable handle=".handle">
                    <el-table :data="hotel.theme_ranking_contents"
                              :show-header="false"
                              cell-class-name="px-0 py-1 ml-0"
                              row-class-name="ml-0 pl-0">
                      <el-table-column>

                        <template slot-scope="props">

                          <div class="row">
                            <div class="col-md-4">
                              <el-select class="select"
                                         placeholder="카테고리 선택"
                                         @change="changePostCate(props.row)"
                                         v-model="props.row.post_cate.idx">
                                <el-option class="select-default" value=""></el-option>
                                <el-option
                                  class="select-default"
                                  v-for="cate in getPostCateOptions"
                                  :key="cate.idx"
                                  :label="cate.name"
                                  :value="cate.idx">
                                </el-option>
                              </el-select>
                            </div>

                            <div class="col-md-8 pl-0">
                              <el-select filterable
                                         remote
                                         placeholder="타이틀 검색"
                                         v-model="props.row.idx">
                                <el-option class="select-default" value=""></el-option>
                                <el-option
                                  class="select-info"
                                  v-for="option in  queriedAllPostData(props.row.post_cate.idx, 'theme_ranking')"
                                  :key="option.idx"
                                  :label="option.title"
                                  :value="option.idx">
                                </el-option>
                              </el-select>
                            </div>
                          </div>

                        </template>
                      </el-table-column>

                      <el-table-column :width="30">
                        <template slot-scope="item">
                          <div class="handle" :class="isReSorted('theme_ranking_contents') ? 'text-warning' : 'text-gray' ">
                            <i class="nc-icon nc-tile-56 mt-2"></i>
                          </div>
                        </template>
                      </el-table-column>

                    </el-table>
                  </el-table-draggable>

                </div>

              </div>
            </div>
          </card>

        </el-tab-pane>

        <el-tab-pane label="패키지" name="package">

          <card class="text-left ml-4 mr-4 CARD">
            <div class="col-md-12">
              <div class="form-group">
                <label>패키지 콘텐츠</label>
                <div v-if="tab === 'package'">
                  <el-table-draggable handle=".handle">
                    <el-table :data="hotel.package_contents"
                              :show-header="false"
                              cell-class-name="px-0 py-1 ml-0"
                              row-class-name="ml-0 pl-0">
                      <el-table-column>

                        <template slot-scope="props">

                          <div class="row">
                            <div class="col-md-4">
                              <el-select class="select"
                                         placeholder="카테고리 선택"
                                         @change="changePostCate(props.row)"
                                         v-model="props.row.post_cate.idx">
                                <el-option class="select-default" value=""></el-option>
                                <el-option
                                  class="select-default"
                                  v-for="cate in getPostCateOptions"
                                  :key="cate.idx"
                                  :label="cate.name"
                                  :value="cate.idx">
                                </el-option>
                              </el-select>
                            </div>

                            <div class="col-md-8 pl-0">
                              <el-select filterable
                                         remote
                                         placeholder="타이틀 검색"
                                         v-model="props.row.idx">
                                <el-option class="select-default" value=""></el-option>
                                <el-option
                                  class="select-info"
                                  v-for="option in  queriedAllPostData(props.row.post_cate.idx, 'package')"
                                  :key="option.idx"
                                  :label="option.title"
                                  :value="option.idx">
                                </el-option>
                              </el-select>
                            </div>
                          </div>

                        </template>
                      </el-table-column>

                      <el-table-column :width="30">
                        <template slot-scope="item">
                          <div class="handle" :class="isReSorted('package_contents') ? 'text-warning' : 'text-gray' ">
                            <i class="nc-icon nc-tile-56 mt-2"></i>
                          </div>
                        </template>
                      </el-table-column>

                    </el-table>
                  </el-table-draggable>

                </div>

              </div>
            </div>
          </card>

        </el-tab-pane>

      </el-tabs>
    </div>

  </div>
</template>
<script>
  import {Table, TableColumn, Select, Option, Input, Tabs, TabPane} from 'element-ui'
  import ElTableDraggable from 'element-ui-el-table-draggable'
  import { mapGetters } from 'vuex'
  import commonComputed from 'src/mixins/common_computed'
  import commonMethods from 'src/mixins/common_methods'
  import forms from '../forms'
  export default {
    name: 'HotelCateForm',
    components: {
      ElTableDraggable,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      [Input.name]: Input,
      [Tabs.name]: Tabs,
      [TabPane.name]: TabPane
    },
    data () {
      return {
        hotel: forms,
        notice: '',
        tab: 'review',
        isBoxFolded: true,
        allPostOptions: [],
        isOpen: false
      }
    },
    created () {
      window.vm = this
    },
    computed: {
      ...commonComputed,
      ...mapGetters([
        'getAllHotelDataFast',
        'getAllPostDataFast',
        'getAllVideoDataFast',
        'getAllPostDataFastCnt',
        'getPostCateOptions'
      ])
    },
    methods: {
      ...commonMethods,
      changePostCate (item) {
        item.idx = ''
        item.title = ''
        item.post_img = {}
        item.tags = []
      },
      queriedAllPostData (post_cate_idx, targetTab) {
        return this.getAllPostDataFast
        if (this.getAllPostDataFastCnt > 0 && this.isOpen && targetTab === tab) {
          // return this.getAllPostDataFast
          let allPostData = this.cloneData(this.getAllPostDataFast)
          return !post_cate_idx
            ? allPostData
            : allPostData.filter((row) => row.post_cate === post_cate_idx)
        }
      },
      isReSorted (column) {
        const cur = this.hotel[column].map(row => row.idx).toString()
        const ori = this.hotel[`${column}_ori`].map(row => row.idx).toString()
        return cur && cur !== ori
      }
    }
  }
</script>
<style scoped>
  .required:before {
    content: "●";
    color: red;
    display: inline-block;
    margin: 0 5px 0 0;
  }
  label {
    font-weight: bold !important;
  }
  .BUTTON {
    position: absolute;
    top: 5px;
    right: 15px;
  }
  .CARD {
    border: 1px solid #eee;
  }
  .CARD_CLOSE_BTN {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
  }
  .MAIN_OPTION {
    color: #555;
  }
  .SUB_OPTION {
    color: #999;
  }
  .FULL_SCREEN {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vw;
    z-index: 100;
  }

  /deep/ .el-tabs__nav {
    transform: translateX(20px) !important;
  }

  /deep/ .el-table__row td:first-child .cell {
    padding-left: 0 !important;
  }
</style>
