<template>
  <div>
    <div class="card card-user">
      <div class="image">
        <img src="static/img/blank.gif" alt="...">
      </div>
      <div class="card-body">
        <div class="author">
          <el-upload
            id="post_img"
            ref="post_img"
            class="avatar-uploader"
            action="about:blank"
            :file-list="getFileList(post.post_img)"
            :on-preview="handlePreview"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :auto-upload="false"
            :limit="1"
            list-type="picture-card">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>
      <div class="card-body mt-3">
        <div class="author">
          <el-upload
            id="post_seo_img"
            ref="post_seo_img"
            class="avatar-uploader"
            action="about:blank"
            :file-list="getFileList(post.post_seo_img)"
            :on-preview="handlePreview"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :auto-upload="false"
            :limit="1"
            list-type="picture-card">
            <i class="el-icon-plus"><br>SEO</i>
          </el-upload>
        </div>
      </div>
      <div class="card-body mt-3">
        <div class="author">
          <el-upload
            id="post_top_img"
            ref="post_top_img"
            class="avatar-uploader"
            action="about:blank"
            :file-list="getFileList(post.post_top_img)"
            :on-preview="handlePreview"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :auto-upload="false"
            :limit="1"
            list-type="picture-card">
            <i class="el-icon-plus"><br>TOP</i>
          </el-upload>
        </div>
      </div>

      <el-dialog :visible.sync="dialogVisible"
                 :modal-append-to-body="false">
        <img :src="dialogImageUrl" alt="">
      </el-dialog>

    </div>

    <div class="card">
      <div class="card-body">
        <div class="text-right">
          <label class="mr-2">공개설정</label>
          <p-switch v-model="post.is_visible" type="danger" on-text="ON" off-text="OFF"></p-switch>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Modal} from 'src/components/UIComponents'
  import {Upload, Dialog, Progress} from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import commonMethods from 'src/mixins/common_methods'
  import forms from '../forms'
  export default {
    name: 'PostPreview',
    components: {
      Modal, PSwitch,
      [Upload.name]: Upload,
      [Dialog.name]: Dialog,
      [Progress.name]: Progress
    },
    data () {
      return {
        post: forms,
        uploadData: [],
        dialogImageUrl: '',
        dialogVisible: false,
      }
    },
    methods: {
      ...commonMethods,
    }
  }
</script>
<style scoped>
  label {
    font-weight: bold !important;
  }

  .card-user .image { height: 114px; }
  .card-user .card-body { min-height: 140px; }

  /deep/ .avatar-uploader .el-upload-list {
    position: relative;
    z-index: 2;
  }
  /deep/  .avatar-uploader .el-upload-list__item {
    position: absolute;
    z-index: 0;
  }
  /deep/  .avatar-uploader .el-upload-list__item.is-success{
    z-index: 2;
  }
</style>
