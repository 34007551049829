<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="login">
                <card type="login">
                  <h3 slot="header" class="header text-center">Login</h3>

                  <fg-input v-model="form.email" addon-left-icon="nc-icon nc-single-02"
                            placeholder="Email..."></fg-input>

                  <fg-input v-model="form.password" addon-left-icon="nc-icon nc-key-25" placeholder="Password"
                            type="password"></fg-input>
                  <br>

                  <p-checkbox v-model="form.check">
                    Remember Email
                  </p-checkbox>

                  <p-button native-type="submit" slot="footer" type="warning" round block class="mb-3">Get started</p-button>
                </card>
              </form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(static/img/background/background-2.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Checkbox, Button } from 'src/components/UIComponents';
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      login() {
        // handle login here
        let formData = new FormData()
        formData.append('email', this.form.email)
        formData.append('password', this.form.password)
        this.axios.post('member/login', formData).then((rs) => {
          this.$localStorage.set('accessToken', rs.data.api_token)
          this.$localStorage.set('accessMember', JSON.stringify(rs.data))
          // console.log(this.$localStorage.get('accessMember'))
          this.$cookies.set("rememberEmail", (this.form.check ? this.form.email : ''), "30d")
          this.$cookies.set("rememberCheck", this.form.check, "30d")
          this.axios.defaults.headers.common['Authorization'] = `Bearer ${rs.data.api_token}`
          this.$store.commit('SET_MY_MEMBER_AUTH_DATA', [])
          this.$router.push('/')
          console.log('login')
        }).catch(() => {
          alert('로그인 정보가 맞지 않습니다. 로그인 정보를 확인해주세요.')
        })
      }
    },
    data() {
      return {
        form: {
          email: this.$cookies.get('rememberEmail'),
          password: '',
          check: JSON.parse(this.$cookies.get('rememberCheck'))
        }
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style scoped>
  .full-page > .content {
    padding-top: 8vh;
  }
</style>
