import axios from 'axios'

class CancelToken {
  constructor(initialValue) {
    this.source = initialValue;
  }
  getSource() {
    return this.source;
  }
  setSource(value) {
    this.source = value;
  }
  cancel(text) {
    this.source.cancel(text);
  }
}
export const cancelSource = new CancelToken(axios.CancelToken.source());