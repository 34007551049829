import * as types from './mutation_types'
import axios from 'axios'
import {cancelSource} from "../mixins/cancel_tocken"

const otaAxios = axios.create({baseURL: ''})
otaAxios.interceptors.response.use(
  function (rs) {
    return rs
  },
  function (err) {
    if (301 === err.response.status) {
      alert("잡았다")
    } else {
      return Promise.reject(err)
    }
  }
)


export default {
  setHotelForm: (context, payload) => {
    context.commit(types.SET_HOTEL_FORM, payload)
  },
  callAllHotelDataFast: (context) => {
    if (context.getters.getAllHotelDataFastCnt < 1) {
      vm.$store.dispatch('loadAllHotelDataFast')
    }
  },
  loadAllHotelDataFast: (context) => {
    const params = {params: {cate: ''}}
    axios.get('/hotels/all/fast', params).then(rs => {
      context.commit(types.SET_ALL_HOTEL_DATA_FAST, rs.data)
    })
  },
  callAllHashTagData: (context) => {
    if (context.getters.getAllHashTagDataCnt < 1) {
      return vm.$store.dispatch('loadAllHashTagData')
    }
  },
  loadAllHashTagData: (context) => {
    return axios.get('hash_tags/all').then(rs => {
      context.commit(types.SET_ALL_HASH_TAG_DATA, rs.data)
    })
  },
  callAllHotelBrandData: (context) => {
    if (context.getters.getAllHotelBrandDataCnt < 1) {
      return vm.$store.dispatch('loadAllHotelBrandData')
    }
  },
  loadAllHotelBrandData: (context) => {
    return vm.axios.get('hotel_brands/all').then(rs => {
      context.commit(types.SET_ALL_HOTEL_BRAND_DATA, rs.data)
    })
  },
  callAllHotelRegionData: (context) => {
    if (context.getters.getAllHotelRegionDataCnt < 1) {
      return vm.$store.dispatch('loadAllHotelRegionData')
    }
  },
  loadAllHotelRegionData: (context) => {
    return vm.axios.get('hotel_regions/all').then(rs => {
      context.commit(types.SET_ALL_HOTEL_REGION_DATA, rs.data)
    })
  },
  callAllHotelRegionDepthData: (context) => {
    if (context.getters.getAllHotelRegionDataCnt < 1) {
      return vm.$store.dispatch('loadAllHotelRegionDepthData')
    }
  },
  loadAllHotelRegionDepthData: (context) => {
    return vm.axios.get('hotel_regions/depth').then(rs => {
      context.commit(types.SET_ALL_HOTEL_REGION_DEPTH_DATA, rs.data)
    })
  },
  callAllAirlineCorpData: (context) => {
    if (context.getters.getAllAirlineCorpDataCnt < 1) {
      return vm.$store.dispatch('loadAllAirlineCorpData')
    }
  },
  loadAllAirlineCorpData: (context) => {
    return axios.get('airline_corps/all').then(rs => {
      context.commit(types.SET_ALL_AIRLINE_CORP_DATA, rs.data)
    })
  },
  callAllPostDataFast: (context) => {
    if (context.getters.getAllPostDataFastCnt < 1) {
      vm.$store.dispatch('loadAllPostDataFast')
    }
  },
  loadAllPostDataFast: (context) => {
    axios.get('/posts/all/fast').then(rs => {
      context.commit(types.SET_ALL_POST_DATA_FAST, rs.data)
    })
  },
  callAllVideoDataFast: (context) => {
    if (context.getters.getAllVideoDataFastCnt < 1) {
      return vm.$store.dispatch('loadAllVideoDataFast')
    }
  },
  loadAllVideoDataFast: (context) => {
    return axios.get('/videos/all/fast').then(rs => {
      context.commit(types.SET_ALL_VIDEO_DATA_FAST, rs.data)
    })
  },
  callAllPostCateData: (context) => {
    if (context.getters.getAllPostCateDataCnt < 1) {
      return vm.$store.dispatch('loadAllPostCateData')
    }
  },
  loadAllPostCateData: (context) => {
    return axios.get('/post_cates/all').then(rs => {
      context.commit(types.SET_ALL_POST_CATE_DATA, rs.data)
    })
  },
  callAllDisplayCateData: (context) => {
    if (context.getters.getAllDisplayCateDataCnt < 1) {
      return vm.$store.dispatch('loadAllDisplayCateData')
    }
  },
  loadAllDisplayCateData: (context) => {
    return axios.get('/display_cates').then(rs => {
      context.commit(types.SET_ALL_DISPLAY_CATE_DATA, rs.data)
    })
  },
  callAllMemberData: (context) => {
    if (context.getters.getAllMemberDataCnt < 1) {
      return vm.$store.dispatch('loadAllMemberData')
    }
  },
  loadAllMemberData: (context) => {
    return axios.get('/members/all').then(rs => {
      context.commit(types.SET_ALL_MEMBER_DATA, rs.data)
    })
  },
  callAllMemberClassData: (context) => {
    if (context.getters.getAllMemberClassDataCnt < 1) {
      return vm.$store.dispatch('loadAllMemberClassData')
    }
  },
  loadAllMemberClassData: (context) => {
    return axios.get('/member_classes/all').then(rs => {
      context.commit(types.SET_ALL_MEMBER_CLASS_DATA, rs.data)
    })
  },
  callAllMemberAuthData: (context) => {
    if (context.getters.getAllMemberAuthDataCnt < 1) {
      return vm.$store.dispatch('loadAllMemberAuthData')
    }
  },
  loadAllMemberAuthData: (context) => {
    return axios.get('/member_auth/all').then(rs => {
      context.commit(types.SET_ALL_MEMBER_AUTH_DATA, rs.data)
    })
  },
  callSeoMetaDataDefault: (context) => {
    if (context.getters.getSeoMetaDataDefaultCnt < 1) {
      axios.get('/seo_metas').then(rs => {
        context.commit(types.SET_SEO_META_DATA_DEFAULT, rs.data)
      })
    }
  },
  callCalendarData: (context) => {
    if (context.getters.getCalendarDataCnt < 1) {
      return axios.get('/charts/calendar').then(rs => {
        context.commit(types.SET_CALENDAR_DATA, rs.data)
      })
    }
  },
  callMyMemberAuthData: (context, payload) => {
    if (context.getters.getMyMemberAuthDataCnt < 1) {
      const params = { params: {
        classIdx: payload
      }}
      return axios.get('/member_auth', params).then(rs => {
        context.commit(types.SET_MY_MEMBER_AUTH_DATA, rs.data)
      })
    }
  },
  callOtaSingleHotel (context, payload) {
    context.commit(types.SET_OTA_SINGLE_HOTEL_DATA, {})
    context.commit(types.SET_OTA_SINGLE_HOTEL_DATAFEEDS, {})
    if (!context.getters.getOtaSingleHotelData) {
      vm.$store.dispatch('loadOtaSingleHotel', payload)
      vm.$store.dispatch('loadOtaSingleHotelDatafeeds', payload)
    }
  },
  async loadOtaSingleHotel (context, payload) {
    // get single hotel data
    const today = vm.$moment(new Date())
    const params = {
      params: {
        apiKey: context.state.otaApiKey,
        hotel: payload.hotelEntityKey,
        languageCode: payload.languageCode,
        sessionId: payload.sessionId,
        // sessionId: 11111,//today.format('YYYY-MM-DD') + Math.random(),
        responseOptions: 'imageSprite,images,place,providers,transliteratedName,starRating,featureSummary,features,results',
        rooms: 1,
        onlyIfComplete: true,
        checkin: today.format('YYYY-MM-DD'),
        checkout: today.add(1, 'days').format('YYYY-MM-DD')
      },
      cancelToken: cancelSource.getSource().token
    }

    try {
      return await otaAxios.get('/ota/hotel', params).then(rs => {
        if (rs.status === 301) {
          alert(301)
        } else if (rs.status === 202) {
          vm.$store.dispatch('loadOtaSingleHotel', payload)
        } else if (rs.status === 200) {
          context.commit(types.SET_OTA_SINGLE_HOTEL_DATA, rs)
        } else {
          context.commit(types.SET_OTA_SINGLE_HOTEL_DATA, rs)
        }
      })
    } catch (e) {
      console.log(e)
      alert('OTA API에서 데이터를 가져올 수 없습니다. 잠시 후 다시 시도해주세요.')
      context.commit(types.SET_OTA_SINGLE_HOTEL_DATA, [{}])
    }
  },
  cancelRequestOtaSingleHotel () {
    cancelSource.cancel('CANCELED OTA REQUEST')
    cancelSource.setSource(axios.CancelToken.source())
  },
  async loadOtaSingleHotelDatafeeds (context, payload) {
    const params = {
      params: {
        id: context.state.otaDatafeedsID,
        PrivateDataKey: context.state.otaDatafeedsPrivateDataKey,
        LanguageCode: payload.languageCode,
        HotelFileName: payload.hotelEntityKey.replace('hotel:', ''),
        type: 'HOTEL',
        OutputType: 'JSON',
        PageNo: 1,
        PageSize: 1
      },
      cancelToken: cancelSource.getSource().token
    }

    try {
      const rtn = await otaAxios.get('/datafeeds', params).then(rs => {
        const obj = {}
        rs.data[0].Columns.map(row => {
          obj[row.Name] = row.DisplayValue
          if (context.getters.getAllOtaCodeDataCnt > 0) { // CODE 예외처리
            if (row.Name === 'PropertyTypeID') obj['PropertyTypeName'] = context.getters.getOtaCodePinpointName({
              type: 'PropertyType',
              id: row.DisplayValue
            })
            if (row.Name === 'Themes') obj['ThemesName'] = context.getters.getOtaCodePinpointName({
              type: 'Theme',
              id: row.DisplayValue
            })
          }
        })
        context.commit(types.SET_OTA_SINGLE_HOTEL_DATAFEEDS, obj)
      })

      return rtn
    } catch (e) {

    }
  },
  callAllOtaCodeData: (context) => {
    if (context.getters.getAllOtaCodeDataCnt < 1) {
      return axios.get('/ota_codes').then(rs => {
        const obj = {}
        rs.data.map(row => {
          if (!obj[row.type]) obj[row.type] = []
          obj[row.type].push({id: row.id, name: row.name, code: row.code})
        })
        context.commit(types.SET_ALL_OTA_CODE_DATA, obj)
      })
    }
  },
  callAllUserData: (context) => {
    if (context.getters.getAllUserDataCnt < 1) {
      return vm.$store.dispatch('loadAllUserData')
    }
  },
  loadAllUserData: (context) => {
    return axios.get('/users/all').then(rs => {
      context.commit(types.SET_ALL_USER_DATA, rs.data)
    })
  },
}
