<template>
  <div class="USER_MANAGER">

    <!-- 헤더 및 컨트롤 -->
    <div class="col-md-12">
      <h5 class="title">회원 관리</h5>
      <p class="category">
        <!--We combine <a href="http://element.eleme.io/#/en-US/component/quickstart" target="_blank" rel="noopener">Element-UI</a>-->
        <!--table functionalities together with a custom pagination component-->
        <!--which should provide a very good starting point to integrate tables in your application.-->
        <!--Check out more functionalities at <a href="http://element.eleme.io/#/en-US/component/table" target="_blank" rel="noopener">Element-UI table documentation</a>.-->
        회원 관리입니다.
      </p>
    </div>

    <!-- 브랜드 리스트 -->
    <div class="col-md-12 card LIST">
      <div class="card-body row table-full-width">
        <div class="col-md-9">
          <fieldset>
            <div class="form-group">
              <el-select
                class="select-info small"
                v-model="searchDB.is_visible"
                @change="getUserList"
                placeholder="회원상태">
                <el-option value=""></el-option>
                <el-option label="회원" :value="1" />
                <el-option label="탈퇴" :value="0" />
              </el-select>

              <el-select
                class="select-info small"
                v-model="searchDB.os"
                @change="getUserList"
                placeholder="운영체제">
                <el-option value=""></el-option>
                <el-option label="Android" value="android" />
                <el-option label="iOS" value="ios" />
              </el-select>

              <el-select
                class="select-info small"
                v-model="searchDB.join_path"
                @change="getUserList"
                placeholder="가입경로">
                <el-option value=""></el-option>
                <el-option label="카카오" value="kakao" />
                <el-option label="네이버" value="naver" />
                <el-option label="직접가입" value="direct" />
              </el-select>

            </div>
          </fieldset>
        </div>
        <div class="col-sm-3 pr-md-0">
          <div class="col-sm-12">
            <fg-input class="input-sm"
                      placeholder="검색 (회원명, 이메일, UID)"
                      v-model="searchDB.keyword"
                      @keyup.enter="getPostList"
                      addon-left-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mt-md-1">
          <el-table class="table-striped"
                    header-row-class-name="text-default"
                    :data="tableData"
                    v-loading="loading"
                    border
                    style="width: 100%">

            <el-table-column type="index"
                             label="#"
                             width="80" />
            <el-table-column prop="uid"
                             :min-width="100"
                             label="UID" />
            <el-table-column label="사진"
                             :min-width="100">
              <template slot-scope="props">
                <img v-if="isFileExist(props.row.prof_img)" :src="props.row.prof_img.url" class="avatar mb-0" />
              </template>
            </el-table-column>
            <el-table-column :min-width="120"
                             label="이름">
              <template slot-scope="props">
                <div>{{props.row.name}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="join_path_txt"
                             :min-width="100"
                             label="가입경로" />
            <el-table-column prop="email"
                             :min-width="250"
                             label="이메일" />
            <el-table-column prop="phone"
                             :min-width="150"
                             label="전화번호" />
            <el-table-column prop="add_info"
                             :min-width="210"
                             label="추가입력" />
            <el-table-column prop="wish_list_cnt"
                             :min-width="80"
                             label="찜" />
            <el-table-column prop="os_txt"
                             :min-width="110"
                             label="운영체제" />
            <el-table-column
              :min-width="80"
              fixed="right"
              class-name="td-actions text-center"
              label="상태">
              <template slot-scope="props">
                <span>{{props.row.is_visible ? '회원' : '탈퇴'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="getCheckThisPageModify || getCheckThisPageDelete"
              :min-width="100"
              align="center"
              fixed="right"
              class-name="action-buttons td-actions"
              label="관리">
              <template slot-scope="props">
                <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)" :disabled="!getCheckThisPageModify">
                  <i class="fa fa-edit"></i>
                </p-button>
                <!--<p-button type="danger" size="sm" icon @click="handleDelete(props.$index, props.row)" :disabled="props.row.idx < 2 || !getCheckThisPageDelete">-->
                  <!--<i class="fa fa-times"></i>-->
                <!--</p-button>-->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="card-body row table-full-width">
        <div class="col-sm-3">
          <div class="">
            <el-select
              class="select"
              v-model="pagination.perPage"
              placeholder="Per page">
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="`${item} rows`"
                :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="pagination-info mt-1">
            <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
          </div>
        </div>
        <div class="col-sm-6 text-center">
          <!--<p-pagination v-model="pagination.currentPage"-->
          <!--:per-page="pagination.perPage"-->
          <!--:total="pagination.total">-->
          <!--</p-pagination>-->
          <el-pagination background
                         :value="pagination.currentPage"
                         :page-size="pagination.perPage"
                         :total="pagination.total"
                         layout="pager"
                         @current-change="setCurrentPage"
                         :disabled="loading">
          </el-pagination>
        </div>
        <div class="col-sm-3">
          <p class="pull-right">
            <p-button class="pull-right" type="info" round icon :disabled="total < 1" @click="downloadExcelFile(getFileName, getTableResult, getTableColumns, 'table_list')">
              <i class="fa fa-download"></i>
            </p-button>
          </p>
          <p class="pull-right mr-3">
            <p-button type="info" round @click.native="toggleModal(); loading=false" v-if="getCheckThisPageCreate">회원 등록 (임시)</p-button>
          </p>
        </div>
      </div>

      <!-- 폼 모달 -->
      <modal :show.sync="isModalOpen"
             footer-classes="justify-content-center">

        <h5 slot="header" class="modal-title text-left">사용자 {{btnText}}</h5>
        <template class="bg-light">
          <div class="row ">
            <div class="col-sm-7 col-md-8 col-lg-9">

              <div class="card">
                <div class="card-body">

                  <form>
                    <div class="row">
                      <div class="col-md-4" v-show="modify">
                        <fg-input type="idx"
                                  label="UID (회원ID)"
                                  placeholder="UID"
                                  :readonly="true"
                                  v-model="formData.idx">
                        </fg-input>
                      </div>
                      <div :class="`col-md-${modify ? 8 : 12}`">
                        <fg-input type="email"
                                  label="이메일"
                                  placeholder="이메일 입력"
                                  class="required"
                                  v-model="formData.email">
                        </fg-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5">
                        <fg-input type="text"
                                  label="이름"
                                  placeholder="이름 입력"
                                  class="required"
                                  v-model="formData.name">
                        </fg-input>
                      </div>
                      <div class="col-md-3 ">
                        <label>생년월일</label>
                        <div>
                          <el-date-picker
                            v-model="formData.birthday"
                            type="date"
                            unlink-panels
                            :picker-options="pickerOptions"
                            value-format="yyyy-MM-dd">
                          </el-date-picker>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <fg-input type="text"
                                  label="연령대"
                                  :readonly="true"
                                  :value="getAgeGroup">
                        </fg-input>
                      </div>
                      <div class="col-md-2">
                        <label>성별</label>
                        <div class="">
                          <p-radio class="float-left mr-3 mb-0" label="M" v-model="formData.gender">남성</p-radio>
                          <p-radio class="float-left mr-3 mb-0" label="F" v-model="formData.gender">여성</p-radio>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5">
                        <fg-input type="text"
                                  label="전화번호"
                                  placeholder="전화번호 입력"
                                  v-model="formData.phone">
                        </fg-input>
                      </div>
                      <div class="col-md-7">
                        <fg-input type="text"
                                  label="비밀번호"
                                  placeholder="변경이 필요할 때만 [영문 + 숫자]조합으로 8자리 이상으로 입력"
                                  v-model.trim="formData.password">
                        </fg-input>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="card">
                <div class="card-body">

                  <form>
                    <div class="row">
                      <div class="col-md-6">
                        <fg-input type="text"
                                  label="운영체제 (버전)"
                                  :readonly="true"
                                  :value="`${formData.os} (${formData.os_ver})`">
                        </fg-input>
                      </div>
                      <div class="col-md-6">
                        <fg-input type="text"
                                  label="가입 경로"
                                  :readonly="true"
                                  :value="formData.join_path_txt">
                        </fg-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <fg-input type="text"
                                  label="디바이스"
                                  :readonly="true"
                                  :value="formData.device">
                        </fg-input>
                      </div>
                      <div class="col-md-6">
                        <fg-input type="text"
                                  label="마케팅 정보 메일, SMS, 푸시 수신동의 여부"
                                  :readonly="true"
                                  :value="`${formData.marketing_consent ? '동의 (' + formData.marketing_consent_at + ')' : '거부'}`">
                        </fg-input>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="card">
                <div class="card-body">

                  <form>
                    <div class="row">
                      <div class="col-md-12">
                        <label>찜 리스트</label>
                        <div readonly class="px-2 wish-wrap rounded">
                          <div class="el-card p-2 my-2 mx-0 is-never-shadow bg-white rounded" v-for="hotel in getWishHotels">
                            <div class="row">
                              <div class="col col-md-2"><img :src="hotel.main_img" width="auto" height="50px" /></div>
                              <div class="col col-md-6 pt-1 text-left">
                                <b>{{hotel.name}}</b><br>
                                {{hotel.name_eng}}
                              </div>
                              <div class="col col-md-4 text-right pt-3 pr-4">
                                {{hotel.ota_place_full_name}}
                              </div>
                            </div>
                          </div>
                          <div class="el-card my-2 mx-0 row rounded text-center" v-if="getWishHotels.length < 1">
                            <div class="col">no data</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                </div>
              </div>

            </div>
            <div class="col-sm-5 col-md-4 col-lg-3">

              <div class="card card-user">
                <div class="image">
                  <img src="static/img/blank.gif" alt="...">
                </div>
                <div class="card-body">
                  <div class="author">
                    <el-upload
                      id="prof_img"
                      ref="prof_img"
                      class="avatar-uploader"
                      action="about:blank"
                      accept="image/png, image/jpeg, image/gif"
                      :file-list="getFileList(formData.prof_img)"
                      :on-preview="handlePreview"
                      :on-change="handleChange"
                      :on-remove="handleRemove"
                      :auto-upload="false"
                      :limit="1"
                      list-type="picture-card">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="pull-right">
                        <label class="pr-2">회원상태</label>
                        <p-switch v-model="formData.is_visible" type="danger" on-text="회원" off-text="탈퇴"></p-switch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </template>
        <div slot="footer" class="justify-content-center">
          <p-button type="danger" size="lg" style="position: absolute; left: 30px" wide round @click.native="removeUser">강제삭제</p-button>

          <p-button type="info" size="lg" wide round @click.native="saveUserData">{{btnText}}</p-button>
        </div>
      </modal>

      <!-- 이미지뷰어 다이얼로그 -->
      <el-dialog :visible.sync="dialogVisible"
                 :modal-append-to-body="false">
        <img :src="dialogImageUrl" alt="">
      </el-dialog>

    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option, Loading, Upload, Dialog, Switch, DatePicker, Pagination, MessageBox} from 'element-ui'
  import ElTableDraggable from 'element-ui-el-table-draggable'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import Modal from 'src/components/UIComponents/Modal'
  import swal from 'sweetalert2'
  import { mapGetters } from 'vuex'
  import commonMethods from 'src/mixins/common_methods'
  const baseAction = '/users/'
  export default{
    components: {
      PSwitch, Modal, ElTableDraggable,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      [Upload.name]: Upload,
      [Dialog.name]: Dialog,
      [Switch.name]: Switch,
      [DatePicker.name]: DatePicker,
      [Pagination.name]: Pagination
    },
    data () {
      return {
        formData: {
          email: '',
          password: '',
          name: '',
          gender: '',
          birthday: '',
          phone: '',
          device: '',
          join_path: '',
          wish_hotels: '',
          wish_hotels_data: '',
          prof_img: {},
          is_visible: true,
        },
        searchDB: {
          'is_visible': '',
          'os': '',
          'join_path': '',
        },
        pagination: {
          perPage: 30,
          currentPage: 1,
          perPageOptions: [10, 30, 50, 100, 400, 1000],
          total: 0
        },
        keyword: '',
        uploadData: [],
        tableData: [{},{},{},{},{}],
        tableDataOrigin: [{},{},{},{},{}],
        loading: true,
        isModalOpen: false,
        dialogImageUrl: '',
        dialogVisible: false,
        modify: false,
        btnText: '등록',
        pickerOptions: {
          shortcuts: [{
            text: 'Today',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
            {
              text: 'Yesterday',
              onClick (picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
              }
            },
            {
              text: 'A week ago',
              onClick (picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', date)
              }
            }]
        },
      }
    },
    watch: {
      isModalOpen (val) {
        if (!val) {
          // forms 초기화
          for( let key in this.formData) {
            if (typeof this.formData[key] === 'string') this.formData[key] = ''
            else if (typeof this.formData[key] === 'number') this.formData[key] = 0
            else if (typeof this.formData[key] === 'boolean') this.formData[key] = false
            else if (this.formData[key] instanceof Array) this.formData[key] = []
            else if (this.formData[key] instanceof Object) this.formData[key] = {}
          }
          this.password2 = ''
        }
      }
    },
    mounted () {
      this.$store.dispatch('callAllUserData').then(() => {
        this.getUserList()
      })
    },
    computed: {
      ...mapGetters([
        'getAllUserData',
        'getCheckThisPageCreate',
        'getCheckThisPageRead',
        'getCheckThisPageModify',
        'getCheckThisPageDelete'
      ]),
      to () {
        let highBound = this.from + this.pagination.perPage
        // if (this.total < highBound) {
        //   highBound = this.total
        // }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        // this.pagination.total = this.tableData.length
        // return this.tableData.length
        return this.pagination.total
      },
      getAgeGroup () {
        const age = this.$moment().diff(this.formData.birthday, 'year')
        return this.formData.birthday ? Math.floor(age/10) * 10 : ''
      },
      getWishHotels () {
        return this.formData.wish_hotels_data
      }
    },
    methods: {
      ...commonMethods,
      getUserList () {
        this.tableData = this.getAllUserData
        this.tableDataOrigin = this.cloneData(this.tableData)
        this.loading = false
      },
      changeIsVisibled (row) {
        const params = {idx: row.idx, is_visible: row.is_visible};
        const action = baseAction + 'visible/' + params.idx
        this.axios.put(action, params).then((rs) => {
          if (rs.data.status === 'success') {
            this.setUserList('put', rs.data.data[0])
          }
        }).catch( error => {
          console.log('failed', error)
        })
      },
      setUserList (method, row) {
        if(method === 'put') {
          for (let key in this.tableData) {
            if (this.tableData[key].idx === row.idx) {
              this.tableData.splice(key, 1, row)
              break
            }
          }
        } else {
          this.tableData.unshift(row)
        }
      },
      toggleModal (row) {
        const loading = Loading.service()
        if(row) {
          this.modify = true
          this.btnText = '수정'
          this.axios.get(baseAction + row.idx).then((rs) => {
            for( var key in rs.data) {
              let val = rs.data[key]
              if(val!==null) {
                this.formData[key] = val
              }
            }
            loading.close()
          })
        } else {
          this.modify = false
          this.btnText = '등록'
          loading.close()
        }
        this.isModalOpen = !this.isModalOpen
      },
      saveUserData () {
        // const loading = Loading.service()
        let params = this.formData
        let method = 'post'
        let action = baseAction

        if (params.password) {
          if (params.password.length < 8) {
            return alert('비밀번호는 8자리 이상이어야 합니다.')
          } else if (!/[0-9]/.test(params.password)||!/[a-zA-Z]/.test(params.password)){
            return alert("비밀번호는 [영문 + 숫자]로 구성하여야 합니다.")
          }
        }

        if (params.idx) {
          action += params.idx
          method = 'put'
        }

        if (!params.password) delete params['password']

        let formData = new FormData()
        for (let key in this.uploadData) {
          delete params[key]
          if (Object.keys(this.uploadData[key]).length > 0) {
            formData.append(key, this.uploadData[key].raw)
          } else {
            formData.append(key, null)
          }
        }
        for (let key in params) {
          if (typeof params[key] === 'object') {
            formData.append(key, JSON.stringify(params[key]))
          } else {
            formData.append(key, params[key])
          }
        }

        this.axios.post(action, formData).then((rs) => {
          if (rs.data.status === 'success') {
            let data = rs.data.data[0]
            if (method === 'put') {
              for (let key in this.tableData) {
                if (this.tableData[key].idx === data.idx) {
                  Vue.set(this.tableData, key, data)
                  break
                }
              }
            } else {
              this.tableData.push(data)
              this.tableDataOrigin = this.cloneData(this.tableData)
            }
            this.$store.dispatch('loadAllUserData')
            this.uploadData = []
          } else {
            swal({
              title: 'ERROR',
              text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
              type: 'error',
              confirmButtonClass: 'btn btn-info btn-fill',
              buttonsStyling: false
            })
          }
          // loading.close()
          this.isModalOpen = false

        }).catch( error => {
          // loading.close()
          console.log('failed', error)
        })
      },

      removeUser () {
        MessageBox.prompt('현재 회원을 DB에서 강제적으로 삭제합니다. 삭제를 진행하려면 아래에 [강제 삭제]를 입력하세요', '강제삭제', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          inputPattern: /강제 삭제/,
          inputErrorMessage: 'Invalid Text'
        }).then(({ value }) => {
          this.axios.delete(baseAction + this.formData.idx).then(rs => {
            this.tableData = this.tableData.filter(row => {
              return row.idx !== this.formData.idx
            })
            this.tableDataOrigin = this.cloneData(this.tableData)
            this.$store.dispatch('loadAllUserData')
            this.uploadData = []
            this.isModalOpen = false
          })
        })

      },

      handleEdit (index, row) {
        this.toggleModal(row)
      },
      handleDelete (index, row) {
        let $this = this
        swal({
          title: '삭제하시겠습니까?',
          // text: '삭제하면 관련',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          buttonsStyling: false
        }).then(function () {
          $this.loading = true
          $this.axios.delete(baseAction + row.idx).then((rs) => {
            $this.loading = false
            if (rs.data.status === 'success') {
              $this.tableData.splice(index, 1)
              $this.tableDataOrigin.splice(index, 1)
            } else {
              swal({
                title: 'ERROR',
                text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
                type: 'error',
                confirmButtonClass: 'btn btn-info btn-fill',
                buttonsStyling: false
              })
            }
          }).catch( error => {
            $this.loading = false
          })
        }, function (dismiss) {

        })
      },
      getNo (no) {
        const rtn = this.pagination.total - (no + (this.pagination.perPage * (this.pagination.currentPage-1)))
        return !isNaN(rtn) ? rtn : ''
      },
      setCurrentPage (p) {
        // if (p !== this.pagination.currentPage) {
        this.pagination.currentPage = p
        this.getPostList()
        // }
      }
    }
  }
</script>
<style scoped>
  /deep/ .LIST .el-range-separator {
    width: 30px !important;
  }
  /deep/ .LIST .el-select {
    width: 36% !important;
    min-width: 110px;
    margin-right: 5px;
  }
  /deep/ .LIST .el-select.small {
    width: 25% !important;
    min-width: 80px;
  }
  /deep/ .LIST .el-select:last-child {
    margin-right:0;
  }

  .el-table th .cell {
    padding-right: 15px;
  }
  /deep/ .el-date-editor.el-input {
    width: 100% !important;
  }
  /deep/ .el-date-editor.el-input .el-input__inner {
    background-color: #fafafa;
  }
  label {
    font-weight: bold !important;
  }

  .card-user .image { height: 114px; }
  .card-user .card-body { min-height: 140px; }

  /deep/ .avatar-uploader .el-upload-list {
    position: relative;
    z-index: 2;
  }
  /deep/ .avatar-uploader .el-upload-list__item {
    position: absolute;
    z-index: 0;
  }
  /deep/ .avatar-uploader .el-upload-list__item.is-success{
    z-index: 2;
  }

  /deep/ .wish-wrap {
    height: auto; min-height: 38px; max-height: 350px; overflow-y: auto; background-color: #f1f1f1;
  }

  /deep/ .modal-dialog {
    padding-top: 0 !important;
    width: 95% !important;
    max-width: 1919px !important;
    margin: 2.5rem auto !important;
  }
  /deep/ .modal-body {
    background-color: #f4f3ef;
    padding: 20px 30px !important;
  }
</style>
