<template>
  <div class="card">
    <div class="card-header row">
      <div class="col-md-8">
        <h5 class="title">
          포스트기본정보
        </h5>
      </div>
      <div class="col-md-4">
        <div class="btn-group pull-right text-nowrap">
          <p-button type="default" class="mt-0" :class="post.type==='HOTEL'?'SELECTED':'OUTLINE'" @click="changeType('HOTEL')">호텔</p-button>
          <p-button type="default" class="mt-0" :class="post.type==='FLIGHT'?'SELECTED':'OUTLINE'" @click="changeType('FLIGHT')">항공</p-button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="required">콘텐츠 카테고리</label>
              <div>
                <el-select
                  required
                  class="select"
                  v-model="post.post_cate.idx"
                  @change="changePostCate"
                  placeholder="카테고리 선택">
                  <el-option class="select-default" value=""></el-option>
                  <el-option
                    class="select-default"
                    v-for="cate in getPostCateOptions"
                    :key="cate.idx"
                    :label="cate.name"
                    :value="cate.idx">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <fg-input type="text"
                      label="포스트 타이틀"
                      placeholder="포스트 타이틀 입력"
                      class="required"
                      required
                      v-model="post.title">
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="required">작성자</label>
              <div>
                <el-select
                  class="select"
                  v-model="post.writer.idx"
                  @change="changeMember"
                  placeholder="작성자 선택">
                  <el-option class="select-default" value=""></el-option>
                  <el-option
                    class="select-default"
                    v-for="member in getMemberOptions"
                    :key="member.idx"
                    :label="member.name"
                    :value="member.idx">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group">
              <label>해시태그 키워드</label>
              <div>
                <span>
                  <!--<input type="text" placeholder="태그 입력"-->
                         <!--class="form-control"-->
                         <!--v-model="tagInputValue"-->
                         <!--ref="saveTagInput"-->
                         <!--@keyup.enter="handleInputConfirm()"-->
                         <!--@blur="handleInputConfirm()"/>-->
                  <fg-input>
                    <el-select multiple
                               class="select-default"
                               size="large"
                               value-key="idx"
                               v-model="post.hash_tags"
                               placeholder="해시태그 선택">
                      <el-option v-for="option in getAllHashTagOptions"
                                 class="select-primary"
                                 :key="option.idx"
                                 :label="`#${option.name}`"
                                 :value="option">
                      </el-option>
                    </el-select>
                  </fg-input>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label>SEO 메타</label>
            <div class="pull-right inline">
              <!--<p-button class="float-left" @click.native="savePostData">reset</p-button>-->
              <p-button class="btn btn-primary btn-neutral p-0 m-0 mr-3" @click.native="setSeoMeta">reset</p-button>
              <p-button class="btn btn-primary btn-neutral p-0 m-0" @click.native="emptySeoMeta">empty</p-button>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <div class="row" v-for="(seo, index) in post.seo_meta" :key="index">
                <div class="col-md-4">
                  <input :readonly="true"
                         class="form-control bg-light"
                         :value="seo.name ? seo.name : seo.property" />
                </div>
                <div class="col-md-8">

                  <el-input type="textarea"
                            class="m-0 p-0"
                            @input="limitInputLine($event, seo, 'comments', 3)"
                            :autosize="{ minRows: 1, maxRows:3 }"
                            placeholder="Content 입력"
                            v-model="seo.content">
                  </el-input>
                  <!--<textarea rows="5" class="form-control border-input CLEAR_MAX_HEIGHT h-100"-->
                  <!--placeholder="Content 입력"-->
                  <!--v-model="post.seo_meta">-->
                  <!--</textarea>-->

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>연관 호텔</label>
              <div class="row my-0">
                <div class="col-md-12">
                  <el-select v-for="(item, index) in post.related_hotels" :key="index"
                             class="select pt-1"
                             placeholder="호텔 선택"
                             value-key="idx"
                             v-model="post.related_hotels[index]">
                    <el-option class="select-default" value=""></el-option>
                    <el-option v-for="hotel in getAllHotelDataFast"
                               class="select-default"
                               :key="hotel.idx"
                               :label="hotel.name"
                               :value="hotel">
                    </el-option>
                  </el-select>
                </div>
              </div>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>연관 항공사</label>
              <div class="row my-0">
                <div class="col-md-12">
                  <el-select v-for="(item, index) in post.related_airlines" :key="index"
                             class="select pt-1"
                             placeholder="항공사 선택"
                             value-key="idx"
                             v-model="post.related_airlines[index]">
                    <el-option class="select-default" value=""></el-option>
                    <el-option v-for="hotel in getAllAirlineCorpData"
                               class="select-default"
                               :key="hotel.idx"
                               :label="hotel.name"
                               :value="hotel">
                    </el-option>
                  </el-select>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label>본문 내용</label>
          </div>
          <div class="col-md-12">
            <div class="form-group">

              <!--<textarea rows="5" class="form-control border-input CLEAR_MAX_HEIGHT h-100"-->
                        <!--placeholder="이벤트/프로모션 입력 (컨테이너)"-->
                        <!--v-model="post.pr_container">-->
              <!--</textarea>-->
              <froala :tag="'textarea'" :config="config_contents" :readonly="true" v-model="post.contents"></froala>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>
      </form>
    </div>
    <el-dialog :visible.sync="dialogVisible"
               :modal-append-to-body="false">
      <img :src="dialogImageUrl" alt="">
    </el-dialog>
    <hotel-selector ref="modal" @selected-hotel="insertHotelToEditor"></hotel-selector>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Input, Select, Option, Tag, Tooltip, Upload, Dialog} from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import HotelSelector from './HotelSelector'
  import { mapGetters } from 'vuex'
  import commonMethods from 'src/mixins/common_methods'
  import forms from '../forms'
  import editors from '../editors'
  export default {
    name: 'PostBasicForm',
    components: {
      Select, Option,
      PSwitch, HotelSelector,
      [Input.name]: Input,
      [Tag.name]: Tag,
      [Tooltip.name]: Tooltip,
      [Select.name]: Select,
      [Option.name]: Option,
      [Upload.name]: Upload,
      [Dialog.name]: Dialog,
    },
    filters: {
      capitalize: function (value) {
        console.log(value)
        return value.replace(/\n/g, '<br>')
      }
    },
    data () {
      return {
        post: forms,
        tagInputValue: '',
        dialogImageUrl: '',
        dialogVisible: false,
        editor: '',
        config_contents: {
          key: editors.keys,
          events: editors.events,
          htmlAllowedTags: editors.htmlAllowedTags,
          htmlAllowedEmptyTags: editors.htmlAllowedEmptyTags,
          htmlRemoveTags: editors.htmlRemoveTags,
          toolbarButtons: editors.toolbarButtonsMax,
          imageEditButtons: editors.imageEditButtons,
          imageDefaultWidth: editors.imageDefaultWidth,
          imageResize: editors.imageResize,
          placeholderText: "포스트 내용",
          useClasses: true,
          height: 300,
          imageUploadURL: Vue.prototype.$apiURL + '/posts/upload/editor',
          imageUploadParams: {column: 'contents'},
          imageMove: true,
          enter: $.FroalaEditor.ENTER_BR,
        },
      }
    },
    created () {
      window.vm = this

      $.FroalaEditor.DefineIconTemplate('nc-icon', '<i class = "nc-icon nc-[NAME]"></i>')
      $.FroalaEditor.DefineIcon('insertHotel', {NAME: 'istanbul', template: 'nc-icon'})
      $.FroalaEditor.RegisterCommand('insertHotel', {
        title: 'Insert Hotel',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function () {
          vm.$refs.modal.isModalOpen = true
          vm.editor = this
          // this.html.insert('New Hotel')
        }
      })
    },
    computed: {
      ...mapGetters([
        'getAllPostDataFast',
        'getAllPostDataFastCnt',
        'getAllPostCateData',
        'getAllPostCateDataCnt',
        'getPostCateOptions',
        'getAllMemberData',
        'getAllMemberDataCnt',
        'getMemberOptions',
        'getAllHotelDataFast',
        'getAllHashTagOptions',
        'getAllAirlineCorpData',
        'getAllAirlineCorpDataCnt',
        'getSeoMetaDataDefault',
        'getSeoMetaDataDefaultCnt'
      ])
    },
    methods: {
      ...commonMethods,
      insertHotelToEditor (idx) {
        this.editor.html.insert(`<hotel>${idx}</hotel><br><br>`)
        this.$refs.modal.isModalOpen = false
      },
      changeType (type) {
        this.post.type = type
      },
      changePostCate (idx) {
        console.log(idx)
        const from = this.getPostCateOptions.find(opt => opt.idx === idx)
        if (from) {
          this.post.post_cate.name = from.name
          this.post.post_cate.logo_img = from.logo_img
        } else {
          this.post.post_cate = {}
        }
      },
      changeMember (idx) {
        const from = this.getMemberOptions.find(opt => opt.idx === idx)
        if (from) {
          this.post.writer.name = from.name
          this.post.writer.prof_img = from.prof_img
        } else {
          this.post.writer = {}
        }
      },
      handleClose (tag) {
        this.post.tags.splice(this.post.tags.indexOf(tag), 1)
      },
      handleInputConfirm () {
        let inputValue = this.tagInputValue
        if (inputValue) {
          this.post.tags.push(inputValue)
        }
        this.tagInputValue = ''
      },
      onlyInteger (data, itemName) {
        if (data[itemName] < 0) data[itemName] = ''
      },
      setSeoMeta () {
        const description = this.post.contents.replace(/<\/?[^>]+(>|$)/g, '').substr(0, 150).replace('/ /g', '')
        const author = this.post.writer ? this.post.writer.name : 'CardGorilla'
        const post_cate = (this.post.post_cate ? this.post.post_cate.name : '')
        const keyword = (post_cate ? post_cate + ', ' : '') + this.post.hash_tags.map(row => row.name).join(', ')
        const post_img = this.isFileExist(this.post.post_img) ? this.post.post_img.url : ''

        let data = this.cloneData(Object.assign([], this.getSeoMetaDataDefault))

        data.map(row => {
          let key = row['name'] ? row['name'] : row['property']
          row.content =
            key === 'keywords' ? keyword :
            key === 'description' ? description :
            key === 'author' ? author :
            key === 'og:type' ? 'website' :
            key === 'og:title' ? this.post.title + ' | 프레스티지고릴라' :
            key === 'og:description' ? description :
            key === 'og:image' ? post_img : ''
          return row
        })
        this.post.seo_meta = Object.assign([], data)
      },
      emptySeoMeta () {
        this.post.seo_meta = this.cloneData(Object.assign([], this.getSeoMetaDataDefault))
      }
    }
  }
</script>
<style scoped>
  label {
    font-weight: bold !important;
  }
  .  {
    display: inline-block;
  }
  .BUTTON {
    position: absolute;
    top: 2px;
    right: 15px;
  }
  .SELECTED {
    background-color: #343a40 !important;
    border: 1px solid #343a40 !important;
  }
  .OUTLINE {
    background-color: #fff !important;
    color: #aaa !important;
    border: 1px solid #ddd !important;
  }
  .CLEAR_MAX_HEIGHT {
    max-height: 123px !important;
  }
  .MAIN_OPTION {
    color: #555;
  }
  .SUB_OPTION {
    color: #999;
  }
  .SWITCH {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  /deep/ .el-textarea__inner {
    min-height: calc(2.25rem + 2px) !important;
    background-color: #fafafa !important;
    padding: 8px !important;
  }
  /deep/ .el-textarea__inner:focus {
    border: 1px solid #999 !important;
    background-color: #fff !important;
  }

  /deep/ .el-select .el-input.is-focus .el-input__inner {
    color: #66615b;
  }

  /deep/ .required:before {
    content: '●';
    color: red;
    display: inline-block;
    margin: 0 5px 0 0;
  }
  /deep/ .el-select.required:before {
    content: '';
    margin: 0;
    display: none;
  }
  /deep/ .upload-demo .el-upload {
    display: block !important;
  }
  /deep/ .upload-demo .el-upload-list {
    margin-bottom: 3px !important;
  }
  /deep/ .upload-demo .el-upload-list__item:first-child {
    margin-top: 0 !important;
  }

  /deep/ hotel {
    display: block;
    width: 100%;
    max-width: 760px;
    height: 150px;
    border: 2px solid #333;
    text-align: center;
    background-color: #f2f2f2;
    font-size: 2em;
    margin-bottom: 10px;
    padding-top: 30px;
  }
  /deep/ hotel:before {
    content: "HID: ";
    font-size: 1em;
    color: #777;
  }
  /deep/ hotel:after {
    content: "\a HOTEL CONTAINER";
    font-size: 0.6em;
    color: #777;
    white-space: pre-wrap;
  }

  /deep/ .modal {
    z-index: 2247483637!important
  }
</style>
