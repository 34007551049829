<template>
  <modal :show.sync="isModalOpen"
         footer-classes="justify-content-center"
         >
    <h5 slot="header" class="modal-title text-left">포스트 {{btnText}}</h5>
    <template class="bg-light">
      <div class="row ">
        <div class="col-lg-9 col-md-8">
          <post-basic-form ref="basic">

          </post-basic-form>
        </div>
        <div class="col-lg-3 col-md-4">
          <post-preview ref="preview">

          </post-preview>
        </div>
      </div>
    </template>
    <div slot="footer" class="justify-content-center">
      <p-button type="info" size="lg" wide round @click.native="savePostData">{{btnText}}</p-button>
    </div>
  </modal>
</template>
<script>
  import Vue from 'vue'
  import { Loading } from 'element-ui'
  import Modal from 'src/components/UIComponents/Modal.vue'
  import swal from 'sweetalert2'
  import PostBasicForm from './PostDetail/PostBasicForm'
  import PostPreview from './PostDetail/PostPreview.vue'
  import commonMethods from 'src/mixins/common_methods'
  import forms from './forms'
  const baseAction = '/posts/'
  export default {
    components: {
      PostBasicForm,
      PostPreview,
      Modal
    },
    data () {
      return {
        baseAction: baseAction,
        post: forms,
        postRs: {},
        relatedHotelsDefault: [
          {idx: '', name: '', main_img: '', hash_tags: []},
          {idx: '', name: '', main_img: '', hash_tags: []},
          {idx: '', name: '', main_img: '', hash_tags: []},
        ],
        relatedAirlinesDefault: [
          {idx: '', name: '', logo_img: ''},
          {idx: '', name: '', logo_img: ''},
          {idx: '', name: '', logo_img: ''},
        ],
        loading: true,
        isModalOpen: false,
        btnText: '등록',
      }
    },
    watch: {
      isModalOpen (val) {
        if (!val) {
          if (JSON.stringify(this.postRs) !== JSON.stringify(this.post)) {
            if (!confirm('변경된 내용이 있습니다. 무시하고 창을 닫을까요?')) {
              this.isModalOpen = true
              return false;
            }
          }

          // forms 초기화
          this.resetFormData ()

          window.removeEventListener('beforeunload', this.unload)

        } else {
          window.addEventListener('beforeunload', this.unload)
        }
      }
    },
    mounted () {

    },
    methods: {
      ...commonMethods,
      resetFormData () {
        for( let key in this.post) {
          if (typeof this.post[key] === 'string') this.post[key] = ''
          else if (typeof this.post[key] === 'number') this.post[key] = 0
          else if (typeof this.post[key] === 'boolean') this.post[key] = false
          else if (this.post[key] instanceof Array) this.post[key] = []
          else if (this.post[key] instanceof Object) this.post[key] = {}
        }
        this.post.type = 'HOTEL'
        this.post.post_cate = {}
        this.post.writer = {}
        this.post.seo_meta = this.cloneData(this.$refs.basic.getSeoMetaDataDefault)
        this.post.related_hotels = this.cloneData(this.relatedHotelsDefault)
        this.post.related_airlines = this.cloneData(this.relatedAirlinesDefault)
      },
      openModal (row) {
        const loading = Loading.service()
        this.resetFormData()

        if(row) {
          this.btnText = '수정'
          this.axios.get('/posts/'+row.idx).then((rs) => {
            for( var key in rs.data) {
              let val = rs.data[key]
              if (val===null) {
                // null 이면 반영 안함
              } else if (val.constructor === Array) {
                if (val.length > 0) {
                  this.post[key] = val
                }
              } else if (val.constructor === Object) {
                if (Object.keys(val).length > 0) {
                  this.post[key] = val
                }
              } else {
                this.post[key] = val
              }
            }

            if (this.post.related_hotels.length > 0) this.post.related_hotels = this.cloneData(Object.assign([], this.relatedHotelsDefault, this.post.related_hotels))
            if (this.post.related_airlines.length > 0) this.post.related_airlines = this.cloneData(Object.assign([], this.relatedAirlinesDefault, this.post.related_airlines))

            setTimeout(function () {
              this.postRs = this.cloneData(this.post)
            }.bind(this))

            loading.close()
          })
        } else {
          this.postRs = this.cloneData(this.post)
          this.btnText = '등록'
          loading.close()
        }
        this.isModalOpen = true
      },
      savePostData () {
        const loading = Loading.service()
        let params = this.cloneData(this.post)
        let uploadDataPreview = this.$refs.preview.uploadData
        let uploadData = Object.assign([], uploadDataPreview)
        let method = 'post'
        let action = baseAction

        if (!params.post_cate.idx || !params.writer.idx || !params.title) {
          // console.log(params)
          setTimeout(() => {
            swal({
              title: 'VALIDATION',
              text: '필수항목을 모두 입력해 주세요',
              type: 'warning',
              confirmButtonClass: 'btn btn-info btn-fill',
              buttonsStyling: false
            })
          }, 500)
          loading.close()
          return false
        }

        if (params.idx) {
          action += params.idx
          method = 'put'
        }

        let formData = new FormData()
        for (let key in uploadData) {
          delete params[key]
          if (Object.keys(uploadData[key]).length > 0) {
            formData.append(key, uploadData[key].raw)
          } else {
            formData.append(key, null)
          }
        }
        for (let key in params) {
          // console.log(key, params[key])
          if (typeof params[key] === 'object') {
            formData.append(key, JSON.stringify(params[key]).toString())
          } else {
            formData.append(key, params[key])
          }
        }

        // console.log('------------------------------') // 폼데이터는 직접 console.log를 찍으면 값이 전송되지 않는다
        // for (var key of formData.entries()) {
        //   console.log(key[0] + ', ' + key[1]);
        // }
        // return

        this.axios.post(action, formData).then((rs) => {
          if (rs.data.status === 'success') {
            this.postRs = this.cloneData(this.post)
            let data = rs.data.data[0]
            this.$emit('change-table-data', method, data)
            this.$store.dispatch('loadAllPostDataFast')
            this.$refs.preview.uploadData = []
          } else {
            swal({
              title: 'ERROR',
              text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
              type: 'error',
              confirmButtonClass: 'btn btn-info btn-fill',
              buttonsStyling: false
            })
          }
          loading.close()
          this.isModalOpen = false

        }).catch( error => {
          loading.close()
          console.log('failed', error)
        })
      },
    },
  }
</script>
<style>

</style>
