export default {
  getCurrentComponentName () {
    return this.$options.name
  },
  getParentComponentName () {
    return this.$parent.$options.name
  },
  getRootComponentName () {
    return this.$parent.$parent && this.$parent.$parent.$options ? this.$parent.$parent.$options.name : this.$options.name
  },
  getRouteName () {
    return this.$route.name
  },
  getRouteRootPath () {
    return this.$route.path.split('/')[1]
  },
  getRouteFullPath () {
    return this.$route.path.substring(1)
  },
  getRouteParams () {
    return this.$route.params
  }
}