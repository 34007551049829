export default {
  idx: 0,
  cate: 'DOMESTIC', // DOMESTIC: 국내, OVERSEAS: 해외
  name: '',
  name_eng: '',
  brand_idx: [],
  region_idxs: [],
  place: '',
  address: '',
  latitude: 0,
  longitude: 0,
  star_rating: 0,
  amenities: '',
  checkin: '',
  checkout: '',
  request_app: '',
  main_img: {url: ''},
  imgs: [],
  bs_imgs: [],
  hash_tags: [],
  seo_meta: [],
  related_review_videos: [],
  related_review_posts: [],
  related_hotels: [],
  related_contents: [],
  theme_ranking_contents: [],
  package_contents: [],
  is_review: false,
  is_visible: false
}
