<template>
  <div>

    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-lg-4 col-md-5 col-sm-4">
            <h5 class="title pointer" @click="isBoxFolded=!isBoxFolded">
              호텔기본정보
              <p-button type="neutral" size="sm" class="m-md-0">
                <i class="nc-icon" :class="isBoxFolded?'nc-minimal-down':'nc-minimal-up'"></i>
              </p-button>
            </h5>
          </div>

          <div class="col-lg-8 col-md-7 col-sm-8 ">
            <div class="btn-group pull-right text-nowrap">
              <p-button type="default" class="mt-0" :class="hotel.cate==='DOMESTIC'?'SELECTED':'OUTLINE'" @click="changeCate('DOMESTIC')">국내</p-button>
              <p-button type="default" class="mt-0" :class="hotel.cate==='OVERSEAS'?'SELECTED':'OUTLINE'" @click="changeCate('OVERSEAS')">해외</p-button>
            </div>
          </div>
        </div>
        <div class="row bg-light pt-md-2 pb-md-1">
          <div class="col-md-"></div>
          <div class="col-md-12 text-right">
            <span class="mr-md-3">
              <label class="pr-2">프고리뷰 &nbsp;</label>
              <p-switch v-model="hotel.is_review" type="warning" on-text="ON" off-text="OFF"></p-switch>
            </span>
            <span class="">
              <label class="pr-2">검색노출 &nbsp;</label>
              <p-switch v-model="hotel.is_visible" type="danger" on-text="ON" off-text="OFF"></p-switch>
            </span>
          </div>
        </div>
      </div>
      <div class="card-body pb-3" v-show="isBoxFolded">
        <form>
          <div class="row">
            <div class="col-md-6 pr-md-2">
              <fg-input type="text"
                        label="호텔명 (한글)"
                        placeholder="호텔명(한글) 입력"
                        class="required"
                        v-model="hotel.name">
              </fg-input>
            </div>
            <div class="col-md-6 pl-md-2">
              <fg-input type="text"
                        label="호텔명 (영문)"
                        placeholder="호텔명(영문) 입력"
                        class="required"
                        v-model="hotel.name_eng">
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-2 pr-md-1">
<!--              <fg-input type="text"-->
<!--                        label="성급"-->
<!--                        placeholder="성급"-->
<!--                        class=""-->
<!--                        :value="hotel.star_rating">-->
<!--              </fg-input>-->
              <label>성급</label>
              <div class="row">
                <div class="col-md-12">
                  <el-select filterable
                             class="select-default"
                             placeholder="브랜드 검색"
                             v-model="hotel.star_rating">
                    <el-option
                      class="select-default"
                      v-for="option in getStarRatings"
                      :key="option"
                      :label="option"
                      :value="option">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="col-md-4 pl-md-1 pr-md-2">
              <label>브랜드</label>
              <div class="row">
                <div class="col-md-12">
                  <el-select filterable
                             class="select-default"
                             placeholder="브랜드 검색"
                             v-model="hotel.brand_idx">
                    <el-option class="select-default" value=""></el-option>
                    <el-option
                      class="select-default"
                      v-for="option in getAllHotelBrandOptions"
                      :key="option.idx"
                      :label="option.name"
                      :value="option.idx">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="col-md-3 pl-md-2 pr-md-1">
              <fg-input type="text"
                        label="위치 (경도)"
                        placeholder="경도 (-180 ~ 180)"
                        class=""
                        v-model="hotel.longitude">
              </fg-input>
            </div>
            <div class="col-md-3 pl-md-1">
              <fg-input type="text"
                        label="위치 (위도)"
                        placeholder="위도 (-90 ~ 90)"
                        class=""
                        v-model="hotel.latitude">
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 pr-md-1">
              <label>국가</label>
              <div>
                <el-cascader v-model="hotel.region_idxs"
                             class="select-default col-md-12 p-0"
                             placeholder="카테고리 선택"
                             :options="getRegionDataOptions"
                             @change="changeRegion(hotel.region_idxs)"
                             :props="{value: 'idx', label: 'name', children: 'sub_data'}"
                             filterable>
                </el-cascader>
              </div>
            </div>
            <div class="col-md-8 pl-md-1">
              <fg-input type="text"
                        label="주소"
                        placeholder="주소"
                        class=""
                        v-model="hotel.address">
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 pr-md-1">
              <label>편의시설</label>
              <div class="">
                <input type="text" placeholder="호텔 편의시설 입력"
                       class="form-control mb-2"
                       v-model="amenity"
                       ref="saveTagInput"
                       @keyup.enter="handleInputConfirm()"
                       @blur="handleInputConfirm()"/>

              </div>
            </div>
            <div class="col-md-4 pl-md-1 pr-md-1">
              <fg-input type="text"
                        label="체크인"
                        placeholder="체크인"
                        class=""
                        v-model="hotel.checkin">
              </fg-input>
            </div>
            <div class="col-md-4 pl-md-1">
              <fg-input type="text"
                        label="체크아웃"
                        placeholder="체크아웃"
                        class=""
                        v-model="hotel.checkout">
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
<!--              <fg-input type="text"-->
<!--                        label="호텔 편의시설"-->
<!--                        placeholder="호텔 편의시설"-->
<!--                        class=""-->
<!--                        v-model="hotel.amenities">-->
<!--              </fg-input>-->
              <el-tag
                class="mr-1 ml-0 border-0"
                v-for="(tag, index) in getAmenitiesArray"
                :key="index"
                type="gray"
                :closable="true"
                :close-transition="false"
                @close="handleClose(tag)"
              >
                {{tag}}
              </el-tag>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <fg-input type="text"
                        label="예약 신청"
                        placeholder="예약 신청 입력 (URL)"
                        class="required"
                        v-model="hotel.request_app">
              </fg-input>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12">
              <label>SEO 메타</label>
              <div class="pull-right inline">
                <!--<p-button class="float-left" @click.native="savePostData">reset</p-button>-->
                <p-button class="btn btn-primary btn-neutral p-0 m-0 mr-3" @click.native="setSeoMeta">reset</p-button>
                <p-button class="btn btn-primary btn-neutral p-0 m-0" @click.native="emptySeoMeta">empty</p-button>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <div class="row" v-for="(seo, index) in hotel.seo_meta" :key="index">
                  <div class="col-md-4">
                    <input :readonly="true"
                           class="form-control bg-light"
                           :value="seo.name ? seo.name : seo.property" />
                  </div>
                  <div class="col-md-8">

                    <el-input type="textarea"
                              class="m-0 p-0"
                              @input="limitInputLine($event, seo, 'comments', 3)"
                              :autosize="{ minRows: 1, maxRows:3 }"
                              placeholder="Content 입력"
                              v-model="seo.content">
                    </el-input>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>
        </form>
      </div>
      <el-dialog :visible.sync="dialogVisible"
                 :modal-append-to-body="false">
        <img :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import {Input, Autocomplete, Select, Option, Tag, Tooltip, Upload, Dialog, Loading, Cascader} from 'element-ui'
  import { mapGetters } from 'vuex'
  import commonMethods from 'src/mixins/common_methods'
  import forms from '../forms'
  import editors from '../editors'
  import { starRatings } from 'src/mixins/common_variables'
  export default {
    name: 'HotelBasicForm',
    components: {
      Select, Option,
      PSwitch,
      [Input.name]: Input,
      [Autocomplete.name]: Autocomplete,
      [Tag.name]: Tag,
      [Tooltip.name]: Tooltip,
      [Select.name]: Select,
      [Option.name]: Option,
      [Upload.name]: Upload,
      [Dialog.name]: Dialog,
      [Cascader.name]: Cascader
    },
    filters: {
      capitalize: function (value) {
        // console.log(value)
        return value.replace(/\n/g, '<br>')
      }
    },
    data () {
      return {
        hotel: forms,
        uploadData: [],
        isBoxFolded: true,
        dialogImageUrl: '',
        dialogVisible: false,
        amenity: ''
      }
    },
    created () {
      window.vm = this
    },
    computed: {
      ...mapGetters([
        'getAllHashTagOptions',
        'getAllHotelBrandOptions',
        'getAllHotelRegionDepthData',
        'getAllHotelRegionNationOptions',
        'getAllHotelRegionCityOptions',
        'getSeoMetaDataDefault',
        'getPostCateOptions'
      ]),
      getIsModify () {
        return Object.keys(this.hotel).find(row => row === 'idx') && this.hotel.idx > 0
      },
      getAmenitiesArray () {
        return this.hotel.amenities && this.hotel.amenities.length > 0 ? this.hotel.amenities.split(', ') : []
      },
      getStarRatings () {
        return starRatings
      },
      getRegionDataOptions () {
        return this.cloneData(this.getAllHotelRegionDepthData)
      }
    },
    methods: {
      ...commonMethods,
      changeCate (cate) {
        this.hotel.cate = cate
      },
      changeRegion (region) {
        console.log(region)
      },
      handleClose (tag) {
        const arr = this.hotel.amenities.split(', ')
        if (arr.indexOf(tag) > -1) arr.splice(arr.indexOf(tag), 1)
        this.hotel.amenities = arr.join(', ')
      },
      handleInputConfirm () {
        if (this.amenity.trim()) {
          this.hotel.amenities = this.hotel.amenities ? this.hotel.amenities + ', ' + this.amenity : this.amenity
        }
        this.amenity = ''
      },
      onlyInteger (data, itemName) {
        if (data[itemName] < 0) data[itemName] = ''
      },
      setSeoMeta () {
        const description = this.hotel.hash_tags.length > 0 ? this.hotel.hash_tags.map(row => row.name).join(', ') : this.hotel.amenities
        const author = 'PrestigeGorilla'
        const cate = this.hotel.amenities + ', 체크인: ' + this.hotel.checkin + ', 체크아웃: ' + this.hotel.checkout
        const keyword = (cate ? cate : '')
        const main_img = this.hotel.main_img.url

        let data = this.cloneData(Object.assign([], this.getSeoMetaDataDefault))
        data.map(row => {
          let key = row['name'] ? row['name'] : row['property']
          row.content =
            key === 'keywords' ? keyword :
            key === 'description' ? description :
            key === 'author' ? author :
            key === 'og:type' ? 'website' :
            key === 'og:title' ? this.hotel.name + ' | 프레스티지고릴라' :
            key === 'og:description' ? description :
            key === 'og:image' ? main_img : ''
          return row
        })
        this.hotel.seo_meta = Object.assign([], data)
      },
      emptySeoMeta () {
        this.hotel.seo_meta = this.cloneData(Object.assign([], this.getSeoMetaDataDefault))
      }
    }
  }
</script>
<style scoped>
  label {
    font-weight: bold !important;
  }
  .BUTTON *{
    margin-top: 0 !important;
  }
  .SWITCH {
    position: absolute;
    right: 15px;
    white-space: nowrap;
  }
  .SWITCH:first-child {
    right: 150px !important;
  }
  .SELECTED {
    background-color: #343a40 !important;
    border: 1px solid #343a40 !important;
  }
  .OUTLINE {
    background-color: #fff !important;
    color: #aaa !important;
    border: 1px solid #ddd !important;
  }
  .CLEAR_MAX_HEIGHT {
    max-height: 123px !important;
  }
  .MAIN_OPTION {
    color: #555;
  }
  .SUB_OPTION {
    color: #999;
  }
  /deep/ .form-control[readonly] {
    background-color: #f2f2f2 !important;
  }
  /deep/ .form-control[readonly]::placeholder {
    /*color: white !important;*/
  }

  /deep/ .select-default.el-select {
    width: 100%;
  }
  /deep/ .select-default.el-cascader {
    margin-top: -2px;
  }
  /deep/ .select-default.el-select .el-input__suffix {
    overflow: hidden;
  }
  /deep/ .select-default.el-select .el-input__inner,
  /deep/ .select-default.el-cascader .el-input__inner {
    color: #777;
    height: 36px;
    border: 1px solid #ddd !important;
    border-radius: 4px;
    background-color: #fafafa;
  }
</style>
