export default [
  {
    'idx': 1,
    'pid': '',
    'cate': '',
    'cate_txt': '',
    'post_img': '',
    'title': '',
    'editor': '',
    'reg_dt': '',
    'event_yn': '',
    'is_visible': false,
  },
  {
    'idx': 2,
    'pid': '',
    'cate': '',
    'cate_txt': '',
    'post_img': '',
    'title': '',
    'editor': '',
    'reg_dt': '',
    'event_yn': '',
    'is_visible': false,
  },
  {
    'idx': 3,
    'pid': '',
    'cate': '',
    'cate_txt': '',
    'post_img': '',
    'title': '',
    'editor': '',
    'reg_dt': '',
    'event_yn': '',
    'is_visible': false,
  },
  {
    'idx': 4,
    'pid': '',
    'cate': '',
    'cate_txt': '',
    'post_img': '',
    'title': '',
    'editor': '',
    'reg_dt': '',
    'event_yn': '',
    'is_visible': false,
  },
  {
    'idx': 5,
    'pid': '',
    'cate': '',
    'cate_txt': '',
    'post_img': '',
    'title': '',
    'editor': '',
    'reg_dt': '',
    'event_yn': '',
    'is_visible': false,
  },
]
