import { editorKeys } from 'src/mixins/common_variables.js'

export default {
  htmlAllowedTags: ['a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'blockquote', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'header', 'hgroup', 'hr', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li', 'link', 'main', 'map', 'mark', 'menu', 'menuitem', 'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param', 'pre', 'progress', 'queue', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'style', 'section', 'select', 'small', 'source', 'span', 'strike', 'strong', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead', 'time', 'tr', 'track', 'u', 'ul', 'var', 'video', 'wbr', 'script', 'hotel'],
  htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hotel'],
  htmlRemoveTags: ['style', 'base'],
  toolbarButtons: ['fullscreen', '|', 'undo', 'redo' , '|', 'fontSize' , '|', 'bold', 'italic', 'underline', 'strikeThrough', 'align', '|', 'insertImage', 'insertLink', '|', 'html'],
  toolbarButtonsNoSize: ['fullscreen', '|', 'undo', 'redo' , '|', 'bold', 'italic', 'underline', 'strikeThrough', 'align',, '|', 'insertImage', 'insertLink', '|', 'html'],
  toolbarButtonsMax: ['fullscreen', '|', 'undo', 'redo' , '|', 'fontFamily', 'fontSize', 'fontAwesome', 'color', 'inlineClass', 'inlineStyle', 'paragraphStyle', 'lineHeight', 'align',  '|', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|', 'insertImage', 'insertLink', 'insertVideo', 'insertTable', 'insertHotel', '|', 'html'],
  imageEditButtons: ['imageDisplay', 'imageReplace', 'imageAlign', 'imageInfo', 'imageRemove', 'imageSize', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove'],
  imageDefaultWidth: '100%',
  imageResize: true,
  keys: editorKeys,
  events: {
    'froalaEditor.initialized': function (e, editor) {
      editor.toolbar.hide()
    },
    'froalaEditor.click': function (e, editor, clickEvent) {
      if (!editor.fullscreen.isActive()) {
        editor.fullscreen.toggle()
        editor.toolbar.show()
      }
    },
    // 'froalaEditor.focus': function (e, editor) {
    //   if (!editor.fullscreen.isActive()) {
    //     editor.fullscreen.toggle()
    //     editor.toolbar.show()
    //   }
    // },
    'froalaEditor.commands.after': function (e, editor, cmd, param1) {
      if (cmd === 'fullscreen') {
        if (editor.fullscreen.isActive()) {
          editor.toolbar.show()
        } else {
          editor.toolbar.hide()
          if (editor.codeView.isActive()) editor.codeView.toggle()
        }
      }
    },
    'froalaEditor.image.beforeUpload': function (e, editor, images) {
      if (vm.formData) editor.opts.imageUploadParams.idx = vm.formData.idx
      else if (vm.post) editor.opts.imageUploadParams.idx = vm.post.idx

      //TODO 등록 시 에디터 이미지 업로드 막음
      if ( !(editor.opts.imageUploadParams.idx >= 1) ) {
        alert('신규등록에서는 에디터 이미지 업로드할 수 없습니다.\n수정화면에서 시도해 주세요')
        return false
      }
    },
    'froalaEditor.image.beforeRemove': function (e, editor, images) {
      if (vm.formData) editor.opts.imageUploadParams.idx = vm.formData.idx
      else if (vm.post) editor.opts.imageUploadParams.idx = vm.post.idx

      //TODO 등록 시 에디터 이미지 업로드 막음
      if ( !(editor.opts.imageUploadParams.idx >= 1) ) {
        alert('신규등록에서는 에디터 이미지 삭제할 수 없습니다.\n수정화면에서 시도해 주세요')
        return false
      }
    },
  },
}