<template>
  <div class="MEMBER_MANAGER">

    <!-- 헤더 및 컨트롤 -->
    <div class="col-md-12">
      <h5 class="title">사용자 관리</h5>
      <p class="category">
        <!--We combine <a href="http://element.eleme.io/#/en-US/component/quickstart" target="_blank" rel="noopener">Element-UI</a>-->
        <!--table functionalities together with a custom pagination component-->
        <!--which should provide a very good starting point to integrate tables in your application.-->
        <!--Check out more functionalities at <a href="http://element.eleme.io/#/en-US/component/table" target="_blank" rel="noopener">Element-UI table documentation</a>.-->
        사용자 관리입니다.
      </p>
    </div>

    <!-- 브랜드 리스트 -->
    <div class="card">
      <div class="card-body row table-full-width">
        <div class="col-sm-12">
          <el-table-draggable handle=".handle">
            <el-table :data="tableData"
                      v-loading="loading"
                      header-row-class-name="text-default">

              <el-table-column :min-width="30">
                <template slot-scope="props">
                  <div class="handle" :class="isReSorted ? 'text-warning' : 'text-gray' " v-if="getCheckThisPageModify">
                    <i class="nc-icon nc-tile-56 mt-2"></i>
                  </div>
                </template>
              </el-table-column>

              <el-table-column type="index"
                               width="80">
              </el-table-column>
              <el-table-column label="사진"
                               :min-width="100">
                <template slot-scope="props">
                  <img v-if="isFileExist(props.row.prof_img)" :src="props.row.prof_img.url" class="avatar mb-0" />
                </template>
              </el-table-column>
              <el-table-column :min-width="180"
                               label="이름">
                <template slot-scope="props">
                  <div>{{props.row.name}} <span class="NICK" :class="props.row.nick">/ {{props.row.nick}}</span></div>
                </template>
              </el-table-column>
              <el-table-column prop="position"
                               :min-width="180"
                               label="직책">
              </el-table-column>
              <el-table-column prop="class_txt"
                               :min-width="120"
                               label="분류">
              </el-table-column>
              <el-table-column prop="email"
                               :min-width="250"
                               label="ID(이메일)">
              </el-table-column>
              <el-table-column prop="reg_dt"
                               :min-width="110"
                               label="등록일자">
              </el-table-column>
              <el-table-column
                v-if="getCheckThisPageModify"
                :min-width="80"
                fixed="right"
                class-name="td-actions text-center"
                label="사용">
                <template slot-scope="props">
                  <el-switch v-model="props.row.is_visible" @change="changeIsVisibled(props.row)" active-color="#EB5E28" :disabled="!getCheckThisPageModify"></el-switch>
                </template>
              </el-table-column>
              <el-table-column
                v-if="getCheckThisPageModify || getCheckThisPageDelete"
                :min-width="100"
                align="right"
                fixed="right"
                class-name="action-buttons td-actions"
                label="수정/삭제">
                <template slot-scope="props">
                  <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)" :disabled="!getCheckThisPageModify">
                    <i class="fa fa-edit"></i>
                  </p-button>
                  <p-button type="danger" size="sm" icon @click="handleDelete(props.$index, props.row)" :disabled="props.row.idx < 2 || !getCheckThisPageDelete">
                    <i class="fa fa-times"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </el-table-draggable>
        </div>
      </div>
      <div>
        <p class="pull-left ml-3" v-if="getCheckThisPageModify">
          <p-button :type="isReSorted ? 'warning' : 'default'" :disabled="!isReSorted" round @click.native="saveSorting()">순서 저장</p-button>
        </p>
        <p class="pull-right mr-3">
          <p-button type="info" round @click.native="toggleModal(); loading=false" v-if="getCheckThisPageCreate">사용자 등록</p-button>
        </p>
      </div>

      <!-- 폼 모달 -->
      <modal :show.sync="isModalOpen"
             footer-classes="justify-content-center">

        <h5 slot="header" class="modal-title text-left">사용자 {{btnText}}</h5>
        <template class="bg-light">
          <div class="row ">
            <div class="col-sm-7 col-md-8 col-lg-9">

              <div class="card">
                <div class="card-body">

                  <form>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="required">사용자 분류</label>
                          <div>
                            <el-select
                              class="select"
                              v-model="formData.class.idx"
                              @change="changeMemberClass"
                              :disabled="formData.idx===1"
                              placeholder="사용자 분류 선택">
                              <el-option class="select-default" value=""></el-option>
                              <el-option
                                class="select-default"
                                v-for="cls in getMemberClassOptions"
                                :key="cls.idx"
                                :label="cls.name"
                                :value="cls.idx">
                              </el-option>
                            </el-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <fg-input type="email"
                                  label="ID(이메일)"
                                  placeholder="ID(이메일) 입력"
                                  class="required"
                                  v-model="formData.email">
                        </fg-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <fg-input type="text"
                                  label="이름"
                                  placeholder="이름 입력"
                                  class="required"
                                  v-model="formData.name">
                        </fg-input>
                      </div>
                      <div class="col-md-4">
                        <fg-input type="text"
                                  label="닉네임"
                                  placeholder="닉네임 입력"
                                  class="required"
                                  v-model="formData.nick">
                        </fg-input>
                      </div>
                      <div class="col-md-4">
                        <fg-input type="text"
                                  label="직책"
                                  placeholder="직책 입력"
                                  class="required"
                                  v-model="formData.position">
                        </fg-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <fg-input type="password"
                                  label="비밀번호"
                                  placeholder="비밀번호 입력"
                                  class="required"
                                  v-model="formData.password">
                        </fg-input>
                      </div>
                      <div class="col-md-6">
                        <fg-input type="password"
                                  label="비밀번호 확인"
                                  placeholder="비밀번호 다시 입력"
                                  class="required"
                                  v-model="password2">
                        </fg-input>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <fg-input type="text"
                                  label="슬로건"
                                  placeholder="슬로건 입력"
                                  v-model="formData.slogan">
                        </fg-input>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

            </div>
            <div class="col-sm-5 col-md-4 col-lg-3">

              <div class="card card-user">
                <div class="image">
                  <img src="static/img/blank.gif" alt="...">
                </div>
                <div class="card-body">
                  <div class="author">
                    <el-upload
                      id="prof_img"
                      ref="prof_img"
                      class="avatar-uploader"
                      action="about:blank"
                      accept="image/png, image/jpeg, image/gif"
                      :file-list="getFileList(formData.prof_img)"
                      :on-preview="handlePreview"
                      :on-change="handleChange"
                      :on-remove="handleRemove"
                      :auto-upload="false"
                      :limit="1"
                      list-type="picture-card">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="pull-right">
                        <label class="pr-2">사용</label>
                        <p-switch v-model="formData.is_visible" type="danger" on-text="ON" off-text="OFF"></p-switch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </template>
        <div slot="footer" class="justify-content-center">
          <p-button type="info" size="lg" wide round @click.native="saveMemberData">{{btnText}}</p-button>
        </div>
      </modal>

      <!-- 이미지뷰어 다이얼로그 -->
      <el-dialog :visible.sync="dialogVisible"
                 :modal-append-to-body="false">
        <img :src="dialogImageUrl" alt="">
      </el-dialog>

    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option, Loading, Upload, Dialog, Switch} from 'element-ui'
  import ElTableDraggable from 'element-ui-el-table-draggable'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import Modal from 'src/components/UIComponents/Modal'
  import swal from 'sweetalert2'
  import { mapGetters } from 'vuex'
  import commonMethods from 'src/mixins/common_methods'
  const baseAction = '/members/'
  export default{
    components: {
      PSwitch, Modal, ElTableDraggable,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      [Upload.name]: Upload,
      [Dialog.name]: Dialog,
      [Switch.name]: Switch,
    },
    data () {
      return {
        baseAction: baseAction,
        formData: {
          class: {},
          email: '',
          name: '',
          nick: '',
          password: '',
          position: '',
          slogan: '',
          prof_img: {},
          is_visible: false,
        },
        password2: '',
        uploadData: [],
        tableData: [{},{},{},{},{}],
        tableDataOrigin: [{},{},{},{},{}],
        loading: true,
        isModalOpen: false,
        dialogImageUrl: '',
        dialogVisible: false,
        btnText: '등록',
      }
    },
    watch: {
      isModalOpen (val) {
        if (!val) {
          // forms 초기화
          for( let key in this.formData) {
            if (typeof this.formData[key] === 'string') this.formData[key] = ''
            else if (typeof this.formData[key] === 'number') this.formData[key] = 0
            else if (typeof this.formData[key] === 'boolean') this.formData[key] = false
            else if (this.formData[key] instanceof Array) this.formData[key] = []
            else if (this.formData[key] instanceof Object) this.formData[key] = {}
          }
          this.password2 = ''
        }
      }
    },
    mounted () {
      this.$store.dispatch('callAllMemberClassData')
      this.$store.dispatch('callAllMemberData').then(() => {
        this.getMemberList()
      })
    },
    computed: {
      ...mapGetters([
        'getAllMemberData',
        'getMemberClassOptions',
        'getCheckThisPageCreate',
        'getCheckThisPageRead',
        'getCheckThisPageModify',
        'getCheckThisPageDelete'
      ]),
      isReSorted () {
        const cur = this.tableData.map(row => row.idx).toString()
        const ori = this.tableDataOrigin.map(row => row.idx).toString()
        return cur && cur !== ori
      }
    },
    methods: {
      ...commonMethods,
      getMemberList () {
        this.tableData = this.getAllMemberData
        this.tableDataOrigin = this.cloneData(this.tableData)
        this.loading = false
      },
      changeMemberClass (idx) {
        const from = this.getMemberClassOptions.find(opt => opt.idx === idx)
        if (from) {
          this.formData.class.name = from.name
          this.formData.class.logo_img = from.logo_img
        } else {
          this.formData.class = {}
        }
      },
      changeIsVisibled (row) {
        const params = {idx: row.idx, is_visible: row.is_visible};
        const action = baseAction + 'visible/' + params.idx
        this.axios.put(action, params).then((rs) => {
          if (rs.data.status === 'success') {
            this.setMemberList('put', rs.data.data[0])
          }
        }).catch( error => {
          console.log('failed', error)
        })
      },
      setMemberList (method, row) {
        if(method === 'put') {
          for (let key in this.tableData) {
            if (this.tableData[key].idx === row.idx) {
              this.tableData.splice(key, 1, row)
              break
            }
          }
        } else {
          this.tableData.unshift(row)
        }
      },
      toggleModal (row) {
        const loading = Loading.service()
        if(row) {
          this.btnText = '수정'
          this.axios.get(baseAction + row.idx).then((rs) => {
            for( var key in rs.data) {
              let val = rs.data[key]
              if(val!==null) {
                this.formData[key] = val
              }
            }
            loading.close()
          })
        } else {
          this.btnText = '등록'
          loading.close()
        }
        this.isModalOpen = !this.isModalOpen
      },
      saveMemberData () {
        // const loading = Loading.service()
        let params = this.formData
        let method = 'post'
        let action = baseAction

        if (params.idx) {
          action += params.idx
          method = 'put'
        }

        if (!params.password) delete params['password']

        let formData = new FormData()
        for (let key in this.uploadData) {
          delete params[key]
          if (Object.keys(this.uploadData[key]).length > 0) {
            formData.append(key, this.uploadData[key].raw)
          } else {
            formData.append(key, null)
          }
        }
        for (let key in params) {
          if (typeof params[key] === 'object') {
            formData.append(key, JSON.stringify(params[key]))
          } else {
            formData.append(key, params[key])
          }
        }

        this.axios.post(action, formData).then((rs) => {
          if (rs.data.status === 'success') {
            let data = rs.data.data[0]
            if (method === 'put') {
              for (let key in this.tableData) {
                if (this.tableData[key].idx === data.idx) {
                  Vue.set(this.tableData, key, data)
                  break
                }
              }
            } else {
              this.tableData.push(data)
              this.tableDataOrigin = this.cloneData(this.tableData)
            }
            this.$store.dispatch('loadAllMemberData')
            this.uploadData = []
          } else {
            swal({
              title: 'ERROR',
              text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
              type: 'error',
              confirmButtonClass: 'btn btn-info btn-fill',
              buttonsStyling: false
            })
          }
          // loading.close()
          this.isModalOpen = false

        }).catch( error => {
          // loading.close()
          console.log('failed', error)
        })
      },

      handleEdit (index, row) {
        this.toggleModal(row)
      },
      handleDelete (index, row) {
        let $this = this
        swal({
          title: '삭제하시겠습니까?',
          // text: '삭제하면 관련',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          buttonsStyling: false
        }).then(function () {
          $this.loading = true
          $this.axios.delete(baseAction + row.idx).then((rs) => {
            $this.loading = false
            if (rs.data.status === 'success') {
              $this.tableData.splice(index, 1)
              $this.tableDataOrigin.splice(index, 1)
            } else {
              swal({
                title: 'ERROR',
                text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
                type: 'error',
                confirmButtonClass: 'btn btn-info btn-fill',
                buttonsStyling: false
              })
            }
          }).catch( error => {
            $this.loading = false
          })
        }, function (dismiss) {

        })
      },
    }
  }
</script>
<style scoped>
  .el-table th .cell {
    padding-right: 15px;
  }
  label {
    font-weight: bold !important;
  }

  .card-user .image { height: 114px; }
  .card-user .card-body { min-height: 140px; }

  /deep/ .avatar-uploader .el-upload-list {
    position: relative;
    z-index: 2;
  }
  /deep/ .avatar-uploader .el-upload-list__item {
    position: absolute;
    z-index: 0;
  }
  /deep/ .avatar-uploader .el-upload-list__item.is-success{
    z-index: 2;
  }

  /deep/ .modal-dialog {
    padding-top: 0 !important;
    width: 95% !important;
    max-width: 1919px !important;
    margin: 2.5rem auto !important;
  }
  /deep/ .modal-body {
    background-color: #f4f3ef;
    padding: 20px 30px !important;
  }
</style>
<style>
  .NICK { font-size: 11px; }
  .NICK.PRESTIGEGORILLA { color: #26c3bc; }
  .NICK.BLACK { color: black; }
  .NICK.GREEN { color: green; }
  .NICK.BURGUNDY { color: #800020; }
  .NICK.ORANGE { color: orange; }
  .NICK.RED { color: red; }
  .NICK.BEIGE { color: #d4d4bd; }
  .NICK.ROSE { color: #e2cac7; }
  .NICK.BROWN { color: brown; }
  .NICK.PEACH { color: #e2c2a6; }
  .NICK.NAVY { color: navy; }
  .NICK.LIME { color: #8bbe1b; }
  .NICK.MARINE { color: #2a6bcc; }
  .NICK.SAGE { color: #9BA17B; }
  .NICK.CORAL { color: coral; }
  .NICK.ASH { color: #B2BEB5; }
  .NICK.JOY { color: #CA2260; }
</style>
