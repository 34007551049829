import * as types from './mutation_types'
// import getters from "./getters"

export default {
  [types.EMPTY_HOTEL_FORM] (state) {
    state.form = []
  },
  [types.SET_HOTEL_FORM] (state, payload) {
    state.form = payload
  },
  [types.UPDATE_HOTEL_FORM] (state, payload) {
    let index = state.form.indexOf(payload)
    if(index > -1) state.form[index] = payload
  },
  [types.SET_ALL_HOTEL_DATA_FAST] (state, payload) {
    state.allHotelDataFast = payload
  },
  [types.SET_ALL_HASH_TAG_DATA] (state, payload) {
    state.allHashTagData = payload
  },
  [types.SET_ALL_HOTEL_BRAND_DATA] (state, payload) {
    state.allHotelBrandData = payload
  },
  [types.SET_ALL_HOTEL_REGION_DATA] (state, payload) {
    state.allHotelRegionData = payload
  },
  [types.SET_ALL_HOTEL_REGION_DEPTH_DATA] (state, payload) {
    state.allHotelRegionDepthData = payload
  },
  [types.SET_ALL_AIRLINE_CORP_DATA] (state, payload) {
    state.allAirlineCorpData = payload
  },
  [types.SET_ALL_POST_DATA_FAST] (state, payload) {
    state.allPostDataFast = payload
  },
  [types.SET_ALL_VIDEO_DATA_FAST] (state, payload) {
    state.allVideoDataFast = payload
  },
  [types.SET_ALL_POST_CATE_DATA] (state, payload) {
    state.allPostCateData = payload
  },
  [types.SET_ALL_DISPLAY_CATE_DATA] (state, payload) {
    state.allDisplayCateData = payload
  },
  [types.SET_ALL_MEMBER_DATA] (state, payload) {
    state.allMemberData = payload
  },
  [types.SET_ALL_MEMBER_CLASS_DATA] (state, payload) {
    state.allMemberClassData = payload
  },
  [types.SET_ALL_MEMBER_AUTH_DATA] (state, payload) {
    state.allMemberAuthData = payload
  },
  [types.SET_SEO_META_DATA_DEFAULT] (state, payload) {
    state.seoMetaDataDefault = payload
  },
  [types.SET_CALENDAR_DATA] (state, payload) {
    state.calendarData = payload
  },
  [types.SET_MY_MEMBER_AUTH_DATA] (state, payload) {
    state.myMemberAuthData = payload
  },
  [types.SET_OTA_SINGLE_HOTEL_DATA] (state, payload) {
    state.otaSingleHotelData = payload
  },
  [types.SET_OTA_SINGLE_HOTEL_DATA_CANCEL_TOKEN] (state, payload) {
    state.otaSingleHotelDataCancelToken = payload
  },
  [types.SET_OTA_SINGLE_HOTEL_DATAFEEDS] (state, payload) {
    state.otaSingleHotelDatafeeds = payload
  },
  [types.SET_OTA_SINGLE_HOTEL_DATAFEEDS_CANCEL_TOKEN] (state, payload) {
    state.otaSingleHotelDatafeedsCancelToken = payload
  },
  [types.SET_ALL_OTA_CODE_DATA] (state, payload) {
    state.allOtaCodeData = payload
  },
  [types.SET_ALL_USER_DATA] (state, payload) {
    state.allUserData = payload
  },
}
