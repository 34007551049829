<template>
  <div class="VIDEO_MANAGER">
    <div class="col-md-12 row pr-0">
      <div class="col-md-2">
        <h5 class="title">{{typeLabel[searchDB.type]}} {{pageName}}</h5>
        <p class="category">{{pageName}} 입니다.</p>
      </div>
      <div class="col-md-10 pr-0">
        <div class="form-group">
          <p-radio class="float-left mr-3" label="" v-model="searchDB.type" @input="resetCurrentPage">전체</p-radio>
          <p-radio class="float-left mr-3" label="HOTEL" v-model="searchDB.type" @input="resetCurrentPage">{{typeLabel['HOTEL']}}</p-radio>
          <p-radio class="float-left" label="FLIGHT" v-model="searchDB.type" @input="resetCurrentPage">{{typeLabel['FLIGHT']}}</p-radio><br>
          <div class="pull-right mt-2">
            <p class="category">검색된 <b>{{typeLabel[searchDB.cate]}} 비디오</b>는 총 <b class="text-info">{{pagination.total}}</b>개 입니다. </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 비디오 리스트 -->
    <div class="col-md-12 card LIST">
      <div class="card-body row table-full-width">
        <div class="col-md-5">
        </div>
        <div class="col-md-4">
          <div class="text-center">
            <el-date-picker
              v-model="searchDB.date"
              @change="getVideoList"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions2">
            </el-date-picker>
          </div>
        </div>
        <div class="col-sm-3 pr-md-0">
          <div class="col-sm-12">
            <fg-input class="input-sm"
                      placeholder="타이틀 검색"
                      v-model="searchDB.title"
                      @keyup.enter="getVideoList"
                      addon-left-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mt-md-1">
          <el-table class="table-striped"
                    header-row-class-name="text-default"
                    :data="tableData"
                    v-loading="loading"
                    border
                    style="width: 100%" >
            <el-table-column v-for="column in tableColumns"
                             class=""
                             :key="column.label"
                             :min-width="column.minWidth"
                             :prop="column.prop"
                             :label="column.label">
              <template slot-scope="props" class="info-text">
                <span v-if="column.prop === 'no'">{{getNo(props.row.key)}}</span>
                <a v-else-if="column.prop === 'title'" :href="`${frontUrl}/videos/detail/${props.row.idx}`" target="_blank" class="text-info-darken" >{{props.row.title}}</a>
                <div v-else-if="column.prop === 'thumbnail'"><img v-if="props.row.url" :src="props.row.thumbnail" width="auto" height="auto" style="max-width:60px; max-height:60px;" /></div>
                <span v-else>{{props.row[column.prop]}}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="getCheckThisPageModify"
              :min-width="100"
              fixed="right"
              class-name="td-actions text-center"
              label="공개설정">
              <template slot-scope="props">
                <el-switch v-model="props.row.is_visible" @change="changeIsVisibled(props.row)" active-color="#EB5E28" :disabled="!getCheckThisPageModify"></el-switch>
              </template>
            </el-table-column>
            <el-table-column
              v-if="getCheckThisPageModify || getCheckThisPageDelete"
              :min-width="100"
              align="right"
              fixed="right"
              class-name="action-buttons td-actions primary pr-2"
              label="수정/삭제">
              <template slot-scope="props">
                <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)" :disabled="!getCheckThisPageModify">
                  <i class="fa fa-edit"></i>
                </p-button>
                <p-button type="danger" size="sm" icon @click="handleDelete(props.$index, props.row)" :disabled="!getCheckThisPageDelete">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="card-body row table-full-width">
        <div class="col-sm-3">
          <div class="">
            <el-select
              class="select"
              v-model="pagination.perPage"
              @change="getVideoList"
              placeholder="Per page">
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="`${item} rows`"
                :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="pagination-info mt-1">
            <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
          </div>
        </div>
        <div class="col-sm-6 text-center">
          <!--<p-pagination v-model="pagination.currentPage"-->
          <!--:per-page="pagination.perPage"-->
          <!--:total="pagination.total">-->
          <!--</p-pagination>-->
          <el-pagination background
                         :value="pagination.currentPage"
                         :page-size="pagination.perPage"
                         :total="pagination.total"
                         layout="pager"
                         @current-change="setCurrentPage"
                         :disabled="loading">
          </el-pagination>
        </div>
        <div class="col-sm-3">
          <p class="pull-right">
            <p-button class="pull-right" type="info" round icon :disabled="total < 1" @click="downloadExcelFile(getFileName, getTableResult, getTableColumns, 'table_list')">
              <i class="fa fa-download"></i>
            </p-button>
          </p>
          <p class="pull-right mr-3">
            <p-button type="info" round @click.native="toggleModal(); loading=false" ref="editor" v-if="getCheckThisPageCreate">비디오 등록</p-button>
<!--            <p-button type="info" round @click.native="toggleModal(); loading=false" v-if="getCheckThisPageCreate">비디오 등록</p-button>-->
          </p>
        </div>
      </div>
    </div>

    <!-- 폼 모달 -->
    <modal :show.sync="isModalOpen"
           footer-classes="justify-content-center">

      <h5 slot="header" class="modal-title text-left">비디오 {{btnText}}</h5>
      <template class="bg-light">
        <div class="row ">
          <div class="col-lg-8 col-md-7">

            <div class="card">
              <div class="card-body">

                <form>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="btn-group pull-right text-nowrap">
                        <p-button type="default" class="mt-0" :class="formData.type==='HOTEL'?'SELECTED':'OUTLINE'" @click="changeType('HOTEL')">호텔</p-button>
                        <p-button type="default" class="mt-0" :class="formData.type==='FLIGHT'?'SELECTED':'OUTLINE'" @click="changeType('FLIGHT')">항공</p-button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <fg-input type="text"
                                label="타이틀"
                                placeholder="타이틀 입력"
                                class="required"
                                v-model="formData.title">
                      </fg-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <fg-input type="text"
                                label="URL"
                                placeholder="URL 입력"
                                class="required"
                                v-model="formData.url"
                                @keyup.enter="showThumbnail">
                      </fg-input>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>
          <div class="col-lg-4 col-md-5">

            <div class="card">
              <div class="card-body text-center pb-md-3">
                <img :src="formData.thumbnail" width="80%" height="auto" />
              </div>
              <!--<div class="image">-->
              <!--<img src="static/img/blank.gif" alt="...">-->
              <!--</div>-->
              <!--<div class="card-body">-->
              <!--<div class="author">-->
              <!--<el-upload-->
              <!--id="video_img"-->
              <!--ref="video_img"-->
              <!--class="avatar-uploader"-->
              <!--action="about:blank"-->
              <!--accept="image/png, image/jpeg, image/gif"-->
              <!--:file-list="getFileList(formData.video_img)"-->
              <!--:on-preview="handlePreview"-->
              <!--:on-change="handleChange"-->
              <!--:on-remove="handleRemove"-->
              <!--:auto-upload="false"-->
              <!--:limit="1"-->
              <!--list-type="picture-card">-->
              <!--<i class="el-icon-plus"></i>-->
              <!--</el-upload>-->
              <!--</div>-->
              <!--</div>-->
            </div>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="pull-right">
                      <label class="pr-2">공개설정</label>
                      <p-switch v-model="formData.is_visible" type="danger" on-text="ON" off-text="OFF"></p-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </template>
      <div slot="footer" class="justify-content-center">
        <p-button type="info" size="lg" wide round @click.native="saveVideoData">{{btnText}}</p-button>
      </div>
    </modal>

    <!-- 이미지뷰어 다이얼로그 -->
    <el-dialog :visible.sync="dialogVisible"
               :modal-append-to-body="false">
      <img :src="dialogImageUrl" alt="">
    </el-dialog>

  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option, DatePicker, Loading, Upload, Dialog, Switch, Pagination} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import Modal from 'src/components/UIComponents/Modal'
  import swal from 'sweetalert2'
  import { mapGetters } from 'vuex'
  import commonMethods from 'src/mixins/common_methods'
  const baseAction = '/videos/'
  export default{
    name: "VideoManager",
    components: {
      PSwitch, Modal, PPagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
      [Upload.name]: Upload,
      [Dialog.name]: Dialog,
      [DatePicker.name]: DatePicker,
      [Switch.name]: Switch,
      [Pagination.name]: Pagination,
      Loading,
    },
    data () {
      return {
        baseAction: baseAction,
        tableData: [{},{},{},{},{}],
        pageName: '비디오 관리',
        pagination: {
          perPage: 30,
          currentPage: 1,
          perPageOptions: [10, 30, 50, 100, 400, 1000],
          total: 0
        },
        typeLabel: {
          'HOTEL': '호텔',
          'FLIGHT': '항공'
        },
        searchDB: {
          'type': '',
          'date': '',
          'title': '',
        },
        tableColumns: [
          {
            prop: 'no',
            label: '#',
            minWidth: 70
          },
          {
            prop: 'vid',
            label: 'VID',
            minWidth: 80
          },
          {
            prop: 'thumbnail',
            label: '썸네일',
            minWidth: 90,
          },
          {
            prop: 'title',
            label: '타이틀',
            minWidth: 350
          },
          {
            prop: 'reg_dt',
            label: '작성일',
            minWidth: 110
          },
        ],
        pickerOptions: {
          shortcuts: [{
            text: 'Today',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
            {
              text: 'Yesterday',
              onClick (picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24)
                picker.$emit('pick', date)
              }
            },
            {
              text: 'A week ago',
              onClick (picker) {
                const date = new Date()
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', date)
              }
            }]
        },
        keyword: '',
        datePicker: '',
        pickerOptions2: {
          shortcuts: [{
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        formData: {
          type: 'HOTEL',
          title: '',
          url: '',
          thumbnail: '',
          video_img: {},
          is_visible: false,
        },
        formDataRs: {},
        uploadData: [],
        loading: true,
        isModalOpen: false,
        dialogImageUrl: '',
        dialogVisible: false,
        btnText: '등록',
      }
    },
    watch: {
      isModalOpen (val) {
        if (!val) {
          if (JSON.stringify(this.formDataRs) !== JSON.stringify(this.formData)) {
            if (!confirm('변경된 내용이 있습니다. 무시하고 창을 닫을까요?')) {
              this.isModalOpen = true
              return false;
            }
          }

          // forms 초기화
          this.resetFormData ()

          window.removeEventListener('beforeunload', this.unload)
        } else {
          window.addEventListener('beforeunload', this.unload)
        }
      }
    },
    beforeRouteLeave (to, from, next) {
      if (to.isModalOpen) {
        next(false)
      } else {
        next()
      }
    },
    mounted () {
      this.getVideoList()
    },
    computed: {
      ...mapGetters([
        'getAllVideoDataFast',
        'getCheckThisPageCreate',
        'getCheckThisPageRead',
        'getCheckThisPageModify',
        'getCheckThisPageDelete'
      ]),
      to () {
        let highBound = this.from + this.pagination.perPage
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        return this.pagination.total
      },
      frontUrl () {
        return Vue.prototype.$appURL
      },
      getFileName () {
        const keyword = this.searchDB.title ? `_(${this.searchDB.title})` : ''
        const date = this.searchDB.date ? `_(${this.searchDB.date.join('_')})` : ''
        return `[Video]${this.pageName.replace(/\ /g, '')}${keyword}${date}`
      },
      getTableResult () {
        const tableResult = this.tableData.reduce((acc, cur) => {
          const row = {}
          this.getTableColumns.map(col => {
            if (cur[col.prop] !== undefined ) {
              if (col.prop === 'no') {
                row[col.prop] = this.getNo(cur.key)
              } else if (col.prop === 'is_visible') {
                row[col.prop] = cur[col.prop] ? 'on' : 'off'
              } else {
                row[col.prop] = cur[col.prop]
              }
            }
          })
          acc.push(row)
          return acc
        }, [])
        return tableResult
      },
      getTableColumns () {
        const rtn = this.tableColumns
        rtn.push({
          prop: 'is_visible',
          label: '검색노출',
        })
        return rtn
      }
    },
    methods: {
      ...commonMethods,
      resetFormData () {
        for( let key in this.formData) {
          if (typeof this.formData[key] === 'string') this.formData[key] = ''
          else if (typeof this.formData[key] === 'number') this.formData[key] = 0
          else if (typeof this.formData[key] === 'boolean') this.formData[key] = false
          else if (typeof this.formData[key] === 'object') this.formData[key] = []
        }
        this.formData.type = 'HOTEL'
      },
      getVideoList () {
        this.loading = true
        let params = {params: {}}
        params.params.p = this.pagination.currentPage
        params.params.perPage = this.pagination.perPage
        if (this.searchDB.type) params.params.type = this.searchDB.type
        if (this.searchDB.date) params.params.date = this.searchDB.date.join(',')
        if (this.searchDB.title) params.params.title = this.searchDB.title
        this.axios.get(baseAction + 'search/all', params).then((rs) => {
          this.tableData = rs.data.data
          if (this.pagination.total !== rs.data.total) this.pagination.total = rs.data.total
          this.loading = false
        })
      },
      resetCurrentPage () {
        this.setCurrentPage(1)
      },
      changeType (type) {
        this.formData.type = type
      },
      changeIsVisibled (row) {
        const params = {idx: row.idx, is_visible: row.is_visible};
        const action = baseAction + 'visible/' + params.idx
        this.axios.put(action, params).then((rs) => {
          if (rs.data.status === 'success') {
            this.setVideoList('put', rs.data.data[0])
          }
        }).catch( error => {
          console.log('failed', error)
        })
      },
      setVideoList (method, row) {
        if(method === 'put') {
          for (let key in this.tableData) {
            if (this.tableData[key].idx === row.idx) {
              this.tableData.splice(key, 1, row)
              break
            }
          }
        } else {
          this.tableData.unshift(row)
        }
      },
      showThumbnail () {
        const params = {params: {
          url: this.formData.url,
          type: 'MAXRES'
        }}
        this.axios.get(baseAction + 'thumbnail', params).then(rs => {
          this.formData.thumbnail = rs.data[params.params.type]
        })
      },
      toggleModal (row) {
        const loading = Loading.service()
        if(row) {
          this.btnText = '수정'
          this.axios.get(baseAction + row.idx).then((rs) => {
            for( var key in rs.data) {
              let val = rs.data[key]
              if(val!==null) {
                this.formData[key] = val
              }
            }

            setTimeout(() => {
              this.formDataRs = this.cloneData(this.formData)
            })

            loading.close()
          })
        } else {
          this.formDataRs = this.cloneData(this.formData)
          this.btnText = '등록'
          loading.close()
        }
        this.isModalOpen = !this.isModalOpen
      },
      saveVideoData () {
        const loading = Loading.service()
        let params = this.cloneData(this.formData)
        let method = 'post'
        let action = baseAction

        if (params.idx) {
          action += params.idx
          method = 'put'
        }

        let formData = new FormData()
        for (let key in this.uploadData) {
          delete params[key]
          if (Object.keys(this.uploadData[key]).length > 0) {
            formData.append(key, this.uploadData[key].raw)
          } else {
            formData.append(key, null)
          }
        }
        for (let key in params) {
          if (typeof params[key] === 'object') {
            formData.append(key, JSON.stringify(params[key]))
          } else {
            formData.append(key, params[key])
          }
        }

        this.axios.post(action, formData).then((rs) => {
          if (rs.data.status === 'success') {
            this.formDataRs = this.cloneData(this.formData)
            let data = rs.data.data[0]
            if (method === 'put') {
              for (let key in this.tableData) {
                if (this.tableData[key].idx === data.idx) {
                  Vue.set(this.tableData, key, data)
                  break
                }
              }
            } else {
              this.tableData.push(data)
              this.tableDataOrigin = this.cloneData(this.tableData)
            }
            this.$store.dispatch('loadAllVideoDataFast')
            this.uploadData = []
          } else {
            swal({
              title: 'ERROR',
              text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
              type: 'error',
              confirmButtonClass: 'btn btn-info btn-fill',
              buttonsStyling: false
            })
          }
          loading.close()
          this.isModalOpen = false

        }).catch( error => {
          loading.close()
          console.log('failed', error)
        })
      },

      handleEdit (index, row) {
        this.toggleModal(row)
      },
      handleDelete (index, row) {
        let $this = this
        swal({
          title: '삭제하시겠습니까?',
          // text: '삭제하면 관련',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          buttonsStyling: false
        }).then(function () {
          $this.loading = true
          $this.axios.delete(baseAction + row.idx).then((rs) => {
            $this.loading = false
            if (rs.data.status === 'success') {
              $this.tableData.splice(index, 1)
              $this.tableDataOrigin.splice(index, 1)
            } else {
              swal({
                title: 'ERROR',
                text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
                type: 'error',
                confirmButtonClass: 'btn btn-info btn-fill',
                buttonsStyling: false
              })
            }
          }).catch( error => {
            $this.loading = false
          })
        }, function (dismiss) {

        })
      },
      getNo (no) {
        const rtn = this.pagination.total - (no + (this.pagination.perPage * (this.pagination.currentPage-1)))
        return !isNaN(rtn) ? rtn : ''
      },
      setCurrentPage (p) {
        // if (p !== this.pagination.currentPage) {
          this.pagination.currentPage = p
          this.getVideoList()
        // }
      }
    }
  }
</script>
<style scoped lang="scss">
  .SELECTED {
    background-color: #343a40 !important;
    border: 1px solid #343a40 !important;
  }
  .OUTLINE {
    background-color: #fff !important;
    color: #aaa !important;
    border: 1px solid #ddd !important;
  }

  /deep/ .LIST .el-range-separator {
    width: 30px !important;
  }
  /deep/ .LIST .el-select {
    width: 36% !important;
    min-width: 110px;
    margin-right: 5px;
  }
  /deep/ .LIST .el-select.small {
    width: 25% !important;
    min-width: 80px;
  }
  /deep/ .LIST .el-select:last-child {
    margin-right:0;
  }

  .card-user .image { height: 114px; }
  .card-user .card-body { min-height: 140px; }

  /deep/ .avatar-uploader .el-upload-list {
    position: relative;
    z-index: 2;
  }
  /deep/ .avatar-uploader .el-upload-list__item {
    position: absolute;
    z-index: 0;
  }
  /deep/ .avatar-uploader .el-upload-list__item.is-success{
    z-index: 2;
  }

  /deep/ .modal-dialog {
    padding-top: 0 !important;
    width: 75% !important;
    max-width: 1919px !important;
    margin: 2.5rem auto !important;
  }
  /deep/ .modal-body {
    background-color: #f4f3ef;
    padding: 20px 30px !important;
  }
</style>
