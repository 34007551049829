<template>
  <div class="MEMBER_AUTH">

    <!-- 헤더 및 컨트롤 -->
    <div class="col-md-12">
      <h5 class="title">사용자 권한 관리</h5>
      <p class="category">
        <!--We combine <a href="http://element.eleme.io/#/en-US/component/quickstart" target="_blank" rel="noopener">Element-UI</a>-->
        <!--table functionalities together with a custom pagination component-->
        <!--which should provide a very good starting point to integrate tables in your application.-->
        <!--Check out more functionalities at <a href="http://element.eleme.io/#/en-US/component/table" target="_blank" rel="noopener">Element-UI table documentation</a>.-->
        사용자 권한 관리 입니다.
      </p>
    </div>

    <!-- 브랜드 리스트 -->
    <div class="card">
      <div class="card-body row table-full-width">
        <div class="col-sm-12">
          <el-table :data="menuData"
                    v-loading="loading"
                    header-row-class-name="text-default">
            <el-table-column prop="menu.name"
                             :min-width="160"
                             fixed="left"
                             label="메뉴">
            </el-table-column>
            <!--<el-table-column :min-width="120"-->
                             <!--fixed="left"-->
                             <!--label="권한">-->
              <!--<template>-->
                <!--<div v-for="(autoGroup, index) in authGroupData" :key="index">{{autoGroup.name}}</div>-->
              <!--</template>-->
            <!--</el-table-column>-->
            <el-table-column v-for="(cls, clsIndex) in getMemberClassOptions" :key="clsIndex"
                             :min-width="140"
                             :label="cls.name">
              <template slot-scope="props" v-if="formData.value.length > 0">
                <div class="" v-for="(authGroup, authGroupIndex) in authGroupData" :key="authGroupIndex">
                  <p-radio :disabled="(cls.idx === superAdminIdx && authGroup.auth !== 'CRUD') || !getCheckThisPageModify && formData.value[clsIndex].menu[props.$index].auth !== authGroup.auth" v-model="formData.value[clsIndex].menu[props.$index].auth" :label="authGroup.auth" @input="clickRadio" >{{authGroup.name}}</p-radio>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div>
        <p class="pull-right mr-3">
          <p-button type="info" round @click.native="saveMemberAuthData" v-if="getCheckThisPageCreate">권한 저장</p-button>
        </p>
      </div>

      <!-- 이미지뷰어 다이얼로그 -->
      <el-dialog :visible.sync="dialogVisible"
                 :modal-append-to-body="false">
        <img :src="dialogImageUrl" alt="">
      </el-dialog>

    </div>
  </div>
</template>
<script>
  import {Table, TableColumn, Loading, Upload, Dialog, RadioGroup, Radio} from 'element-ui'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import Modal from 'src/components/UIComponents/Modal'
  import swal from 'sweetalert2'
  import { mapGetters } from 'vuex'
  import sidebarLinks from 'src/sidebarLinks.js'
  const baseAction = '/member_auth'
  export default{
    components: {
      PSwitch, Modal,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Upload.name]: Upload,
      [Dialog.name]: Dialog,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio
    },
    data () {
      return {
        formData: {
          idx: '',
          column: '',
          value: []
        },
        authGroupData: [
          {name:'모든권한', auth: 'CRUD'},
          {name:'읽기/등록/수정', auth: 'CRU'},
          {name:'읽기만', auth: 'R'},
          {name:'권한없음', auth: ''},
        ],
        superAdminIdx: 1,
        menuData: [],
        loading: true,
        isModalOpen: false,
        dialogImageUrl: '',
        dialogVisible: false,
        btnText: '등록',
      }
    },
    mounted () {
      this.getMenuList()
      this.$store.dispatch('callAllMemberClassData').then(() => {
        this.formData.value = this.getMemberClassOptions.map(row => ({idx: row.idx, name: row.name}))
        this.formData.value.map(row => {
          row.menu = this.menuData.map(row2 => {
            return row.idx === 1 ? {path: row2.menu.path, auth: 'CRUD'} : {path: row2.menu.path, auth: ''}
          })
          return row
        })

        this.$store.dispatch('callAllMemberAuthData').then(() => {
          this.getMemberAuthList()
        })
      })
    },
    computed: {
      ...mapGetters([
        'getMemberClassOptions',
        'getAllMemberAuthData',
        'getCheckThisPageCreate',
        'getCheckThisPageRead',
        'getCheckThisPageModify',
        'getCheckThisPageDelete'
      ])
    },
    methods: {
      isFileExist (obj) {
        return obj && obj.url ? true : false
      },
      cloneData (original) {
        return (original instanceof Array) ? Object.assign([], original) : Object.assign({}, original)
      },
      clickRadio () {
        this.formData.value = this.cloneData(this.formData.value)
      },
      getMenuList () {
        const menu = sidebarLinks.map(row => row.children).flat().map(item => {
          return {menu: item}
        })
        this.menuData = menu//Object.assign(menu, this.menuData)
      },
      getMemberAuthList () {
        this.loading = true
        const rs = this.getAllMemberAuthData
        if (rs) {
          this.formData.idx = rs.idx
          this.formData.column = rs.column
        }
        if (rs.value && rs.value.length > 0) {
          // console.log(rs)
          this.formData.value.map((row, index) => {
            const cls = rs.value.find(row2 => row.idx === row2.idx)
            if (cls) {
              row.menu.map((row2, index2) => {
                const auth = cls.menu.find(row3 => row2.path === row3.path)
                if (auth) this.formData.value[index].menu[index2] = auth
              })
            }
          })
          this.formData = this.cloneData(this.formData)
        }
        this.loading = false
      },
      saveMemberAuthData () {
        if (!confirm('갱신없이 오랫동안 편집했다면 다른작업자의 설정을 덮어 쓸 가능성이 있습니다.\n새로고침을 하시고 진행하는 것을 권장합니다.\n바로 저장 하시겠습니까?')) return
        const loading = Loading.service()
        let params = this.cloneData(this.formData)
        let method = 'post'
        let action = baseAction

        if (params.idx) {
          action += '/' + params.idx
          method = 'put'
        }

        let formData = new FormData()
        for (let key in params) {
          if (typeof params[key] === 'object') {
            formData.append(key, JSON.stringify(params[key]))
          } else {
            formData.append(key, params[key])
          }
        }

        this.axios.post(action, formData).then((rs) => {
          if (rs.data.status === 'success') {
            this.$router.go(0)
          } else {
            swal({
              title: 'ERROR',
              text: '오류가 발생했습니다. 문제가 계속되면 개발사에 문의하세요',
              type: 'error',
              confirmButtonClass: 'btn btn-info btn-fill',
              buttonsStyling: false
            })
          }
          loading.close()

        }).catch( error => {
          loading.close()
          console.log('failed', error)
        })
      }
    }
  }
</script>
<style scoped>
  label {
    font-weight: bold !important;
  }

  .card-user .image { height: 114px; }
  .card-user .card-body { min-height: 140px; }

  /deep/ .from-check, .form-check-radio {
    margin-bottom: 0px;
  }
  /deep/ .el-table th .cell {
    padding-right: 15px;
  }
  /deep/ .el-table td .cell {
    line-height: 1.5;
  }
</style>
