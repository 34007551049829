export default [
  {
    'no': 1,
    'idx': 1,
    'did': '',
    'section': {
      'idx': '',
      'data': [{'idx': '', 'name': ''}],
      'path': [],
      'pathName': ''
    },
    'link_url_app': '',
    'link_target_app': '',
    'dp_type': '',
    'dp_title': '',
    'dp_text1': '',
    'dp_text2': '',
    'dp_html': '',
    'dp_status': '',
    'dp_interactive': '',
    'dp_start_time': null,
    'dp_end_time': null,
    'model': '',
    'model_idx': null,
    'model_data': {},
    'is_advertising': false,
    'ad_channel': '',
    'ad_title': '',
    'ad_code_app': '',
    'app_img': {},
    'app_img_size': {width: '', height: ''},
    'app_img_interactive': {},
    'app_img_interactive_size': {width: '', height: ''},
    'reg_dt': ''
  },
  {
    'no': 2,
    'idx': 2,
    'did': '',
    'section': {
      'idx': '',
      'data': [{'idx': '', 'name': ''}],
      'path': [],
      'pathName': ''
    },
    'link_url_app': '',
    'link_target_app': '',
    'dp_type': '',
    'dp_title': '',
    'dp_text1': '',
    'dp_text2': '',
    'dp_html': '',
    'dp_status': '',
    'dp_interactive': '',
    'dp_start_time': null,
    'dp_end_time': null,
    'model': '',
    'model_idx': null,
    'model_data': {},
    'is_advertising': false,
    'ad_channel': '',
    'ad_title': '',
    'ad_code_app': '',
    'app_img': {},
    'app_img_size': {width: '', height: ''},
    'app_img_interactive': {},
    'app_img_interactive_size': {width: '', height: ''},
    'reg_dt': ''
  },
  {
    'no': 3,
    'idx': 3,
    'did': '',
    'section': {
      'idx': '',
      'data': [{'idx': '', 'name': ''}],
      'path': [],
      'pathName': ''
    },
    'link_url_app': '',
    'link_target_app': '',
    'dp_type': '',
    'dp_title': '',
    'dp_text1': '',
    'dp_text2': '',
    'dp_html': '',
    'dp_status': '',
    'dp_interactive': '',
    'dp_start_time': null,
    'dp_end_time': null,
    'model': '',
    'model_idx': null,
    'model_data': {},
    'is_advertising': false,
    'ad_channel': '',
    'ad_title': '',
    'ad_code_app': '',
    'app_img': {},
    'app_img_size': {width: '', height: ''},
    'app_img_interactive': {},
    'app_img_interactive_size': {width: '', height: ''},
    'reg_dt': ''
  },
  {
    'no': 4,
    'idx': 4,
    'did': '',
    'section': {
      'idx': '',
      'data': [{'idx': '', 'name': ''}],
      'path': [],
      'pathName': ''
    },
    'link_url_app': '',
    'link_target_app': '',
    'dp_type': '',
    'dp_title': '',
    'dp_text1': '',
    'dp_text2': '',
    'dp_html': '',
    'dp_status': '',
    'dp_interactive': '',
    'dp_start_time': null,
    'dp_end_time': null,
    'model': '',
    'model_idx': null,
    'model_data': {},
    'is_advertising': false,
    'ad_channel': '',
    'ad_title': '',
    'ad_code_app': '',
    'app_img': {},
    'app_img_size': {width: '', height: ''},
    'app_img_interactive': {},
    'app_img_interactive_size': {width: '', height: ''},
    'reg_dt': ''
  },
  {
    'no': 5,
    'idx': 5,
    'did': '',
    'section': {
      'idx': '',
      'data': [{'idx': '', 'name': ''}],
      'path': [],
      'pathName': ''
    },
    'link_url_app': '',
    'link_target_app': '',
    'dp_type': '',
    'dp_title': '',
    'dp_text1': '',
    'dp_text2': '',
    'dp_html': '',
    'dp_status': '',
    'dp_interactive': '',
    'dp_start_time': null,
    'dp_end_time': null,
    'model': '',
    'model_idx': null,
    'model_data': {},
    'is_advertising': false,
    'ad_channel': '',
    'ad_title': '',
    'ad_code_app': '',
    'app_img': {},
    'app_img_size': {width: '', height: ''},
    'app_img_interactive': {},
    'app_img_interactive_size': {width: '', height: ''},
    'reg_dt': ''
  },
]
